import L from 'pnp_core_bundle/lib/leaflet.js';

const vehiclePositionMarker = [];
const parameters = {
    urlImages : '/bundles/canaltppnpcore/images/'
};

let intervalDirection;
let observer = null;

export async function displayVehiclePositionByRouteId (direction) {

    if (intervalDirection) {
        clearInterval(intervalDirection);
    }
    
    let element = JKisio('#stop_area_hours #' + direction);
    if (direction !== 'forward' && typeof JKisio('#stop_area_hours #backward').attr('data-route-id') !== 'undefined') {
        element = JKisio('#stop_area_hours #backward');
    }
    if (!element) {
        return;
    }

    // first call
    apiCall(element);

    // refresh every interval time
    intervalDirection = setInterval(function () {
        apiCall(element);
    }, Kisio.vehicle_position_interval);
}

async function apiCall (element) {
    try {
        await JKisio.ajax({
            url: Routing.generate('schedule_vehicle_position', {
                routeId: element.attr('data-route-id'),
                type_product: Kisio.type_product
            }),
            async: true,
            success: function (data) {
                if (data.length > 0) {
                    for (const [key, value] of Object.entries(vehiclePositionMarker)) {
                        if (hasVehiclePosition(value.id, data[0]?.vehicle_journey_positions) || value.iteration > 6) {
                            window.Kisio.map.removeLayer(value.marker);
                            delete vehiclePositionMarker[key];
                        } else {
                            const origin = L.icon({
                                iconUrl: parameters.urlImages + 'modes/vehicle-no-signal.svg',
                                iconSize: 50,
                                popupAnchor: [170, 90]
                            });
                            value.marker.setIcon(origin);
                            vehiclePositionMarker[key].iteration = vehiclePositionMarker[key].iteration ?? 0;
                            vehiclePositionMarker[key].iteration++;
                            createPopup(value.marker, data[0].line, null, true);
                        }
                    }

                    for (const result in data) {
                        displayPicto(data[result]);
                    }
                }
            },
            error: function (xhr, status, error) {
                console.log(error);
            }
        })
    } catch (e) {
        console.log(e);
    }
}

export function getCurrentDirectionEventListeners () {
    if (observer) {
        observer.disconnect();
    }

    observer = new MutationObserver(function (observer) {
       observer.forEach(function (e) {
          if ('none' === e.target.style.display) {
              clearInterval(intervalDirection);
              displayVehiclePositionByRouteId('backward');

              return;
          }

           clearInterval(intervalDirection);
           displayVehiclePositionByRouteId('forward');
       });
    });

    observer.observe(JKisio('#stop_area_hours #forward')[0], {
        attributes: true,
        childList: true,
        characterData: true
    })
}

function displayPicto (result) {
    if (!result.vehicle_journey_positions) {
        return;
    }

    for (const vehicle_position in result.vehicle_journey_positions) {
        const position = result.vehicle_journey_positions[vehicle_position];
        if (position.coord && position.coord.lat && position.coord.lon) {

            
            const origin = L.icon({
                iconUrl: parameters.urlImages + 'modes/vehicle.svg',
                iconSize: 50,
                popupAnchor: [170, 90]
            });

            const positionMarker = new L.marker(
                [position.coord.lat, position.coord.lon],
                {icon: origin, layerType: 'vehicle-position-marker', keyboard: false}
            );
            createPopup(positionMarker, result.line, position);
            positionMarker?.addTo(window.Kisio.map);
            vehiclePositionMarker.push({id: position.vehicle_journey.id, marker: positionMarker});
        }
    }
}

function createPopup(marker, line, vehicle_position = null, no_signal = false) {
    const el = document.createElement('div');
    const mode = line.physical_modes[0].name;
    let dom = `<div class="vehicle-position-popup__header"><span>${mode}</span>` +
        `<span class="vehicle-position-popup__code-line" style="background-color: #${line.color}; color: #${line.text_color}">${line.code}</span></div>`;

    if (no_signal) {
        dom += `<div class="vehicle-position-popup__body"><span class="vehicle-position-popup__no-signal">${Translator.trans('schedule.occupancy.no_signal')}</span></div>`;
    } else {
        dom += `<div class="vehicle-position-popup__body"><div class="vehicle-position-popup__direction">` +
            `<span>${Translator.trans('next_departures.table.headers.direction')}</span>` +
            `<span>${vehicle_position.vehicle_journey.name}</span></div>`;
            if (vehicle_position.occupancy != "EMPTY") {
                dom += `<div class="vehicle-position-popup__occupancy" ><div>` +
            `<img src="/bundles/canaltppnpcore/images/realtime.gif" alt="realtime" /> ` +
            `<img src="/bundles/canaltpjourney/images/${vehicle_position.occupancy}.svg"
            alt="occupancy"/></div><div class="vehicle-position-popup__occupancy-info">` +
            `<span>${Translator.trans("schedule.occupancy.popup_title")}</span>` +
            `<span>${Translator.trans("schedule.occupancy." + vehicle_position.occupancy.toLowerCase() + ".title").toLowerCase()}</span></div></div></div>`;
        }
    }

    el.innerHTML = dom;
    marker.bindPopup(el, {className: 'vehicle-position-popup'});
    marker.on('mouseover', function () {
        this.openPopup();
    });
    marker.on('mouseout', function () {
        this.closePopup();
    });
}

function hasVehiclePosition(id, vehiclesPosition) {
    for (const [key, value] of Object.entries(vehiclesPosition)) {
        if (id === value.vehicle_journey.id) {
            return true;
        }
    }
    return false;
}

export function clearIntervalVehiclePosition() {
    // clear vehicle-position
    if (intervalDirection) {
        clearInterval(intervalDirection);
    }
}
