import { addNextBackPageListeners, addNavigationControlsListeners } from './core/event-listeners';
import { time_table_consts } from './core/constants';
import {getShowPlanLink} from "./core";

export * from './download';
export * from './download/event-listeners';

export function initShowTimeTableLink (direction) {
    return getShowPlanLink(direction);
}

export function initTimeTable () {
    const timetableElement = document.querySelector(time_table_consts.main_class);

    addNextBackPageListeners(timetableElement);
    addNavigationControlsListeners(timetableElement);
}
