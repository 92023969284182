import { clearMap } from '../modules/map';
import { read_local_storage_item } from './localStorage';
import { globalBodyPopup } from './global_popup';
import { addFavoriteToCookie, delFavoriteFromCookie } from './favorites_cookies';
import { reloadPositionBoard } from '../modules/reloadPositionBoard';
import { modelDisplaySearchScheduleResults } from 'schedule_bundle/result/schedule_result';

export var determineFavManager, checkFavoritesOnPage, clickOnFavorite, manageFavorites;

/**
 * Function to determine which Manager (cookies or Hofund) is enabled
 * @returns {string}
 */
determineFavManager = function () {
    let favorites_manager;
    if (Kisio.enable_fav_cookies !== undefined && Kisio.enable_fav_cookies) {
        favorites_manager = 'cookies';
    }
    if (Kisio.enable_fav_hofund !== undefined && Kisio.enable_fav_hofund) {
        favorites_manager = 'hofund';
    }
    return favorites_manager;
}

/**
 * Function to test in JS if the Management of Favorites is enabled for this type and which Manager (Cookies or Hofund) is defined
 * @returns {[boolean, string]}
 */
export var enableFavorites = function (type) {
    let display_favorites = false;
    let favorites_manager, favManager;
    if (Kisio.enable_favorites) {
        // We enable the favorites only if this particular type is authorized te be used
        if (Kisio.favorites_types.indexOf(type) > -1) {
            // We enable the favorites only if one Manager is enabled also
            favManager = determineFavManager();
            if (favManager !== undefined) {
                display_favorites = true;
                favorites_manager = favManager;
            }
        }
    }
    return [display_favorites, favorites_manager];
};
/**
 * Show Proximity/Favorites content and clean board elements
 * @param div
 */
export function showProxFavContent(div, type) {
    let height = JKisio(div).css({
        display: "block"
    }).height();
    JKisio('#board_footer').hide();

    JKisio(div).css({
        overflow: "hidden",
        marginTop: height,
        height: 0
    }).animate({
        marginTop: 0,
        height: height
    }, 500, function () {
        JKisio(this).css({
            display: "block",
            overflow: "",
            height: "",
            marginTop: ""
        });
        if (type !== undefined && type === 'favorite') {
            JKisio('#ctp-favorites').css({'height': 'auto'});
            reloadPositionBoard();
            // Once the Favorites Page loading is completed, we check the first favorite type opened and send its EltType to the proper function
            let initialOpenedSectionTypeElt = JKisio('#list-favorites ul > li:first-child h3');
            loadAjaxContentFav(initialOpenedSectionTypeElt);
        }
        if (type !== undefined && type === 'proximity') {
            JKisio('.autocomplete-search-icon, .locate-position').show();
        }
    });
};

/**
 * Hide proximity & Fav content and restore board elements
 * @param div
 */
export var hideProxFavContent = function (div, type, callback) {
    let height = JKisio(div).height();
    if (type === 'proximity') {
        JKisio('#ctp-proximityForm .autocomplete-search-icon, #ctp-proximityForm .locate-position').hide();
    }

    JKisio(div).css({
        overflow: "hidden",
        marginTop: 0,
        height: height
    }).animate({
        marginTop: height,
        height: 0
    }, 500, function () {
        JKisio(this).css({
            display: "none",
            overflow: "",
            height: "",
            marginTop: ""
        });
        if (type === 'proximity') {
            JKisio('#proximity_content').html('');
        }
        JKisio('#board_title_container, #board_footer').show();
        if (JKisio('#schedule_title').hasClass('selected')) {
            JKisio('#board_container').animate({'top': '2%'}, "fast", "swing");
            JKisio('#schedule_content').show();
        }else{
            JKisio('#journey_content').show();
            if (JKisio('#result_content').length > 0 && !JKisio('#result_content').is(':visible')) {
                JKisio('#board_container').animate({'top': '2%'}, "fast", "swing");
                JKisio('#result_content').show();
            }
        }
        if (type === 'favorite') {
            JKisio('#favorites_content').remove();
        }
        if (typeof callback === "function") {
            callback();
        }
    });
};

if (Kisio.enable_favorites) {

    /**
     * Function to update the Favorite Star icon and its container
     * @param element
     * @param status
     * @param display_popup
     */
    var updateFavStarStatus = function (element, status, display_popup) {
        let favoritesType = JKisio(element).attr('data-favorites-type');
        let parentDuplicate = JKisio(element).parents('.kisio-duplicate-section');
        let idParentLi;
        let originFav;
        let popupMessage;
        if (status === 'added') {
            // The favorite has been added so we change its Status (Star filled)
            if (favoritesType === "journey_solution" && JKisio(parentDuplicate).length > 0 && JKisio(parentDuplicate).is(':visible')){
                // In case we are on the FDR, we need to search the original fav star inside the parent Li to also update its status
                idParentLi = JKisio(element).attr('data-journey-parent-id');
                originFav = JKisio('#'+idParentLi).find('.fav-star');
                updateStatus(originFav);
            }
            updateStatus(element);
            if (display_popup) {
                popupMessage = Translator.trans('favorites.adding_fav');
                console.log(popupMessage);
                // TO DO: pop-up with message
            }
        }
        if (status === 'deleted') {
            // The favorite has been deleted so we change its Status (Star empty)
            if (favoritesType === "journey_solution" && JKisio(parentDuplicate).length > 0 && JKisio(parentDuplicate).is(':visible')){
                // In case we are on the FDR, we need to search the original fav star inside the parent Li to also update its status
                idParentLi = JKisio(element).attr('data-journey-parent-id');
                originFav = JKisio('#'+idParentLi).find('.fav-star');
                updateStatus(originFav);
            }
            updateStatus(element);
            if (display_popup) {
                popupMessage = Translator.trans('favorites.deleting_fav');
                console.log(popupMessage);
                // TO DO: pop-up with message
            }
        }
    };

    /**
     *  Function to update the status of the Favorite Star Icon
     *  @param element
     */
    var updateStatus = function (element) {
        let textAriaLabelTitle;
        if (JKisio(element).hasClass('fav-star-add')){
            JKisio(element).removeClass('fav-star-add');
            JKisio(element).addClass('fav-star-del');
            textAriaLabelTitle = JKisio(element).data('aria-label-del');
            JKisio(element).attr('title', textAriaLabelTitle);
            JKisio(element).attr('aria-label', textAriaLabelTitle);
        } else if (JKisio(element).hasClass('fav-star-del')) {
            JKisio(element).removeClass('fav-star-del');
            JKisio(element).addClass('fav-star-add');
            textAriaLabelTitle = JKisio(element).data('aria-label-add');
            JKisio(element).attr('title', textAriaLabelTitle);
            JKisio(element).attr('aria-label', textAriaLabelTitle);
        }
    }

    /**
     * Function to manage the click on the Favorite Star icon
     * @param element
     */
    clickOnFavorite = function (element){
        let typeSectionFavorite = JKisio(element).data('favorites-type-section');
        let typeFavorite = JKisio(element).data('favorites-type');
        let managerFavorite = JKisio(element).data('favorites-manager');
        if (managerFavorite === '') {
            managerFavorite = determineFavManager();
        }
        let paramsFavorite;
        if (typeFavorite === 'journey_solution') {
            paramsFavorite = JKisio(element).data('favorites-params');
        } else {
            paramsFavorite = decodeURIComponent(JKisio(element).data('favorites-params'));
        }
        if (Kisio.user_consent) {
            if (managerFavorite === 'cookies') {
                if (JKisio(element).hasClass('fav-star-add')) {
                    addFavoriteToCookie(typeSectionFavorite, typeFavorite, paramsFavorite);
                    updateFavStarStatus(JKisio(element), 'added', true);
                } else if (JKisio(element).hasClass('fav-star-del')) {
                    delFavoriteFromCookie(typeFavorite, paramsFavorite);
                    updateFavStarStatus(JKisio(element), 'deleted', true);
                }
            } else if (managerFavorite === 'hofund') {
                let typeSectionFavoriteArray = typeSectionFavorite.split(',');

                //Request to get bookmarks if they exist
                JKisio.ajax({
                    url: Routing.generate('hofund_get_user_bookmarks', {type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''}),
                    async: false,
                    success: function (list_favorites) {
                        if (list_favorites === false) {
                            console.log('Need to refresh token');
                        }else {
                            if (typeof list_favorites[typeFavorite] === 'undefined') {
                                list_favorites[typeFavorite] = {
                                    section: typeSectionFavoriteArray,
                                    list: []
                                };
                            }

                            // We extract then the favorite's type list of favorites from its Type Object
                            let favoritesTypeCookie = list_favorites[typeFavorite].list;
                            let alreadyPresent = false;
                            // We check if this particular favorite is already present
                            for (const favoriteTypeCookie of favoritesTypeCookie) {
                                if (favoriteTypeCookie === paramsFavorite) {
                                    alreadyPresent = true;
                                }
                            }
                            if (!alreadyPresent) {
                                // If this particular favorite is not present, we add it to its Type section in the JSON and we re-create the cookie
                                list_favorites[typeFavorite].list.push(paramsFavorite);
                            }
                            const favorites = Object.assign({}, list_favorites);
                            //Now we update with the new list of favorites
                            JKisio.ajax({
                                type: 'POST',
                                async: false,
                                url: Routing.generate('hofund_update_user_bookmarks',
                                    {
                                        type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                                        favorites
                                    }),
                                success: function (data) {
                                    //Manage the fav star
                                    if (JKisio(element).hasClass('fav-star-add')) {
                                        updateFavStarStatus(JKisio(element), 'added', true);
                                    } else if (JKisio(element).hasClass('fav-star-del')) {
                                        updateFavStarStatus(JKisio(element), 'deleted', true);
                                    }
                                },
                                error: function (data) {
                                    console.log(data);
                                }
                            });
                        }
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });
            }
        } else {
            // We should tell the user that in order to use favorites locally, he must consent to use of Local Storage & Cookies
            try {
                new globalBodyPopup({
                    'page_type': 'user_consent_fav'
                });
            } catch (e) {
                console.log(e);
            }
        }
    };

    /**
     * Function to retrieve the parameters of a given URL
     * @param query
     */
    function getQueryParameter(query)
    {
        let params = {};
        let elements;
        let regex = /[?&]?([^=]+)=([^&]*)/g;
        // Iterate through the results of the regex as long as there are any in the browser query.
        while (elements = regex.exec(query)) {
            params[decodeURIComponent(elements[1])] = decodeURIComponent(elements[2]);
        }
        return params;
    }


    /**
     * Function to check Favorites on page and change their respective status accordingly
     * @param favorites_manager
     */
    checkFavoritesOnPage = function (favorites_manager, elt){
        //retrieve the parameters of the current URL
        let parameters = getQueryParameter(window.location.search);
        let listFavorites;
        if (parameters.is_journey_schedules !== undefined) {
            // When coming from a favorite route, hide all the stars in the LDS and FDR
            JKisio('.fav-star').hide();
        } else {
            if (elt === undefined) {
                elt = '';
            }
            listFavorites = null;
            // We grab the list of Favorites depending on the manager
            if (favorites_manager === 'cookies' && Kisio.user_consent) {
                listFavorites = read_local_storage_item('favorites');
            } else if (favorites_manager === 'hofund') {
                //Request to get bookmarks if they exist
                JKisio.ajax({
                    url: Routing.generate('hofund_get_user_bookmarks', {type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''}),
                    async: false,
                    success: function (result) {
                        listFavorites = result;
                    }
                });
            }
            if (listFavorites !== null) {
                // We circle on the various Type of Favorites
                for (let favoriteType in listFavorites) {
                    let favoriteTypeObject = listFavorites[favoriteType];
                    let favoriteTypeFavList = favoriteTypeObject.list
                    // We circle on the various favorites and update their status
                    for (const favorite of favoriteTypeFavList) {
                        // We check the case if we needed to store the variable encoded
                        let favToFilled = JKisio(elt + '.fav-star.fav-star-add[data-favorites-params="'+encodeURIComponent(favorite)+'"]');
                        // If no correspondence is found, we check the case if we needed to store the variable NOT encoded
                        if (favToFilled.length === 0) {
                            favToFilled = JKisio(elt + '.fav-star.fav-star-add[data-favorites-params="'+favorite+'"]');
                        }
                        // If either way produce a result, we update the status to added of the FavStar corresponding
                        if (favToFilled.length > 0) {
                            favToFilled = favToFilled[0];
                            updateFavStarStatus(favToFilled, 'added', false);
                        }
                    }
                }
            }
        }
    };

    var managePopUponFav = function () {
        JKisio(document).on('click', '#popup-departure-btn, #popup-arrival-btn', function () {
            hideProxFavContent(JKisio("#favorites_content"), 'favorite');
        });
    };

    /**
     * Function to load the Next Departures for the Stop_Area_Lines_Direction Favorite
     */
    var loadNextDeparturesFav = function () {
        let model = new modelDisplaySearchScheduleResults();
        let items = JKisio('#list-stop_area_lines_direction .item');
        let stop_area_id, line_id, network_id, direction_type;
        JKisio.each(items, function (index, item) {
            stop_area_id = JKisio(item).data('stop-area-id');
            line_id = JKisio(item).data('line-id');
            network_id = JKisio(item).data('network-id');
            direction_type = JKisio(item).data('direction-type');
            model.getScheduleTimesForFavorites(item, stop_area_id, line_id, network_id, direction_type);
        });
    };

    /**
     * Function to load the FDR favorites in their respective cards
     */
    var loadFDRCardsFav = function () {
        let items = JKisio('#list-journey_solution .item');
        JKisio.each(items, function (index, item) {
            loadFDRFav(item);
        });
    };

    /**
     * Function to fetch the FDR from the server and display it accordingtly to success or error
     * @param item
     */
    var loadFDRFav = function (item) {
        // We remove previous error message if loading did not wort earlier
        JKisio(item).find('.ajaxLoadError').remove();
        // We grab the necessary data to send to the server
        let index = JKisio(item).data('index');
        let navitiaLink = decodeURIComponent(JKisio(item).data('favorite'));
        JKisio.ajax({
            type: 'POST',
            url: Routing.generate('favorites_journey_solution',
                {
                    type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''
                }),
            data: {
                link: navitiaLink,
                index: index
            },
            success: function (data) {
                if (data.success) {
                    // The successfull call returnmed the html with the FDR card to display and after we recalculate the SimpleBar and the Board's position
                    JKisio(item).html(data.html).promise().done(function () {
                        JKisio('#ctp-favorites').css({'height': 'auto'});
                        if (window.favoritesPageSimpleBar !== undefined) {
                            window.favoritesPageSimpleBar.recalculate();
                        }
                        reloadPositionBoard();
                    });
                } else {
                    // The call returned no card to display, we inform the user
                    let errorMessage = Translator.trans('favorites.error_details_fav');
                    let htmlError = '<div class="ajaxLoadError">' +
                                        '<span>' + errorMessage + '</span>' +
                                        '<i class="reload-fav-icon ikisio ikisio-reload"></i>' +
                                    '</div>';
                    JKisio(item).find('.temporary-fdr .kisio-js-summary').append(htmlError);
                }
            }
        });
    };

    /**
     * Function to load if necessary the Ajax Content depending on type and previous loading
     * @param elt
     */
    var loadAjaxContentFav = function (elt) {
        let sectionType = JKisio(elt).data('section-type');
        // Fetch next departure times if section-type is stop_area_lines_direction and content NOT ALREADY loaded
        if (sectionType === 'stop_area_lines_direction' && !JKisio(elt).hasClass('ajax-content-loaded')) {
            JKisio(elt).addClass('ajax-content-loaded');
            loadNextDeparturesFav();
        }
        // Fetch FDR cards if section-type is journey_solution and content NOT ALREADY loaded
        if (sectionType === 'journey_solution' && !JKisio(elt).hasClass('ajax-content-loaded')) {
            JKisio(elt).addClass('ajax-content-loaded');
            loadFDRCardsFav();
        }
        // Fetch BSS cards if section-type is journey_solution and content NOT ALREADY loaded
        if (sectionType === 'bss' && !JKisio(elt).hasClass('ajax-content-loaded')) {
            JKisio(elt).addClass('ajax-content-loaded');
            loadBSSFav();
        }
    }

    /**
     * Manage click on reload BSSFavorite
     */
    JKisio(document).on('click', '#list-bss .ajaxLoadError .ikisio', function () {
        let item = JKisio(this).parents('.item');
        loadBSSFav(item);
    });

    /**
     * Load BSS favorite
     *
     * @param item
     */
    var loadBSSFav = function (item) {
        let items, poi_id, item_id, htmlError;
        if (JKisio('#list-bss').length > 0) {
            if (typeof item !== "undefined") {
                items = item;
            }else {
                items = JKisio('#list-bss .item');
            }
            JKisio.each(items, function (index, item) {
                poi_id = JKisio(item).data('poi-id');
                item_id = JKisio(item).attr('id');
                htmlError = '<div class="ajaxLoadError">' +
                    '<span>' + Translator.trans('schedule.stop_schedule_error') + '</span>' +
                    '<i class="reload-fav-icon ikisio ikisio-reload"></i>' +
                    '</div>';
                JKisio.ajax({
                    url: Routing.generate('r_public_transport_objects_filtered',
                        {
                            type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                            public_transport_object_type: 'pois',
                            public_transport_object_id: poi_id,
                            action: 'pois',
                            count: 1
                        }),
                    dataType: 'json',
                    success: function (result) {
                        let total, content_bss, poi;
                        if (result.error && JKisio('#' + item_id + ' .bss_content .ajaxLoadError').length <= 0) {
                            JKisio('#' + item_id + ' .bss_content').append(htmlError);
                        }else {
                            content_bss = '';

                            if (result.pois !== undefined && result.pois[0] !== '') {
                                poi = result.pois[0];

                                if (poi.address !== undefined) {
                                    content_bss += '<br />' + poi.address.label;
                                    JKisio('#' + item_id + ' .buttons_travel button').attr('data-address', poi.address.label);
                                }
                                if (poi.coord !== undefined) {
                                    JKisio('#' + item_id + ' .buttons_travel button').attr('data-coord', poi.coord.lat + ';' + poi.coord.lon);
                                }
                                if (poi.address !== undefined && poi.coord !== undefined) {
                                    JKisio('#' + item_id + ' .buttons_travel button').show();
                                }
                                if (poi.stands !== undefined && poi.stands !== null && (poi.stands.available_bikes !== undefined || poi.stands.available_places === undefined)) {
                                    total = 0;
                                    if (poi.stands.available_bikes !== undefined) {
                                        content_bss += '<br /><span>' + Translator.trans('proximity.data.bss.available_bikes') + '<img src="' + Kisio.realtime_data_picto + '" /> ' + poi.stands.available_bikes + '</span>';
                                        total = poi.stands.available_bikes;
                                    }
                                    if (poi.stands.available_places !== undefined) {
                                        content_bss += '<br /><span>' + Translator.trans('proximity.data.bss.available_places') + '<img src="' + Kisio.realtime_data_picto + '" /> ' + poi.stands.available_places + '</span>';
                                        total = total + poi.stands.available_places;
                                    }
                                }
                            }

                            if (typeof total !== "undefined" && total == 0) {
                                if (JKisio('#' + item_id + ' .bss_content .ajaxLoadError').length > 0) {
                                    JKisio('#' + item_id + ' .bss_content .ajaxLoadError').remove();
                                }
                                content_bss = htmlError;
                            }
                            JKisio('#' + item_id + ' .bss_content').append(content_bss);
                        }
                    },
                    error: function (error) {
                        console.log(error);
                        JKisio('#' + item_id + ' .bss_content').append(htmlError);
                    }
                });
            });
        }
    }

    /**
     * Function to prepare the query params for the Page Favorite Ajax Call
     * @param favoritesManager
     * @returns {[string, {favorites_manager: *, list_favorites: [], standalone_section: string, type_product: string}]}
     */
    var prepareForFavAjaxCall = function (favoritesManager, listFavorites) {
        let pathName = decodeURIComponent(window.location.pathname);
        const reg = /\/[a-z]{2}\/standalone\/(journey|schedule|proximity|favorites)\//;
        let standaloneSection = 'full';
        let m, isFavoritesStandalone = false;
        if ((m = reg.exec(pathName)) !== null) {
            if (m[1] !== 'favorites') {
                standaloneSection = m[1];
            } else {
                isFavoritesStandalone = true;
            }
        }

        let query = {
            // We want the favorites template to be the Full one, since we are in the case of another section Standalone (Journey / Schedule)
            type_product: 'full',
            favorites_manager: favoritesManager,
            list_favorites: listFavorites,
            standalone_section: standaloneSection,
            is_favorites_standalone: isFavoritesStandalone,
            user_consent: Kisio.user_consent
        };
        return [standaloneSection, query];
    }

    var manageFavorites = function () {

        // Deactivate click to avoid recalculation of top board
        JKisio(document).on('click', '#favorites_title', function () {
            return false;
        });

        // If we have favorites enabled and journeys displayed, we check for favorites on the page
        if (JKisio('.kisio-result-journey').length > 0) {
            let favoritesManager = determineFavManager();
            if (Kisio.enable_favorites && favoritesManager !== undefined) {
                checkFavoritesOnPage(favoritesManager);
            }
        }

        // Manage click on Trash button/icon on Favorites page
        JKisio(document).on('click', '#list-favorites .trash-button-fav', function () {
            let parent_id = JKisio(this).parents('li.item').attr('id');
            try {
                new globalBodyPopup({
                    'page_type': 'favorite_del',
                    'favorite_parent_id': parent_id
                });
            } catch (e) {
                console.log(e);
            }
        });

        // Manage click on reload JourneySolutionFavorite
        JKisio(document).on('click', '#list-journey_solution .ajaxLoadError .ikisio', function () {
            let item = JKisio(this).parents('.item');
            loadFDRFav(item);
        });

        // Manage Delete of Favorites from Favorites Page
        JKisio(document).on('click', '.kisio-global-popup-full .fav-delete', function () {
            JKisio('#kisio-popup-close').trigger('click');
            let htmlFavEmpty, managerFavorite, parent_id, favToRemove, typeFavorite, paramsFavorite;
            if (JKisio(this).hasClass('yes')) {
                managerFavorite = determineFavManager();
                parent_id = JKisio(this).data('parent-id');
                favToRemove = JKisio('#'+parent_id);
                typeFavorite = JKisio(favToRemove).data('favorites-type');
                // In the case of journey_solution favorites, the complete value of the favorites is on the data attribute "favorite-full" not "favorite"
                if (typeFavorite === 'journey_solution') {
                    paramsFavorite = JKisio(favToRemove).data('favorite-full');
                } else if (typeFavorite === 'bss') {
                    paramsFavorite = JKisio(favToRemove).data('favorite');
                } else {
                    paramsFavorite = decodeURIComponent(JSON.stringify(JKisio(favToRemove).data('favorite')));
                }
                if (managerFavorite === 'cookies') {
                    delFavoriteFromCookie(typeFavorite, paramsFavorite);
                } else if (managerFavorite === 'hofund') {
                    //Request to get bookmarks if they exist
                    let listFavoritesCookie = {};
                    JKisio.ajax({
                        url: Routing.generate('hofund_get_user_bookmarks', {type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''}),
                        async: false,
                        success: function (result) {
                            if (result === false) {
                                console.log('Need to refresh token');
                            } else {
                                listFavoritesCookie = result;
                                if (typeof listFavoritesCookie[typeFavorite] === 'undefined') {
                                    listFavoritesCookie[typeFavorite] = {
                                        section: typeSectionFavoriteArray,
                                        list: []
                                    };
                                }
                            }
                        }
                    });
                    if (listFavoritesCookie !== null) {
                        // We extract the favorite's type list from the cookies JSON
                        let favoritesTypeCookie = listFavoritesCookie[typeFavorite].list;
                        let params;
                        for (let i = 0; i < favoritesTypeCookie.length; i++) {
                            if (typeFavorite === 'journey_solution') {
                                // In the case of journey_solution favorite in Cookies, we need to decode once more its content for proper comparison with the favorite handled by the JS
                                params = decodeURIComponent(favoritesTypeCookie[i]);
                                if (params === paramsFavorite) {
                                    // We found the favorite and we delete its value from the list
                                    favoritesTypeCookie.splice(i,1);
                                } else {
                                    // We need to try once more without decodeng it because encoding vary between PHP and JS
                                    params = favoritesTypeCookie[i];
                                    if (params === paramsFavorite) {
                                        // We found the favorite and we delete its value from the list
                                        favoritesTypeCookie.splice(i,1);
                                    }
                                }
                            } else {
                                params = favoritesTypeCookie[i];
                                if (params === paramsFavorite) {
                                    // We found the favorite and we delete its value from the list
                                    favoritesTypeCookie.splice(i,1);
                                }
                            }
                        }
                        if (favoritesTypeCookie.length > 0) {
                            // We still have some Favorites for this Type
                            // We update the JSON with the modified favorite's type list
                            listFavoritesCookie[typeFavorite].list = favoritesTypeCookie;
                        } else {
                            // No Favorites remain for this type, we need to delete the type from the Global Array
                            delete listFavoritesCookie[typeFavorite];
                        }

                        //Now we update with the new list of favorites
                        const favorites = Object.assign({}, listFavoritesCookie);
                        JKisio.ajax({
                            type: 'POST',
                            async: false,
                            url: Routing.generate('hofund_update_user_bookmarks',
                                {
                                    type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                                    favorites
                                }),
                            success: function (data) {
                            },
                            error: function (data) {
                                console.log(data);
                            }
                        });
                    }
                }
                let stars = JKisio(document).find('.fav-star-del');
                if (JKisio(stars).length > 0) {
                    JKisio(stars).removeClass('fav-star-del');
                    JKisio(stars).addClass('fav-star-add');
                    checkFavoritesOnPage(managerFavorite);
                }
                JKisio(favToRemove).remove();
                if (JKisio('#list-'+typeFavorite+' .item').length === 0) {
                    // There is no longer any favorites in this Type Section, we need to remove it altogether
                    JKisio('#accordion-'+typeFavorite).remove();
                }
                if (JKisio('#list-favorites .list-fav li').length === 0) {
                    // There is no longer any Favorites at all, we need to display the correct text
                    if (Kisio.user_consent) {
                        // The user consented to cookies so the text has to be about no favorites added
                        htmlFavEmpty = '<li>'+Translator.trans('favorites.no_favorites_to_display')+'</li>';
                    } else {
                        // The user refused to consent to cookies so the text has to be about that
                        htmlFavEmpty = '<li>'+Translator.trans('favorites.no_favorites_to_display_because_user_consent')+'</li>';
                    }
                    JKisio('#list-favorites .list-fav').append(htmlFavEmpty);
                }
            }
        });

        // Management of Favorites Footer & Favorites apparition in Full and Other Sections Standalones (Journey / Schedule)
        if (JKisio('#footer_favorites').length > 0 && !JKisio('#favorites_content').is(':visible')) {
            JKisio('#footer_favorites').click(function (e) {
                e.preventDefault();
                let favoritesManager = determineFavManager();
                let query, listFavorites, prepParams, div, standaloneSection;
                if (Kisio.enable_favorites && favoritesManager !== undefined) {
                    clearMap();
                    div = JKisio("#favorites_content");
                    if (!JKisio(div).is(':visible')) {
                        listFavorites = [];
                        //This is commented for the moment, as the bookmarks are not synchronized with ADM
                        if (favoritesManager === 'cookies') {
                            listFavorites = read_local_storage_item('favorites');
                        }else if (favoritesManager === 'hofund') {
                            query = {
                                type_product: 'full'
                            };
                            JKisio.ajax({
                                url: Routing.generate('hofund_get_user_bookmarks', query),
                                async: false,
                                success: function (result) {
                                    listFavorites = result;
                                    if (listFavorites === 'false') {
                                        console.log(listFavorites);
                                    }else{
                                        return listFavorites;
                                    }
                                },
                                error: function (error) {
                                    console.log(error);
                                }
                            });
                        }
                        prepParams = prepareForFavAjaxCall(favoritesManager, listFavorites);
                        standaloneSection = prepParams[0];
                        query = prepParams[1];
                        JKisio.ajax({
                            url: Routing.generate('favorites', query),
                            success: function (result) {
                                if (window.Kisio.type_product !== undefined && window.Kisio.type_product === 'full') {
                                    JKisio("<div id='favorites_content'></div>").insertAfter('#schedule_content');
                                } else if (Kisio.type_product !== undefined && Kisio.type_product === 'standalone') {
                                    if (standaloneSection === 'proximity') {
                                        JKisio("<div id='favorites_content'></div>").insertAfter('#ctp-proximity');
                                    }
                                    if (JKisio('#'+standaloneSection+'_content').is(':visible')) {
                                        JKisio("<div id='favorites_content'></div>").insertAfter('#'+standaloneSection+'_content');
                                    } else {
                                        JKisio("#board_content").append("<div id='favorites_content'></div>");
                                    }
                                }

                                JKisio('#favorites_content').html(result);
                                //Hide journey results if present
                                if (JKisio('#result_content').length > 0 && JKisio('#result_content').is(':visible')) {
                                    JKisio('#result_content').hide();
                                }
                                showProxFavContent("#favorites_content", 'favorite');
                                JKisio('#board_container').animate({'top': '130px'}, "fast", "swing");
                                JKisio('#board_title_container, #journey_content, #schedule_content, #ctp-proximity').hide();
                                managePopUponFav();
                            },
                            error: function (error) {
                                console.log('Error in loading Favorites template: '+ error);
                            }
                        });

                    } else {
                        hideProxFavContent(div, 'favorite');
                    }
                }
            });
        }

        // Show previous results when clicking on Close Favorites
        JKisio(document).on('click', '#favorites_clear', function () {
            clearMap();
            hideProxFavContent(JKisio("#favorites_content"), 'favorite');
        });

        // Fold and Unfold list of favorites
        JKisio(document).on('click', '#list-favorites .ctp-favorites-accordion h3', function () {
            if (JKisio(this).next('div').is(':visible')){
                JKisio(this).attr('aria-expanded','false');
                JKisio(this).find('i').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
                JKisio(this).next('div').hide().promise().done(function () {
                    // We recalculate the SimpleBar and the Board's position
                    JKisio('#ctp-favorites').css({'height': 'auto'});
                    if (window.favoritesPageSimpleBar !== undefined) {
                        window.favoritesPageSimpleBar.recalculate();
                    }
                    reloadPositionBoard();
                });
            } else {
                let __this = this;
                JKisio('#list-favorites .section-favorites-content').hide().promise().then(function () {
                    JKisio('#list-favorites .section-favorites .ikisio').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
                    JKisio(__this).attr('aria-expanded','true');
                    JKisio(__this).find('i').removeClass('ikisio-arrow-drop-up').addClass('ikisio-arrow-drop-down');
                    JKisio(__this).next('div').show().promise().done(function () {
                        // We recalculate the SimpleBar and the Board's position
                        JKisio('#ctp-favorites').css({'height': 'auto'});
                        if (window.favoritesPageSimpleBar !== undefined) {
                            window.favoritesPageSimpleBar.recalculate();
                        }
                        reloadPositionBoard();
                        // We send the Elt type to the load Ajax content function
                        let sectionTypeElt = JKisio(__this);
                        loadAjaxContentFav(sectionTypeElt);
                    });
                });
            }
        });

        // Interacting with a Favorite Icon on the Proximity Page
        JKisio(document).on('click', '.leaflet-popup .fav-star', function () {
            clickOnFavorite(this);
        });

        // Once the Favorites Page is completed, we check the first favorite type opened and send its EltType to the proper function
        let initialOpenedSectionTypeElt = JKisio('#list-favorites ul > li:first-child h3');
        loadAjaxContentFav(initialOpenedSectionTypeElt);

        JKisio(document).on('click', '.journey-solution-clickable .kisio-summary', function (e) {
           if (!(JKisio(e.target).hasClass('trash-button-fav') || JKisio(e.target).hasClass('reload-fav-icon'))) {
               let href = JKisio(this).data('href').substring(1, JKisio(this).data('href').length-1);
               window.location.href = href;
           }
        });

        if (JKisio('#ctp-favorites').hasClass('local-storage-to-read')) {
            let favoritesManager = determineFavManager();
            let query, prepParams, htmlFavEmpty;
            if (Kisio.enable_favorites && favoritesManager !== undefined) {
                let listFavorites = [];
                if (favoritesManager === 'cookies' && Kisio.user_consent) {
                    listFavorites = read_local_storage_item('favorites');
                    prepParams = prepareForFavAjaxCall(favoritesManager, listFavorites);
                    query = prepParams[1];
                    JKisio.ajax({
                        url: Routing.generate('favorites', query),
                        success: function (result) {
                            let resultJKisioObject = JKisio.parseHTML(result);
                            let ctpFavoritesIdContent = JKisio(resultJKisioObject[2]).html();
                            JKisio('#ctp-favorites').html();
                            JKisio('#ctp-favorites').append(ctpFavoritesIdContent);
                            // Once the Favorites Page is completed, we check the first favorite type opened and send its EltType to the proper function
                            let initialOpenedSectionTypeElt = JKisio('#list-favorites ul > li:first-child h3');
                            loadAjaxContentFav(initialOpenedSectionTypeElt);
                        },
                        error: function (error) {
                            console.log('Error in loading Favorites template: '+ error);
                        }
                    });
                } else {
                    // The user refused to consent to cookies so the text has to be about that
                    htmlFavEmpty = '<li>'+Translator.trans('favorites.no_favorites_to_display_because_user_consent')+'</li>';
                    JKisio('#list-favorites .list-fav').append(htmlFavEmpty);
                }
            }
        }
    };
}