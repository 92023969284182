import { manageGeoMarkers } from '../modules/map-popup';
import { read_cookie, create_cookie } from './cookies';
import { isIE9, isIE10, isIE11 } from '../plugnplay/browser';

/**
 * String.prototype.replaceAll() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(str, newStr){
        // If a regex pattern
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return this.replace(str, newStr);
        }
        // If a string
        return this.replace(new RegExp(str, 'g'), newStr);
    };
}

if (Kisio.enable_historic_search){
    
    /**
     * Function to add Historic of Journey search to Cookie
     * @param {string} label Valeur du champs texte
     * @param {string} uri Valeur du champs caché
     */
    function addHistoricSearchToCookie(label, uri, coord) {
        //Value to insert into cookie
        let value = {
            input: encodeURIComponent(label),
            inputHidden: encodeURIComponent(uri),
            coord: coord
        };

        //Read the cookie
        let historicCookie = read_cookie('historic');
        if (historicCookie !== undefined && historicCookie !== null && historicCookie.length > 0) {
            //Check if the value is already present
            let valueAlreadyPresent = false;
            for (const element of historicCookie) {
                if (element.input !== undefined && element.inputHidden !== undefined && element.input === value.input && element.inputHidden === value.inputHidden) {
                    valueAlreadyPresent = true;
                }
            }
            if (valueAlreadyPresent === false) {
                //Insert value into cookie
                historicCookie.push(value);
                //Update its content as soon as there are 3 elements
                if (historicCookie.length > Kisio.historic_limit_value){
                    historicCookie.splice(0,1);
                }
                // if Cookie already exist, we need to complete it
                create_cookie('historic', historicCookie);
            }
        } else {
            //cookie doesn't exist, we create it
            let cookieValue = [];
            cookieValue.push(value);
            create_cookie('historic', cookieValue);
        }
    };

    /**
     * Function to show the historic list
     * @param elt
     */
    function showHistoricList(elt){
        // Remove the list if it exists
        JKisio('.autocomplete .historic-list').remove();

        //Read the cookie
        let historicList = read_cookie('historic');

        let parentHtlml = JKisio(elt).parent();
        let idParentHtml = parentHtlml.attr('id');
        let idInput = JKisio(elt).attr('id');

        if (historicList !== undefined && historicList !== null && historicList.length > 0) {
            // Build the list in HTML
            let htmlUl = "<ul class='historic-list'>";
            let label = "<label class='historic-label'>" + Translator.trans('historic.label').toUpperCase() + "</label>";
            let htmlLi = "";
            for (const element of historicList) {
                if (typeof element !== "undefined" && typeof element.coord !== 'undefined') {
                    let input = decodeURIComponent(element.input);
                    let id = decodeURIComponent(element.inputHidden);
                    let coord = element.coord.lat + ';' + element.coord.lon;
                    htmlLi += "<li class='historic-item' data-coord='" + coord + "' id='" + id + "'>" + input + "</li>";
                }
            }
            let htmlEnd = "</ul>"
            let html = htmlUl + label + htmlLi + htmlEnd;
            parentHtlml.append(html);
            // Manage the css on focus
            JKisio('#'+idParentHtml).addClass('focus-in');
            if (idInput === "search_from_autocomplete") {
                JKisio('#search_to .historic-list').remove();
                JKisio('#journey-form-reverse').hide();
                JKisio('#search_to').removeClass('focus-in');
            } else if (idInput === "search_to_autocomplete") {
                JKisio('#search_from .historic-list').remove();
            } else if (idInput === "schedule_stop_area_lines_autocomplete") {
                JKisio('.historic-list').remove();
            } else if (idInput === "proximity_search_uri_autocomplete") {
                JKisio('.historic-list').remove();
            }
            filterHistoricList(elt);
        }

    };

    /**
     * Function used to filter the Historic list based on inputed value
     * @param elt
     */
    function filterHistoricList(elt) {
        // Before filtering, we make sure that the historic entries container is visible
        // in the case of one of those should be "appearing" depending on the filter
        JKisio('.historic-list').show();
        // We get the value inputed, trimmed of trailing whitespaces
        let inputValueString = elt.value.trim(' ');
        if (!(isIE9 || isIE10 || isIE11)) {
            // On all modern browsers, we normalize the string to deconpose any accented characters and then remove the accent
            inputValueString = inputValueString.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        }
        // We filter only if the value is not empty
        if (inputValueString.length > 0) {
            // We build an array of terms to search by differentiating them based on whitespace separation in the inputed value
            let inputValuesArray = inputValueString.split(' ');
            // We check if the split has generated any empty string in the Search Terms array
            for (let i = 0; i < inputValuesArray.length; i++) {
                if (inputValuesArray[i] === '') {
                    // When it is the case, we dropped them from the array
                    inputValuesArray.splice(i,1);
                }
            }
            // We start building the string used later for the substitution Regex by joining the searh array terms with a | in order to search alternatively all terms
            let inputValuesStrRegex = '('+inputValuesArray.join('|')+')';
            // We build the Regex with Global & Insensitive flags to search for all occurences & despite cases
            let regex = new RegExp(inputValuesStrRegex, 'gi');
            // We get all historic items to cycle through them to decide if we need to hide them or keep them and highlight the search terms
            let historicList = JKisio('.historic-list li');
            for (const historic of historicList) {
                // We get the text ONLY form the historic item
                let originalStr = JKisio(historic).text();
                if (!(isIE9 || isIE10 || isIE11)) {
                    // On all modern browsers, we normalize the string to deconpose any accented characters and then remove the accent
                    originalStr = originalStr.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                }
                // We need to determine before making the sustitution, if all terms are found in the item string
                // We initiate a variable which will increase when we found an occurence of a search term in the item string
                let toSubstitute = 0;
                for (const input of inputValuesArray) {
                    let reg = new RegExp(input, 'gi');
                    if (reg.test(originalStr)) {
                        toSubstitute++;
                    }
                }
                // If the variable previously increased is equal to the length of the Search terms array, it means that all terms have been found in the historic item string
                // We can proceed with the substitution of the search terms to highlight them
                if (toSubstitute === inputValuesArray.length) {
                    // We create the substitute string
                    let subStr = '<strong>$&</strong>';
                    // We execute the replaceAll with the RegExp Object and the substitute string
                    let result = originalStr.replaceAll(regex, subStr);
                    // In order to avoid strange behavior by the browser regarding whitespace and contiguous html tags, we replace normal whitespace by unbreakable ones
                    let newStr = result.replaceAll(' ', '&nbsp;');
                    // Now we clean again the string before implementing it in the html of the historic item
                    let cleanStr = newStr.replaceAll('\\s', ' ');
                    JKisio(historic).html(cleanStr);
                    // In case of previous masked historic items, we show it
                    JKisio(historic).show();
                } else {
                    // We did NOT found ALL search terms in the historic item string, so we hide this historic item
                    JKisio(historic).hide();
                }
            }
        } else {
            // In case of previous masked historic items, we show all of them in case of empty inputed value
            JKisio('.historic-list li').show();
        }
        //Now we test if the inputed value made all historic items disappear
        if (JKisio('.historic-list li:visible').length === 0) {
            // In this case we need to hide the .historic-list elt
            JKisio('.historic-list').hide();
        }
    }

    // Manage the click on an item in the historic list
    JKisio(document).on('click', '.autocomplete .historic-item', function () {
        let itemHidden = JKisio(this).attr('id');
        let item = JKisio(this).text();
        let parent = JKisio(this).parents('.autocomplete');
        let fieldtype;
        if (parent.attr('id') == 'search_from') {
            fieldtype = 'from';
        } else if (parent.attr('id') == 'search_to') {
            fieldtype = 'to';
        }
        let object_coord = JKisio(this).attr('data-coord').split(';');
        manageGeoMarkers(fieldtype, '', [object_coord[0], object_coord[1]]);

        JKisio(parent).find('.autocompletable').val(item);
        JKisio(parent).find('.autocompletable-hidden').val(itemHidden);
        JKisio(parent).find('.historic-list').remove();
        JKisio(parent).find(' #autocomplete-clear i').show();
        JKisio(parent).removeClass('focus-in');
        JKisio('#journey-form-reverse').show();
    });

    // Manage the click on the cross in the autocomplete
    JKisio(document).on('click', '.ikisio-clear-field', function (){
        JKisio('#journey-form-reverse').show();
    })

    // Manage the mouseleave on the input list
    JKisio(document).on('mouseleave', '.autocomplete .historic-list', function () {
        let parent = JKisio(this).parents('.autocomplete');
        let parentId = JKisio(parent).attr('id');
        JKisio(parent).find('.historic-list').remove();
        JKisio(parent).removeClass('focus-in');
        if (parentId === "search_from") {
            JKisio('#journey-form-reverse').show();
        }
    });

    // Manage the re-click when the focus's on
    JKisio(document).on('click', '#search_from_autocomplete, #search_to_autocomplete', function (){
        if (Kisio.user_consent) {
            showHistoricList(this);
        }
    });

    // Manage the click on an item in the autocomplete list
    JKisio(document).on('click', '.ui-menu-item', function (){
        JKisio('.historic-list').remove();
    });
}



/**
 * Function to add Historic of Journey search to Cookie
 * @param {string} label Valeur du champs texte
 * @param {string} uri Valeur du champs caché
 */
export function addHistoricSearchToCookie(label, uri, coord) {
    //Value to insert into cookie
    let value = {
        input: encodeURIComponent(label),
        inputHidden: encodeURIComponent(uri),
        coord: coord
    };

    //Read the cookie
    let historicCookie = read_cookie('historic');
    if (historicCookie !== undefined && historicCookie !== null && historicCookie.length > 0) {
        //Check if the value is already present
        let valueAlreadyPresent = false;
        for (const element of historicCookie) {
            if (element.input !== undefined && element.inputHidden !== undefined && element.input === value.input && element.inputHidden === value.inputHidden) {
                valueAlreadyPresent = true;
            }
        }
        if (valueAlreadyPresent === false) {
            //Insert value into cookie
            historicCookie.push(value);
            //Update its content as soon as there are 3 elements
            if (historicCookie.length > Kisio.historic_limit_value){
                historicCookie.splice(0,1);
            }
            // if Cookie already exist, we need to complete it
            create_cookie('historic', historicCookie);
        }
    } else {
        //cookie doesn't exist, we create it
        let cookieValue = [];
        cookieValue.push(value);
        create_cookie('historic', cookieValue);
    }
};

/**
 * Function to show the historic list
 * @param elt
 */
export function showHistoricList(elt){
    // Remove the list if it exists
    JKisio('.autocomplete .historic-list').remove();

    //Read the cookie
    let historicList = read_cookie('historic');

    let parentHtlml = JKisio(elt).parent();
    let idParentHtml = parentHtlml.attr('id');
    let idInput = JKisio(elt).attr('id');

    if (historicList !== undefined && historicList !== null && historicList.length > 0) {
        // Build the list in HTML
        let htmlUl = "<ul class='historic-list'>";
        let label = "<label class='historic-label'>" + Translator.trans('historic.label').toUpperCase() + "</label>";
        let htmlLi = "";
        for (const element of historicList) {
            if (typeof element !== "undefined") {
                let input = decodeURIComponent(element.input);
                let id = decodeURIComponent(element.inputHidden);
                let coord = element.coord.lat + ';' + element.coord.lon;
                htmlLi += "<li class='historic-item' data-coord='" + coord + "' id='" + id + "'>" + input + "</li>";
            }
        }
        let htmlEnd = "</ul>"
        let html = htmlUl + label + htmlLi + htmlEnd;
        parentHtlml.append(html);
        // Manage the css on focus
        JKisio('#'+idParentHtml).addClass('focus-in');
        if (idInput === "search_from_autocomplete") {
            JKisio('#search_to .historic-list').remove();
            JKisio('#journey-form-reverse').hide();
            JKisio('#search_to').removeClass('focus-in');
        } else if (idInput === "search_to_autocomplete") {
            JKisio('#search_from .historic-list').remove();
        } else if (idInput === "schedule_stop_area_lines_autocomplete") {
            JKisio('.historic-list').remove();
        } else if (idInput === "proximity_search_uri_autocomplete") {
            JKisio('.historic-list').remove();
        }
        filterHistoricList(elt);
    }

};

/**
 * Function used to filter the Historic list based on inputed value
 * @param elt
 */
export function filterHistoricList(elt) {
    // Before filtering, we make sure that the historic entries container is visible
    // in the case of one of those should be "appearing" depending on the filter
    JKisio('.historic-list').show();
    // We get the value inputed, trimmed of trailing whitespaces
    let inputValueString = elt.value.trim(' ');
    if (!(isIE9 || isIE10 || isIE11)) {
        // On all modern browsers, we normalize the string to deconpose any accented characters and then remove the accent
        inputValueString = inputValueString.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }
    // We filter only if the value is not empty
    if (inputValueString.length > 0) {
        // We build an array of terms to search by differentiating them based on whitespace separation in the inputed value
        let inputValuesArray = inputValueString.split(' ');
        // We check if the split has generated any empty string in the Search Terms array
        for (let i = 0; i < inputValuesArray.length; i++) {
            if (inputValuesArray[i] === '') {
                // When it is the case, we dropped them from the array
                inputValuesArray.splice(i,1);
            }
        }
        // We start building the string used later for the substitution Regex by joining the searh array terms with a | in order to search alternatively all terms
        const inputValuesStrRegex = '('+inputValuesArray.join('|')+')';
        // We build the Regex with Global & Insensitive flags to search for all occurences & despite cases
        let regex = new RegExp(inputValuesStrRegex, 'gi');
        // We get all historic items to cycle through them to decide if we need to hide them or keep them and highlight the search terms
        let historicList = JKisio('.historic-list li');
        Object.keys(historicList).forEach(function(element) {
            // We get the text ONLY form the historic item
            let originalStr = JKisio(element).text();
            if (!(isIE9 || isIE10 || isIE11)) {
                // On all modern browsers, we normalize the string to deconpose any accented characters and then remove the accent
                originalStr = originalStr.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            }
            // We need to determine before making the sustitution, if all terms are found in the item string
            // We initiate a variable which will increase when we found an occurence of a search term in the item string
            let toSubstitute = 0;
            for (const element of inputValuesArray) {
                let reg = new RegExp(element, 'gi');
                if (reg.test(originalStr)) {
                    toSubstitute++;
                }
            }
            // If the variable previously increased is equal to the length of the Search terms array, it means that all terms have been found in the historic item string
            // We can proceed with the substitution of the search terms to highlight them
            if (toSubstitute === inputValuesArray.length) {
                // We create the substitute string
                let subStr = '<strong>$&</strong>';
                // We execute the replaceAll with the RegExp Object and the substitute string
                let result = originalStr.replaceAll(regex, subStr);
                // In order to avoid strange behavior by the browser regarding whitespace and contiguous html tags, we replace normal whitespace by unbreakable ones
                let newStr = result.replaceAll(' ', '&nbsp;');
                // Now we clean again the string before implementing it in the html of the historic item
                let cleanStr = newStr.replaceAll('\\s', ' ');
                JKisio(element).html(cleanStr);
                // In case of previous masked historic items, we show it
                JKisio(element).show();
            } else {
                // We did NOT found ALL search terms in the historic item string, so we hide this historic item
                JKisio(element).hide();
            }
        })
    } else {
        // In case of previous masked historic items, we show all of them in case of empty inputed value
        JKisio('.historic-list li').show();
    }
    //Now we test if the inputed value made all historic items disappear
    if (JKisio('.historic-list li:visible').length === 0) {
        // In this case we need to hide the .historic-list elt
        JKisio('.historic-list').hide();
    }
}
