Kisio.loadBoardEvents = Kisio.loadBoardEvents || {};
Kisio.loadBoardEvents.models = Kisio.loadBoardEvents.models || {};
Kisio.loadBoardEvents.models.libModels = Kisio.loadBoardEvents.models.libModels || {};

Kisio.loadBoardEvents.models.libModels.manageJourneySearchModes = Backbone.Model.extend({
    params: {
        fieldAccordionContent: 'fieldset .ui-accordion-content',
        fieldAccordionHeader: 'fieldset .ui-accordion-header',
        boardContainerId: '#board_container',
        activeFileds: 'show journey-search-board-modes-selected',
        iconUp: 'ikisio-arrow-drop-up',
        iconDown: 'ikisio-arrow-drop-down'
    },

    /**
     * initialize search modes
     * @param widthBody
     * @param topBoard
     * @param desktopBreakPoint
     * @param animate
     */
    initialize: function (widthBody,topBoard, desktopBreakPoint, animate) {
        let container = this.params.boardContainerId;
        JKisio(this.params.fieldAccordionContent +
            ',' + this.params.fieldAccordionHeader).removeClass(this.params.activeFileds);
        JKisio(this.params.fieldAccordionHeader).attr('aria-expanded', false);
        JKisio(this.params.fieldAccordionContent).hide('fast', function () {
            if (animate !== 'disable') {
                if (widthBody >= desktopBreakPoint) {
                    if (animate) {
                        JKisio(container).animate({'top': topBoard}, "fast", "swing");
                    } else {
                        JKisio(container).css({'top': topBoard});
                    }
                } else {
                    JKisio(container).css({'top': 'auto'});
                }
            }
        });
        JKisio(this.params.fieldAccordionHeader + ' .arrow-ikisio')
            .removeClass(this.params.iconUp)
            .addClass(this.params.iconDown);
    }
});

export var manageJourneySearchModes = Kisio.loadBoardEvents.models.libModels.manageJourneySearchModes.extend({});