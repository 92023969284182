import { params } from 'pnp_core_bundle/structure';

export function initAutocompleteInputs() {
    window.autocomplete = {
        from: JKisio(params.fromId).val(),
        to: JKisio(params.toId).val()
    };

    window.autocompleteHidden = {
        from: JKisio(params.fromIdHidden).val(),
        to: JKisio(params.toIdHidden).val()
    };
}
