import { positionBoard } from 'pnp_core_bundle/structure/board/board_dynamic_position';

/**
 * Recalculate top position of board
 */
 export function reloadPositionBoard() {
    //Position board container
    let positionBoardInstance = new positionBoard();
    let widthBody = JKisio('body').outerWidth(true);
    let boardElement = JKisio('#board_container');
    let topBoard;
    if (boardElement.length > 0)
         topBoard = parseInt(boardElement.css('top').replace(/[^-\d\.]/g, ''), 10);
    positionBoardInstance.calculate(topBoard, widthBody, 600, 'init');
};