import {
    modelProximity
} from '/vendor/hove-io/proximity-bundle/Resources/public/js/proximity';
import { params } from './constants';

JKisio(document).ready(function () {
    JKisio(document).trigger('loadStructure');
});

JKisio(document).on('loadStructure', function () {
    let boardContainer = new modelLoadBoard();
    boardContainer.init(params);

    let prox = new modelProximity();
    prox.initializeProximity();
});

var modelLoadBoard = Kisio.loadBoardEvents.models.libModels.extend({

    /**
     * @param params
     */
    init: function (params) {
        let widthBody = this.getWidthOfElement('body');
        let topBoard = this.getPositionOfElement(params.boardContainerId);
        this.showContentSelected(params.boardContentId);
        this.clearable(params);

        this.manageDisplayBoardContent(
            params,
            topBoard,
            widthBody
        );

        if (JKisio(params.boardContentId).length > 0) {
            // datepicker initialize
            Kisio.DatePicker();
            Kisio.TimePicker('timepicker');

            // Display datetime info
            let headerDate = new Kisio.initHeaderDateTime();
            headerDate.initialize();

            JKisio(document).on("keydown", '*', function(e){
                if (e.keyCode === 9 && JKisio('body').find('.datepicker').is(':visible')) {
                    JKisio(".datepicker").hide();
                    JKisio('#datepicker .input-group.date').show();
                }
            });

        }

        /*
         * Display or not the bloc "Maintenant/Préférences"
         */
        this.manageSettingNowBlocs(
            topBoard,
            widthBody,
            params
        );

        this.manageInputChange(params, topBoard, widthBody);
    },

    /**
     * @param id
     * @returns {*}
     */
    getWidthOfElement: function (id) {
        return JKisio(id).outerWidth(true);
    }

});