import {getLineGeoJsonObject, showOneLineById, showStopPointsByLineId} from "../../load_infos";
import {
    addMarker,
    setUrlDynamically,
    showNextDepartures
} from "../../display_search_schedule_result";
import { mapPopupConfig } from 'pnp_core_bundle/plugnplay/leaf-core';

/**
 * Update line passing by stop point or stop area
 */
export function updateLinesByType(selectDisappear = false) {
    JKisio('#schedule-time-text').html(Translator.trans('schedule.result.next_departures.modify_time'));

    if (selectDisappear) {
        JKisio('#select-passing-lines-network-container').html('');
    }
    let stop_area_id = JKisio('#next_departures_results .lines span').attr('data-stop-area');
    if (typeof stop_area_id === 'undefined') {
        stop_area_id = JKisio('#next_departures_results .selected_line span').attr('data-stop-area');
    }
    let line_id = JKisio('.schedule-lines').attr('id');
    let line_label = JKisio('.schedule-lines').attr('data-line-label');
    JKisio('#button_action').remove();
    showNextDepartures(stop_area_id, line_id);

    // clear layers
    window.leaflet.map?.eachLayer(function (layer) {
        if (!('_url' in layer)) window.leaflet.map.removeLayer(layer);
    });
    addMarker(JKisio('#stop_area_label').attr('data-coord'));
    let lineColor;
    if (Kisio.schedule_map_config.line_drawings) {
        lineColor = showOneLineById(line_id, 1);
    }
    if (Kisio.schedule_map_config.stop_point_drawings && lineColor) showStopPointsByLineId(line_id, lineColor);

    // We change the URL (to share possibly) to line choice then Stop choice for giving to the user using the shared URL more choice by later using the back button
    setUrlDynamically(line_id, line_label, stop_area_id);
    JKisio('#loading-box').css("visibility", "hidden");
};

export function drawLineOnMap(data, opacity, clear) {
    if (typeof clear !== 'undefined') {
        clear = true;
    }
    let line = data.lines;

    line = getLineGeoJsonObject(line[0], clear, true, opacity);
    if (typeof line !== 'undefined' && Object.keys(line?.getBounds()).length !== 0) {
        if(!window.leaflet){
            return;
        }
        line?.addTo(window.leaflet.map);
        window.leaflet.map?.fitBounds(line?.getBounds(), mapPopupConfig(window.boardWidth));
    }
};
