import { showNextDepartures } from '../../../display_search_schedule_result';

export function getStopScheduleDirectionFromLocation() {
    const queryString = decodeURIComponent(window.location.search);
    if (queryString !== '') {
        const regex = /&direction_type=(forward|backward)/;
        const selected_direction = regex.exec(queryString);

        return selected_direction ? selected_direction[1] : 'forward';
    }

    return null;
};


export function reloadNextDepartures() {

    setTimeout(function(){
        let stop_area_id = JKisio('#next_departures_results #stop_area_label').attr('data-stop-area');
        if (typeof stop_area_id === 'undefined') {
            stop_area_id = JKisio('#next_departures_results .selected_line span').attr('data-stop-area');
        }
        const line_id = JKisio('.selected_line span').attr('id');
        const direction = getStopScheduleDirectionFromLocation();
        JKisio('#button_action, .notesContainer, #next_departures_table_front, #next_departures_table_back').remove();
        JKisio('.next-departures-result').html('<img src="/bundles/canaltppnpcore/images/loading.gif">');
        showNextDepartures(stop_area_id, line_id, false, null, direction);
        JKisio('.next-departures-result').html('');
    }, Kisio.schedule_reload_time);
}