import SimpleBar from 'pnp_core_bundle/simplebar/simplebar';
import { isIE9 } from 'pnp_core_bundle/plugnplay/browser';
import { mapPopupConfig,ManagePopup, parameters } from 'pnp_core_bundle/plugnplay/leaf-core';
import { getLinePicto } from 'pnp_core_bundle/plugnplay/customize_client';
import { initializeMap } from 'pnp_core_bundle/modules/map';

var getImgByMode = function (mode) {
    let img = window.Kisio.modes_img[mode];
    if (typeof window.Kisio.modes_img[mode] === 'undefined') {
        img = window.Kisio.modes_img['commercial_mode:' + mode];
    }
    return img;
};

/**
 * Show all lines for a network solo only !
 *
 * @param network_id
 * @param network_conf
 */
export var showAllLines = function(network_id, network_conf) {
    window.leaflet = window.leaflet || {};
    if(!window.leaflet.map){
        window.leaflet.map = initializeMap(parameters);
        window.boardWidth = (JKisio('#board').length > 0) ? JKisio('#board').outerWidth() + JKisio('#board').offset().left : 0;
    }
    //add simpleBar
    if (!isIE9) {
        try {
            new SimpleBar(JKisio('#schedule_lines_content')[0]);
        } catch (e) {
            console.log(e);
        }
    }
    JKisio('#loading-box').css("visibility","visible");
    if (typeof network_conf !== "undefined") {
        JKisio('#schedule_lines_content, #schedule_lines').show();
        let html_network = `<div class="schedule_section" data-network-id="${network_id}">`;
        if (network_conf.img != '' && network_conf.name != '') {
            html_network += '<div class="schedule_lines_network">' +
                '<img src="' + network_conf.img + '" />' +
                network_conf.name +
                '</div></div>';

        }
        JKisio('#schedule_lines').append(html_network);
    }
    JKisio.ajax({
        url: Routing.generate('r_public_transport_objects_filtered',
            {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                public_transport_object_type: 'networks',
                public_transport_object_id: network_id,
                action: 'lines',
                count: Kisio.max_lines
            }),
        async: true,
        dataType: 'json',
        success: function (data) {
            let arrayOfDisruption = [];
            if (typeof data.disruptions !== "undefined") {
                let disruptions = data.disruptions;
                let level_severity = '';
                disruptions.forEach(function (d) {
                    let severity = d.severity.effect;
                    switch (severity) {
                        case 'NO_SERVICE':
                            level_severity = 'disrupt';
                            break;
                        case 'SIGNIFICANT_DELAYS':
                        case 'REDUCED_SERVICE':
                        case 'DETOUR':
                        case 'ADDITIONAL_SERVICE':
                        case 'MODIFIED_SERVICE':
                        case 'UNKNOWN_EFFECT':
                        case 'STOP_MOVED':
                            level_severity = 'warning';
                            break;
                        case 'OTHER_EFFECT':
                        default:
                            level_severity = 'information';
                            break;
                    }
                    arrayOfDisruption[d.impacted_objects[0].pt_object.id] = {
                        'cause' : d.cause,
                        'image': '/bundles/canaltppnpcore/images/disruptions/'+level_severity+'.svg'
                    }
                });
            }
            if (typeof data.lines !== "undefined") {
                let shedule_line = new Kisio.shedule_line_order();
                let lines = data.lines;

                lines.forEach(function (i) {
                    if (Kisio.show_lines_by_id.length === 0 || Kisio.show_lines_by_id.indexOf(i.id) >= 0) {
                        let total = 0;
                        if (Kisio.show_all_lines_on_map && typeof i.geojson.coordinates[0] !== "undefined") {
                            for (const coordinate of i.geojson.coordinates) {
                                for (const element of coordinate) {
                                    let image = getImgByMode(i.commercial_mode.name.toLowerCase());
                                    if (total === Math.round(coordinate.length / 10) && typeof image !== "undefined") {
                                        let icon = L.icon({
                                            iconUrl: image,
                                            iconSize: [0, 0], // size of the icon
                                            iconAnchor: [element[1], element[0]], // point of the icon which will correspond to marker's location
                                        });
                                        let marker = L.marker([element[1], element[0]], {icon: icon, keyboard: false}).addTo(window.leaflet.map);
                                        let new_url = '?schedule[stop_area_lines][autocomplete]=' + i.name + '&schedule[stop_area_lines][autocomplete-hidden]=' + i.id;
                                        let url = Routing.generate('schedule_home', {type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''}) + new_url;
                                        let content = '<a href="'+url+'">' + i.commercial_mode.name+' '+i.code + '</a>';
                                        marker.bindTooltip( content, {
                                            interactive: true,
                                            permanent: true,
                                            pane: 'tooltipPane'
                                        }).openTooltip();
                                    }
                                    total++;
                                }
                            }
                            let myStyle = {
                                "color": "#fff",
                                "weight": 7,
                                "opacity": 1
                            };
                            let myStyle2 = {
                                "color": "#" + i.color,
                                "weight": 5,
                                "opacity": 1
                            };
                            L.geoJSON(i.geojson, {style: myStyle}).addTo(window.leaflet.map);
                            L.geoJSON(i.geojson, {style: myStyle2}).addTo(window.leaflet.map);

                        } else {
                            console.log('No coordinates found for line: ' + i.id)
                        }
                        let physicalMode = i?.physical_modes ? i.physical_modes[0].id : null;
                        if (typeof shedule_line[physicalMode] !== 'undefined') {
                            let m = shedule_line[physicalMode].length;
                            shedule_line[physicalMode][m++] = i;
                        }
                    }
                });

                let url = window.location.search;
                if (typeof Kisio.geojson_file === 'undefined' && url === '') {
                    window.leaflet.map.setZoom(12);
                }

                JKisio.each(shedule_line, function (index, mode) {
                    if (shedule_line[index].length > 0) {
                        let html = '<div class="sections"><div class="lines_sections">' +
                            '<img src="/bundles/canaltppnpcore/images/modes/' + shedule_line[index][0].commercial_mode.name.toLowerCase() + '.png" class="icon-mode" alt="' + shedule_line[index][0].commercial_mode.name.toLowerCase() + '" /> ' +
                            shedule_line[index][0].commercial_mode.name +
                            '</div>';
                        JKisio.each(shedule_line[index], function (s, line) {

                            if (line.code !== '') {
                                html += '<div class="lines">' + getLinePicto(line, arrayOfDisruption) + '</div>';
                            }
                        });
                        html += '</div>';

                        JKisio('.schedule_section[data-network-id="'+network_id+'"]').append(html);
                    }
                });
            }else{
                console.log("No data for lines available");
            }
            JKisio('#schedule-lines .lines span').tooltip();
            JKisio(document).on('click', '#schedule_lines .lines span', function () {
                if (typeof JKisio(this).attr('data-url') !== 'undefined') {
                    let new_url = JKisio(this).attr('data-url');
                    let redirect_url = Routing.generate('schedule_home', {type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''}) + new_url;
                    window.location.href = redirect_url;
                }
            });
            JKisio('#loading-box').css('visibility','hidden');
        },
        error: function(xhr, status, error) {
            console.log(error);
        }
    });
};

/**
 * Show all lines with stop points
 */
export var showAllLinesWithStopPoint = function(network_id) {

    JKisio('#loading-box').css("visibility","visible");

    JKisio.ajax({
        url: Routing.generate('r_public_transport_objects_filtered',
            {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                public_transport_object_type: 'networks',
                public_transport_object_id: network_id,
                action: 'lines',
                count: Kisio.show_max_nbr_lines
            }),
        async: false,
        dataType: 'json',
        success: function (data) {
            if (typeof data.lines !== "undefined") {
                let lines = data.lines;
                let lineColor;
                lines.forEach(function (i) {

                    let line_id = i.id;
                    if (Kisio.schedule_map_config.line_drawings) {

                        if (i.color !== 'FFFFFF') {
                            lineColor = '#' + i.color;
                        }else{
                            lineColor = '#' + i.text_color;
                        }

                        let line = getLineGeoJsonObject(i, false, true, 1);
                        if (typeof line !== 'undefined') {
                            line.addTo(window.leaflet.map);
                            window.leaflet.map.fitBounds(line.getBounds(), mapPopupConfig(window.boardWidth));
                        }
                    }
                    if (Kisio.schedule_map_config.stop_point_drawings && lineColor) showStopPointsByLineId(line_id, lineColor);

                });
            }else{
                console.log("No data for lines available");
            }

            JKisio('#loading-box').css('visibility','hidden');
        },
        error: function(xhr, status, error) {
            console.log(error);
        }
    });
};
/**
 * Show all stop areas
 *
 * @param network_id
 */
export var showAllStopAreas = function(network_id) {

    JKisio('#loading-box').css("visibility","visible");

    JKisio.ajax({
        url: Routing.generate('r_public_transport_objects_filtered',
            {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                public_transport_object_type: 'networks',
                public_transport_object_id: network_id,
                action: 'stop_areas',
                count: Kisio.max_stop_areas
            }),
        dataType: 'json',
        success: function (data) {

            if (typeof data.stop_areas !== "undefined") {

                let stopAreas = data.stop_areas;
                let sumLon = 0;
                let sumLat = 0;
                let group = L.featureGroup().addTo(window.leaflet.map);

                stopAreas.forEach(function (sp) {
                    if (!sp.coord || !sp.coord.lat || !sp.coord.lon) {
                        return;
                    }

                    let circleLayer = [];

                    let circleMarker = L.circleMarker(
                        [sp.coord.lat, sp.coord.lon], {
                            radius: 5,
                            width: 3,
                            color: 'red',
                            fillColor: '#fff',
                            fillOpacity: 0.5,
                            sp: sp
                        }
                    ).addTo(group);

                    circleLayer.push(circleMarker);

                    L.layerGroup(circleLayer).addTo(window.leaflet.map);
                    sumLon += parseFloat(sp.coord.lon);
                    sumLat += parseFloat(sp.coord.lat);

                });

                group.on('click', function (e) {
                    let popupContent = showLinesInPopup(e.layer.options.sp, 'stop_areas');
                    let popup = new L.Popup();
                    popup.setLatLng(e.latlng).setContent(popupContent).openOn(window.leaflet.map);
                    let point = window.leaflet.map.latLngToLayerPoint(e.layer.options.sp.coord);
                    ManagePopup(point, window.leaflet.map);
                    L.DomEvent.stopPropagation(e);
                });

                let lon = sumLon / stopAreas.length;
                let lat = sumLat / stopAreas.length;
                window.leaflet.map.panTo([lat, lon]).setZoom(12);
            }else{
                console.log("No data for stop areas available");
            }

            JKisio('#loading-box').css("visibility","hidden");
        },
        error: function (xhr, status, error) {
            console.log(error);
        }
    });
};

/**
 * Show all stop points
 *
 * @param network_id
 * @param color
 */
export let showStopPoints = function(network_id, color) {

    if (typeof color === "undefined") {
        color = 'blue';
    }

    let sumLon = 0;
    let sumLat = 0;

    JKisio('#loading-box').css("visibility","visible");

    JKisio.ajax({
        url: Routing.generate('r_public_transport_objects_filtered',
            {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                public_transport_object_type: 'networks',
                public_transport_object_id: network_id,
                action: 'stop_points',
                count: Kisio.max_stop_points
            }),
        dataType: 'json',
        success: function (data) {

            let stopPoints = data.stop_points;

            if (typeof stopPoints !== "undefined") {

                let group = L.featureGroup().addTo(window.leaflet.map);

                stopPoints.forEach(function (sp) {
                    if (!sp.coord || !sp.coord.lat || !sp.coord.lon) {
                        return;
                    }

                    let circleLayer = [];

                    let circleMarker = L.circleMarker(
                        [sp.coord.lat, sp.coord.lon], {
                            radius: 5,
                            width: 0.1,
                            color: color,
                            fillColor: 'white',
                            fillOpacity: 1,
                            sp: sp
                        }
                    ).addTo(group);

                    circleLayer.push(circleMarker);

                    L.layerGroup(circleLayer).addTo(window.leaflet.map);

                    sumLon += parseFloat(sp.coord.lon);
                    sumLat += parseFloat(sp.coord.lat);
                });

                group.on('click', function (e) {
                    let popupContent = showLinesInPopup(e.layer.options.sp, 'stop_points');
                    let popup = new L.Popup();
                    popup.setLatLng(e.latlng).setContent(popupContent).openOn(window.leaflet.map);
                    let point = window.leaflet.map.latLngToLayerPoint(e.layer.options.sp.coord);
                    ManagePopup(point, window.leaflet.map);
                    L.DomEvent.stopPropagation(e);
                });

                let lon = sumLon / stopPoints.length;
                let lat = sumLat / stopPoints.length;
                window.leaflet.map.panTo([lat, lon]).setZoom(12);
            }else{
                console.log("No data for stop points available");
            }
            JKisio('#loading-box').css("visibility","hidden");
        },
        error: function (xhr, status, error) {
            console.log(error);
        }
    });
};
