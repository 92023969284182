import { hasValidLine, hasValidNetwork, hasValidStopId } from "../../utils";

 /**
 * Function to display next departures in the list of lines after searching for a Stop
 */
export function getEachScheduleTimeByLine(resolve, item, stop_id, isIE, enable_favorites, favorites_manager) {
    // We fix async call or not depending on browsers --> IE: not async / Modern ones : async
    const async = !isIE;
    const html = '<div class="schedule_by_line_temp"><img src="/bundles/canaltppnpcore/images/loading.gif" /></div>';
    JKisio(item).append(html);
    const line_id = JKisio(item).attr('id');
    const network_id = JKisio(item).attr('data-network');
    const stop_area = JKisio(item).attr('data-content');
    

    if (!hasValidLine(line_id) || !hasValidNetwork(network_id) || !hasValidStopId(stop_id)) {
            console.log('You have requested invalid parameters ',
            {
                'line id': line_id,
                'network id': network_id,
                'stop area': stop_area
            }
        );

        return;
    }

    const query = {
        type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
        stop_area_id: stop_id,
        line_id: line_id,
        network_id: network_id,
        from_datetime: 'now',
        data_freshness: 'realtime'
    };

    JKisio.ajax({
        url: Routing.generate('schedule_next_time', query),
        async: async,
        success: function (data) {
            decodeURIComponent(window.location.search);
            let favoritesParamsString;
            let favoritesParams;
            JKisio('.schedule_by_line_temp').remove();
            let html = '<div class="schedule_by_line clearfix">';
            let direction = Object.keys(data.schedule)[0];
            let direction_type;
            let favStarHtmlStart;
            let favStarHtmlEnd
            if (Object.keys(data.direction_type)[0] === direction) {
                direction_type = data.direction_type[direction];
            }
            // Prepare HTML for Favorites
            if (enable_favorites && favorites_manager !== undefined) {
                favoritesParams = {
                    stop_area_id: stop_id,
                    line_id: line_id,
                    network_id: network_id,
                    stop_area: stop_area
                };
                const ariaLabelAdd = Translator.trans('line_schedule.result.favorites.stop_line_direction.add');
                const ariaLabelDel = Translator.trans('line_schedule.result.favorites.stop_line_direction.del');
                favStarHtmlStart = '<div class="fav-star fav-star-add fav-stop_area_lines_direction" data-favorites-manager="' + favorites_manager + '"' +
                    ' data-favorites-type-section="schedule"' +
                    ' data-favorites-type="stop_area_lines_direction"' +
                    ' data-aria-label-add="' + ariaLabelAdd + '"' +
                    ' data-aria-label-del="' + ariaLabelDel + '"' +
                    ' tabindex="0"' +
                    ' title="' + ariaLabelAdd + '"' +
                    ' aria-label="' + ariaLabelAdd + '"' +
                    ' data-favorites-params="';
                favStarHtmlEnd = '"></div>';
            }

            if (typeof data.schedule[direction] !== 'undefined' && data.schedule[direction] !== null) {
                let time = data.schedule[direction][0];
                if (time === 'no_departure_this_day') {
                    time = Translator.trans('stop_schedules.' + time);
                }
                if (time === 'active_disruption') {
                    time = Translator.trans('schedule.result.disruptions.multiple');
                }
                if (time !== 'terminus' && time !== 'partial_terminus') {
                    html += '<span data-direction="' + direction_type + '">' + direction + '</span> <b>';

                    let time_values = time;
                    if (Kisio.nbr_schedule_by_line > 1) {
                        const max = Kisio.nbr_schedule_by_line - 1;
                        for (let i = 1; i <= max; i++) {
                            if (typeof data.schedule[direction][i] !== 'undefined') {
                                time_values = time_values + '/' + data.schedule[direction][i];
                            }
                        }
                    }
                    if (enable_favorites && favorites_manager !== undefined) {
                        favoritesParams.direction_type = direction_type;
                        favoritesParamsString = encodeURIComponent(JSON.stringify(favoritesParams));
                        html += favStarHtmlStart + favoritesParamsString + favStarHtmlEnd;
                    }
                    // In case of favorites management, we finish its HTML
                    html += time_values + '</b>';
                    html += '<br /> ';
                }
            }
            direction = Object.keys(data.schedule)[1];
            if (Object.keys(data.direction_type)[1] === direction) {
                direction_type = data.direction_type[direction];
            }
            if (typeof data.schedule[direction] !== 'undefined' && data.schedule[direction] !== null) {
                let time = data.schedule[direction][0];
                if (time === 'no_departure_this_day') {
                    time = Translator.trans('stop_schedules.' + time);
                }
                if (time === 'active_disruption') {
                    time = Translator.trans('schedule.result.disruptions.multiple');
                }
                if (time !== 'terminus' && time !== 'partial_terminus') {
                    // In case of favorites management, we finish its HTML
                    if (enable_favorites && favorites_manager !== undefined) {
                        favoritesParams.direction_type = direction_type;
                        favoritesParamsString = encodeURIComponent(JSON.stringify(favoritesParams));
                        html += favStarHtmlStart + favoritesParamsString + favStarHtmlEnd;
                    }
                    html += '<span data-direction="' + direction_type + '">' + direction + '</span> <b>' + time + '</b>';
                }
            }
            html += '</div>';
            JKisio(item).append(html);
            // In case of Modern browser we need to resolve the Promise
            if (!isIE) {
                resolve();
            }
        },
        error: function (data) {
            // In case of Modern browser we need to resolve the Promise
            if (!isIE) {
                resolve();
            }
        }
    });
}