const line_regex = /^line:[a-zA-Z0-9:_-]+/;
const network_regex = /^network:[a-zA-Z0-9:_-]+/;
const stop_area_regex = /^stop_area:[a-zA-Z0-9:_-]+/;
const stop_point_regex = /^stop_point:[a-zA-Z0-9:_-]+/;

export function hasValidLine(line) {
    return line_regex.test(line);
}

export function hasValidNetwork(network) {
    return network_regex.test(network);
}

export function hasValidStopArea(stopArea) {
    return stop_area_regex.test(stopArea);
}

export function hasValidStopPoint(stopPoint) {
    return stop_point_regex.test(stopPoint);
}

export function hasValidStopId(stopId) {
    return hasValidStopArea(stopId) || hasValidStopPoint(stopId);
}