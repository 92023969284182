import { modelDisplaySearchScheduleResults } from './result/schedule_result';
import { getModePicto, getLinePicto } from 'pnp_core_bundle/plugnplay/customize_client';
import { parameters } from 'pnp_core_bundle/plugnplay/leaf-core';
import { showOneLineById, showStopPointsByLineId } from './load_infos';
import { getCurrentDirectionEventListeners, displayVehiclePositionByRouteId, clearIntervalVehiclePosition } from './module/vehicle-positions/index';
import {
    initTimeTable,
    downloadTimeTableButton,
    downloadEventListeners,
    initShowTimeTableLink
} from './module/time-table';
import { isIE9 } from 'pnp_core_bundle/plugnplay/browser';
import SimpleBar from 'pnp_core_bundle/simplebar/simplebar';
import { getStopScheduleDirectionFromLocation, reloadNextDepartures } from './module/stop-schedule';
import { updateLinesByType } from './module/line-schedule';
import {showPlanEventListener} from "./module/time-table/event-listeners";
import DisruptionsModel from "./result/disruptions/index";
import { removeQueryParamsFromUrl } from 'pnp_core_bundle/modules/utils';

export var setUrlDynamically,
    showPassingLine,
    addMarker,
    getDisruptions,
    showNextDepartures,
    showLinesByType,
    showSelectedPartofTableDepartures,
    getNewOffset,
    showTimeTable,
    showFirstAndLastPassage,
    delegateEvents;

let nextDaparturesCallback = null;

/**
 * Traitement de l'evenement de choix d'un arret / ligne dans l'onglet prochain passage
 */
JKisio(document).on('displaySearchScheduleResults', function (e, uri, label) {
    e.stopImmediatePropagation();
    let model = new modelDisplaySearchScheduleResults();
    let values = uri.split(':');
    let queryString = decodeURIComponent(window.location.search);
    let queryScheduleSelected = '';
    let queryDirectionType = '';
    if (queryString !== '') {
        let regex = /&schedule_selected=(.+)&direction_type=(forward|backward)/;
        let m;
        if ((m = regex.exec(queryString)) !== null) {
            queryScheduleSelected = m[1];
            queryDirectionType = m[2];
        }
    }
    switch (values[0]) {
        case 'line':
            model.displayLineInfos('r_public_transport_objects_filtered', uri, label, queryScheduleSelected, queryDirectionType);
            break;
        case 'stop_area':
            model.updateList('r_public_transport_objects_filtered', uri, label, queryScheduleSelected, queryDirectionType, 'stop_areas');
            break;
        case 'stop_point':
            model.updateList('r_public_transport_objects_filtered', uri, label, queryScheduleSelected, queryDirectionType, 'stop_points');
            break;
    }

    if (nextDaparturesCallback !== null) {
        document.body.removeEventListener('show_next_departures', nextDaparturesCallback);
    }
    nextDaparturesCallback = function(e){

        showNextDepartures(e.detail.stop_area, e.detail.id, e.detail.show_in_popup, e.detail.network_id, e.detail.direction)
    }
    document.body.addEventListener('show_next_departures', nextDaparturesCallback);
});


(function () {
    let baseNotesForward = [];
    let baseNotesBackward = [];
    let isODTNoNotesForward = false;
    let isODTNoNotesBackward = false;

    /**
     * Click sur un stop area renvoie vers la page de résultat
     *
     * @param offset
     * @param count
     */
    const manageStopArea = function (offset, count) {
        let max = parseInt(Kisio.max_nb_next_departures);
        if (typeof offset === 'undefined' || offset === null) {
            offset = 0;
        }
        if (typeof count === 'undefined' || count === null) {
            count = max;
        }

        JKisio('#next_departures_results .next, #next_departures_results .prev').off('click').on('click', function () {

            let action = JKisio(this).attr('class');
            if (action.indexOf('next') > -1) {
                offset = parseInt(offset) + max;
                count = parseInt(count) + max;
            }
            if (action.indexOf('prev') > -1) {
                offset = parseInt(offset) - max;
                count = parseInt(count) - max;
            }

            if (offset < 0) {
                offset = 0;
            }
            if (count < max) {
                count = max;
            }
            manageNextPrevButtons(offset, count);
        });
    };

    /**
     * Show line passing by stop point or stop area
     *
     * @param object_id
     * @param object_type
     * @param id_line_selected
     * @param network_id
     */
    showLinesByType = function(object_id, object_type, id_line_selected, network_id) {
        let query = {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                public_transport_object_type: object_type,
                public_transport_object_id: object_id,
                action: 'lines',
                count: Kisio.max_lines
        };
        if (typeof network_id !== 'undefined') {
            query.network = network_id;
        }
        JKisio.ajax({
            url: Routing.generate('r_public_transport_objects_filtered', query),
            dataType: 'json',
            success: function (result) {
                if (typeof result.lines !== 'undefined') {
                    let shedule_line = new Kisio.shedule_line_order();
                    JKisio.each(result.lines, function (nbr, l) {
                        if (JKisio('#schedule_content .schedule-lines').length > 0) {
                            let physicalMode = l.physical_modes[0].id;
                            if (typeof shedule_line[physicalMode] !== 'undefined') {
                                let m = shedule_line[physicalMode].length;
                                shedule_line[physicalMode][m++] = l;
                            }
                        }

                    });

                    if (Kisio.filter_by_stop_point == '1') {
                        showPassingLine(shedule_line, object_id, id_line_selected);
                    }

                    //When choosing stop area and then line, get all disruptions
                    let query = {
                        type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                        public_transport_object_type: object_type,
                        public_transport_object_id: object_id,
                        action: 'line_reports',
                        count: Kisio.max_lines
                    }
                    if (typeof network_id !== 'undefined') {
                        query.network = network_id;
                    }
                    JKisio.ajax({
                        url: Routing.generate('r_public_transport_objects_filtered', query),
                        dataType: 'json',
                        success: function (result) {
                            getDisruptions(result.disruptions);
                        }
                    });
                }
            }
        });
    };

    /**
     * Show all passing lines
     * @param shedule_line
     * @param object_id
     * @param id_line_selected
     */
    showPassingLine = function(shedule_line, object_id, id_line_selected) {
        JKisio.each(shedule_line, function (index, mode) {
            if (mode.length === 0) {
                delete shedule_line[index];
            } else {
                let passing_lines = [];
                let shortIndex = index.replace('physical_mode:', '');
                let network_passing_lines = '';
                let show_once = 0;
                let tmp_mode = '';
                let html = '';
                let selected = '';
                let number_lines_to_deduce_from_passing_lines = 0;
                JKisio.each(shedule_line[index], function (s, line) {
                    let mode = line.commercial_mode.name.toLowerCase();
                    let img = getModePicto(line.commercial_mode.name);
                    if (line.code !== '') {
                        if (s < 1) {
                            JKisio('#schedule_content .schedule-lines').append(img);
                        }
                        if (typeof id_line_selected !== 'undefined' && line.id === id_line_selected) {
                            html = '<div class="selected_line" tabindex="0">';
                            selected = true;
                        }else{
                            html = '<div class="lines" tabindex="0">';
                            selected = false;
                        }

                        html += getLinePicto(line, undefined, undefined, object_id, undefined, undefined, selected);

                        number_lines_to_deduce_from_passing_lines++;
                        JKisio('#schedule_content .schedule-lines').append(html);
                    } else {
                        passing_lines.push([line.id, line.name, line.physical_modes[0].id.replace('physical_mode:', '').toLowerCase()]);
                    }
                    if (line.code === '' && show_once === 0) {
                        if (line.network !== undefined && line.network.name !== undefined) {
                            network_passing_lines = line.network.name;
                        }
                        JKisio('#schedule_content .schedule-lines').append(
                            '<div class="passing-lines">' +
                                '<span data-html="true" data-passing-lines-mode="' + shortIndex + '">' + img + '</span>' +
                            '</div>');
                        show_once++;
                    }
                    tmp_mode = line.commercial_mode.name.toLowerCase();
                    if (tmp_mode !== mode) {
                        show_once = 0;
                    }
                    if (typeof line.disruptions !== 'undefined' && typeof line.disruptions[0].impacted_objects !== 'undefined'
                        && line.disruptions[0].impacted_objects[0].pt_object.embedded_type === 'line'
                        && id_line_selected === line.disruptions[0].impacted_objects[0].pt_object.id) {
                        getDisruptions(line.disruptions);
                    }
                });
                if (passing_lines.length > 0) {
                    JKisio('#schedule_content .passing-lines span[data-passing-lines-mode="' + shortIndex + '"]')
                        .data('passing-lines', passing_lines)
                        .append('<span class="passing-lines-number">'+ (shedule_line[index].length - number_lines_to_deduce_from_passing_lines) +'</span>')
                        .append('<img src="/bundles/canaltppnpcore/images/networks/network_'+network_passing_lines+'.png" class="icon-mode passing-lines-network" alt="' + network_passing_lines + '" />');
                }
            }
        });
        managePassingLines();
        // Manage selecting SNCF line after coming directly by GET access
        if (JKisio('.selected_line').length === 0 && id_line_selected !== undefined && id_line_selected.indexOf('SCF') > 0) {
            let spanToBeSelected;
            let passingLinesSpan = JKisio('.passing-lines > span');
            JKisio.each(passingLinesSpan, function () {
                let data = JKisio(this).data('passing-lines');
                for (const element of data) {
                    if (element.indexOf(id_line_selected) > -1) {
                        spanToBeSelected = JKisio(this);
                    }
                }
            });
            if (spanToBeSelected !== undefined) {
                JKisio(spanToBeSelected).trigger('click', [true]);
                setTimeout(function () {
                    JKisio('.select-passing-lines-network-option-choice').trigger('click');
                    setTimeout(function () {
                        JKisio('.select-passing-lines-network-option[data-value="' + id_line_selected + '"]').trigger('click', [true]);
                    }, 300);
                }, 300);
            }
        }
    };

    /**
     * Clean and update disruptions
     * @param disruptions
     */
     getDisruptions = function(disruptions) {
        if (disruptions && disruptions.length > 0) {
            if (JKisio('#disruption').length > 0) {
                JKisio('#disruption').empty();
            }
            let model = new DisruptionsModel();
            let disruptionInfo = '';
            if (disruptions.length > 1) {
                disruptionInfo = model.renderLineDisruption({
                    'disruptions': disruptions,
                    'multiple': true
                });
            }else {
                disruptionInfo = model.renderLineDisruption({
                    'disruptions': disruptions
                });
            }
            if (JKisio('#ctp-lineSccheduleLineStopAreasSearch').length > 0 && JKisio('#ctp-lineSccheduleLineStopAreasSearch').is(':visible')) {
                disruptionInfo.insertBefore('#ctp-lineSccheduleLineStopAreasSearch');
            }else{
                JKisio('#disruption').append(disruptionInfo);
            }
            JKisio('.disruption-line').click(function () {
                model.showPopup(disruptions);
            });
        }
    };

    /**
     * Update line passing by stop point or stop area
     * @param selectDisappear
     */
    const updateLinesByType = function(selectDisappear) {
        JKisio('#schedule-time-text').html(Translator.trans('schedule.result.next_departures.modify_time'));

        if (selectDisappear) {
            JKisio('#select-passing-lines-network-container').html('');
        }
        let stop_area_id = JKisio('#next_departures_results .lines span').attr('data-stop-area');
        if (typeof stop_area_id === 'undefined') {
            stop_area_id = JKisio('#next_departures_results .selected_line span').attr('data-stop-area');
        }
        let line_id = JKisio('.schedule-lines').attr('id');
        let line_label = JKisio('.schedule-lines').attr('data-line-label');
        JKisio('#button_action').remove();
        showNextDepartures(stop_area_id, line_id);

        // clear layers
        window.leaflet.map.eachLayer(function (layer) {
            if (!('_url' in layer)) window.leaflet.map.removeLayer(layer);
        });
        addMarker(JKisio('#stop_area_label').attr('data-coord'));
        let lineColor = '';
        if (Kisio.schedule_map_config.line_drawings) {
            lineColor = showOneLineById(line_id, 1);
        }
        if (Kisio.schedule_map_config.stop_point_drawings && lineColor) showStopPointsByLineId(line_id, lineColor);

        // We change the URL (to share possibly) to line choice then Stop choice for giving to the user using the shared URL more choice by later using the back button
        setUrlDynamically(line_id, line_label, stop_area_id);
        JKisio('#loading-box').css("visibility", "hidden");
    };

    let directionLabel = {
        direction: '',
        set: function (direction) {
            this.direction = direction;
        },
        get: function () {
            return this.direction;
        }
    };

    /**
     * Add marker on map
     * @param coords
     */
     addMarker = function(coords) {
        if (!window.leaflet) {
            return;
        }
        if (typeof coords !== 'undefined') {
            if(JKisio('img.leaflet-marker-icon').length > 0) {
                JKisio('img.leaflet-marker-icon').remove();
            }
            let icon = L.icon({
                iconUrl: parameters.urlImages + 'map-marker-from.svg',
                iconSize: parameters.icon_size
            });
            let object_coord = coords.split(';');
            if (typeof object_coord !== 'undefined' && Object.keys(object_coord).length > 1) {
                L.marker([object_coord[0], object_coord[1]], {icon: icon, keyboard: false}).addTo(window.leaflet.map);
                window.leaflet.map.setView([object_coord[0], object_coord[1]]);
            }
        }
    };

    /**
     *  Show and clean next departures section
     * @param stop_area_id
     * @param line_id
     * @param show_in_popup
     * @param network_id
     * @param route_direction
     */
    showNextDepartures = function(stop_area_id, line_id, show_in_popup, network_id, route_direction) {
        let html = '';
        let htmlFront = [];
        let htmlFrontRealtime = [];
        let htmlBackRealtime = [];
        let htmlBack = [];
        let nbr_f = 0;
        let nbr_fr = 0;
        let nbr_b = 0;
        let nbr_br = 0;
        let offset = 0;
        let no_departure = false;
        let isError = false;
        if(typeof stop_area_id === 'undefined') stop_area_id = JKisio('#next_departures_results #stop_area_label').attr('data-stop-area');
        if(typeof line_id === 'undefined') line_id = JKisio('.schedule-lines').attr('id');
        if(typeof show_in_popup === 'undefined') show_in_popup = false;

        let date = JKisio('#schedule_from_datetime #date-input-picker').val();
        let time = JKisio('#schedule_from_datetime .schedule-timepicker').val();
        let currentDate = new Date();
        let minutes = currentDate.getMinutes();
        if (minutes < 10) {
            minutes = '0'+minutes;
        }

        let splittime = time.split(':');
        let oldhours = splittime[0];
        let oldminutes = splittime[1];

        if (oldminutes < minutes) {
            time = oldhours+':'+minutes;
            JKisio('#schedule_from_datetime .schedule-timepicker').val(time);
        }

        if (typeof route_direction === 'undefined') {
            route_direction = '';
        }
        let changeDirection = false;

        //Clean any error
        JKisio('#stop_schedule_error').remove();

        //Check if timestamp has changed (when selecting a new date)
        let timestamp = parseInt(JKisio('#schedule_from_datetime').attr('data-timestamp'));
        let newDate = parseInt(Math.floor(new Date().getTime() / 1000));
        if (newDate === timestamp || (newDate-1) === timestamp || (newDate+1) === timestamp) {
            timestamp = 0;
        }

        if (typeof JKisio('#next_departures_results #stop_area_label').attr('data-stop-point') !== 'undefined' && Kisio.filter_by_stop_point == '0') {
            stop_area_id = JKisio('#next_departures_results #stop_area_label').attr('data-stop-point');
        }

        let query = {
            type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
            stop_area_id: stop_area_id,
            line_id: line_id,
            direction_type: 'forward',
            show_in_popup: show_in_popup
        };
        if (typeof date !== 'undefined') query.date = date;
        if (typeof time !== 'undefined') query.time = time;
        if (network_id && typeof network_id !== 'undefined') query.network = network_id;

        let stop_point_name = '';
        //Forward + base_schedule
        if (Kisio.gtfsrt_enabled === 0) {
            JKisio.ajax({
                url: Routing.generate('schedule_next_time', query),
                async: false,
                success: function (data) {
                    if (typeof data.error !== 'undefined' && data.error !== '') {
                        isError = true;
                    }
                    if (typeof data.first_datetime !== 'undefined' && typeof data.last_datetime !== 'undefined') {
                        let hours_content = Translator.trans('schedule.opening_time') + data.first_datetime + '<div class="closing_time">' + Translator.trans('schedule.closing_time') + data.last_datetime + '</div>';
                        const forwardTimesElements = JKisio('#stop_area_hours #forward');
                        forwardTimesElements.html(hours_content);
                        forwardTimesElements.attr('data_first_datetime', data.raw_first_datetime);
                        forwardTimesElements.attr('data-route-id', data?.route_id);
                    }
                    if (typeof data.schedule !== 'undefined' && typeof (data.schedule) === 'object' && data.schedule !== null) {
                        baseNotesForward = data.baseNotes;
                        isODTNoNotesForward = data.isODTNoNotes;
                        let result = Object.keys(data.schedule)[0];
                        directionLabel.set(result);
                        if (Array.isArray(data.schedule[result])) {
                            JKisio.each(data.schedule[result], function (direction, values) {
                                if (values === 'no_departure_this_day') {
                                    no_departure = true;
                                } else if (values !== 'partial_terminus') {
                                    nbr_f++;
                                    htmlFront.push(values);
                                }
                            });
                        }
                    }
                    if (typeof data.disruptions !== 'undefined' && JKisio('#disruption').length === 0) {
                        getDisruptions(data.disruptions);
                    }
                    stop_point_name = data.stop_points?.forward?.name;
                },
                error: function (xhr, status, error) {
                    console.log(error);
                }
            });
        }

        //Forward + realtime
        if (Kisio.realtime_activated === 1) {
            query.data_freshness = 'realtime';
            JKisio.ajax({
                url: Routing.generate('schedule_next_time', query),
                async:false,
                success: function(data) {
                    if (typeof data.error !== 'undefined' && data.error !== '') {
                        isError = true;
                    }
                    if (typeof data.first_datetime !== 'undefined' && typeof data.last_datetime !== 'undefined') {
                        let hours_content = Translator.trans('schedule.opening_time') + data.first_datetime + '<div class="closing_time">' + Translator.trans('schedule.closing_time') + data.last_datetime + '</div>';
                        const forwardTimesElement = JKisio('#stop_area_hours #forward');
                        forwardTimesElement.html(hours_content);
                        forwardTimesElement.attr('data-route-id', data?.route_id);
                        forwardTimesElement.attr('data_first_datetime', data.raw_first_datetime);
                    }
                    if (!Array.isArray(data['schedule'])) {
                        baseNotesForward = data.baseNotes;
                        isODTNoNotesForward = data.isODTNoNotes;
                        let result = Object.keys(data['schedule'])[0];
                        directionLabel.set(result);
                        if (Array.isArray(data['schedule'][result])) {
                            JKisio.each(data['schedule'][result], function (direction, values) {
                                if (values !== 'active_disruption' && values !== 'partial_terminus' && values !== 'no_departure_this_day') {
                                    nbr_fr++;
                                    htmlFrontRealtime.push(values);
                                }
                            });
                        }
                    }
                    stop_point_name = data.stop_points?.forward?.name;
                },
                error: function (xhr, status, error) {
                    console.log(error);
                }
            });
        }

        //Cut the htmlFrontArray to include realtime
        let isTerminusFront = false;
        let cutoffsetFront = 0;
        let ts;
        if (htmlFrontRealtime.length > 0 && htmlFront.length > 0) {
            for (let k = 0; k < nbr_f; k++) {
                if (typeof htmlFront[k] !== 'undefined' && htmlFront[k] !== 'terminus') {
                    if (htmlFront[k].indexOf(' min') > 0) {
                        cutoffsetFront = k;
                        break;
                    }else{
                        ts = htmlFront[k].match(/\d+/)[0];
                        if (timestamp < ts) {
                            cutoffsetFront = k;
                        }
                    }
                }else{
                    isTerminusFront = true;
                }
            }

            let firstPart = htmlFront.slice(0, cutoffsetFront);
            let cut = cutoffsetFront + nbr_fr;
            let lastPart = htmlFront.slice(cut, nbr_f);
            let tab = htmlFrontRealtime.concat(lastPart);
            htmlFront = firstPart.concat(tab);
        }else if (htmlFrontRealtime.length > 0 && htmlFront.length === 0) {
            for (let l = 0; l < nbr_fr; l++) {
                if (htmlFrontRealtime[l] === 'terminus') {
                    isTerminusFront = true;
                }else{
                    ts = htmlFrontRealtime[l].match(/\d+/)[0];
                    if (timestamp < ts) {
                        cutoffsetFront = l;
                    }
                }
            }
            htmlFront = htmlFrontRealtime;
        }

        //Filter for array unique results
        htmlFront = htmlFront.filter(onlyUnique);

        if (htmlFront.length > 0) {
            if (route_direction === '') {
                route_direction = 'forward';
            }
            html = '<table id="next_departures_table_front" class="next_departures_table" tabindex="0" aria-live="polite">';
            let newOffset = 0;

            for (let m = 0; m < htmlFront.length; m++) {
                if (typeof htmlFront[m] !== 'undefined') {
                    ts = parseInt(JKisio(htmlFront[m]).attr('data-timestamp'));
                    if (timestamp > ts) {
                        newOffset = m + 1;
                    }
                    html += '<tr><td>' + directionLabel.get() + '</td><td>' + htmlFront[m] + '</td></tr>';
                }
            }
            html += '</table>';

            //In case of there is stop schedules after now
            if (typeof htmlFront[newOffset] === 'undefined') {
                newOffset = 0;
            }
        }

        /**
         * Backward
         */
        query.direction_type = 'backward';
        if (Kisio.gtfsrt_enabled === 0) {
            query.data_freshness = 'base_schedule';
            JKisio.ajax({
                url: Routing.generate('schedule_next_time', query),
                async: false,
                success: function (data) {
                    if (typeof data.error !== 'undefined' && data.error !== '') {
                        isError = true;
                    }
                    if (typeof data.first_datetime !== 'undefined' && typeof data.last_datetime !== 'undefined') {
                        let hours_content = Translator.trans('schedule.opening_time') + data.first_datetime + '<div class="closing_time">' + Translator.trans('schedule.closing_time') + data.last_datetime + '</div>';
                        const backwardTimesElement = JKisio('#stop_area_hours #backward');
                        backwardTimesElement.html(hours_content);
                        backwardTimesElement.attr('data_first_datetime', data.raw_first_datetime);
                        backwardTimesElement.attr('data-route-id', data?.route_id);
                    }
                    if (typeof data.schedule !== 'undefined' && typeof (data.schedule) === 'object' && data.schedule !== null && data.schedule !== 'no_departure_this_day') {
                        baseNotesBackward = data.baseNotes;
                        isODTNoNotesBackward = data.isODTNoNotes;
                        let result = Object.keys(data.schedule)[0];
                        directionLabel.set(result);
                        if (Array.isArray(data.schedule[result])) {
                            JKisio.each(data.schedule[result], function (direction, values) {
                                if (values === 'no_departure_this_day') {
                                    no_departure = true;
                                } else if (values !== 'partial_terminus') {
                                    nbr_b++;
                                    htmlBack.push(values);
                                }
                            });
                        }
                    }
                    if (route_direction == query.direction_type) {
                        stop_point_name = data.stop_points?.backward?.name;
                    }
                },
                error: function (xhr, status, error) {
                    console.log(error);
                }
            });
        }

        //Backward + realtime
        if (Kisio.realtime_activated === 1) {
            query.data_freshness = 'realtime';
            JKisio.ajax({
                url: Routing.generate('schedule_next_time', query),
                async: false,
                success: function (data) {
                    if (typeof data.error !== 'undefined' && data.error !== '') {
                        isError = true;
                    }
                    if (typeof data.first_datetime !== 'undefined' && typeof data.last_datetime !== 'undefined') {
                        let hours_content = Translator.trans('schedule.opening_time') + data.first_datetime + '<div class="closing_time">' + Translator.trans('schedule.closing_time') + data.last_datetime + '</div>';
                        const backwardTimesElement = JKisio('#stop_area_hours #backward');
                        backwardTimesElement.html(hours_content);
                        backwardTimesElement.attr('data_first_datetime', data.raw_first_datetime);
                        backwardTimesElement.attr('data-route-id', data?.route_id);
                    }
                    if (!Array.isArray(data['schedule'])) {
                        baseNotesBackward = data.baseNotes;
                        isODTNoNotesBackward = data.isODTNoNotes;
                        let result = Object.keys(data['schedule'])[0];
                        directionLabel.set(result);
                        if (Array.isArray(data['schedule'][result])) {
                            JKisio.each(data['schedule'][result], function (direction, values) {
                                if (values !== 'active_disruption' && values !== 'partial_terminus' && values !== 'no_departure_this_day') {
                                    nbr_br++;
                                    htmlBackRealtime.push(values);
                                }
                            });
                        }
                    }
                    if (route_direction == query.direction_type) {
                        stop_point_name = data.stop_points?.backward?.name;
                    }
                },
                error: function (xhr, status, error) {
                    console.log(error);
                }
            });
        }

        updateStopAreaLabel(stop_point_name);
        //Cut the htmlBackArray to include realtime
        let isTerminusBack = false;
        let cutoffsetBack = 0;
        if (htmlBackRealtime.length > 0 && htmlBack.length > 0) {
            for (let i = 0; i < nbr_b; i++) {
                if (typeof htmlBack[i] !== 'undefined' && htmlBack[i] !== 'terminus') {
                    if (htmlBack[i].indexOf(' min') > 0) {
                        cutoffsetBack = i;
                        break;
                    }else{
                        ts = htmlBack[i].match(/\d+/)[0];
                        if (timestamp < ts) {
                            cutoffsetBack = i;
                        }
                    }
                }else{
                    isTerminusBack = true;
                }
            }
            let firstPartBack = htmlBack.slice(0, cutoffsetBack);
            let cutBack = cutoffsetBack + nbr_br;
            let lastPartBack = htmlBack.slice(cutBack, nbr_b);
            let tabBack = htmlBackRealtime.concat(lastPartBack);
            htmlBack = firstPartBack.concat(tabBack);
        }else if (htmlBackRealtime.length > 0 && htmlBack.length == 0) {
            //Check if there is a terminus in realtime results
            for (let j = 0; j < nbr_br; j++) {
                if (htmlBackRealtime[j] === 'terminus') {
                    isTerminusBack = true;
                }else{
                    ts = htmlBackRealtime[j].match(/\d+/)[0];
                    if (timestamp < ts) {
                        cutoffsetBack = j;
                    }
                }
            }
            htmlBack = htmlBackRealtime;
        }

        //Filter for array unique results
        htmlBack = htmlBack.filter(onlyUnique);

        let htmlClass;
        //Format table for backward
        if (htmlBack.length > 0) {
            if (htmlFront.length === 0) {
                if (route_direction === '') {
                    route_direction = 'backward';
                }
                htmlClass = '';
                JKisio('#stop_area_hours #forward').hide();
                JKisio('#stop_area_hours #backward').show();
            }else{
                htmlClass = 'hide';
                JKisio('#stop_area_hours #backward').hide();
                JKisio('#stop_area_hours #forward').show();
            }
            html += '<table id="next_departures_table_back" class="'+ htmlClass +' next_departures_table" tabindex="0" aria-live="polite">';

            for (const element of htmlBack) {
                if (typeof element !== 'undefined') {
                    html += '<tr><td>' + directionLabel.get() + '</td><td>' + element + '</td></tr>';
                }
            }
            html += '</table>';
        }

        //Add change route_direction or new search button to html content
        html += '<div id="button_action">';
        if (htmlFront.length > 0 && htmlBack.length > 0) {
            html += '<a href="#" id="change_direction" role="button"><span class="ikisio ikisio-reverse"></span> ' + Translator.trans('schedule.result.next_departures.change_direction') + '</a>';
        }else if (isError) {
            html += '<div id="stop_schedule_error">'+Translator.trans('schedule.stop_schedule_error')+'</div>';
        }else if (htmlBack.length === 0 && htmlFront.length === 0) {
            html = '<div id="schedule-form-new-search">';
            if (no_departure) {
                html += Translator.trans('schedule.no_departure_this_day');
            }else{
                html +=Translator.trans('schedule.form.no_result');
            }
            html += '<br /><button class="ctp-button-primary" type="submit" value="' + Translator.trans('journey.form.new_search') + '"  title="' + Translator.trans('journey.form.new_search') + '">' +
                '   <span>' + Translator.trans('journey.form.new_search') + '</span>' +
                '</button>' +
                '</div>';
        }

        //Add button to show plan only if there is a direction
        if (Kisio.timetable_activated == 1) {
            if (htmlFront.length > 0) {
                html += initShowTimeTableLink('forward');
            } else if (htmlBack.length > 0) {
                html += initShowTimeTableLink('backward');
            }
        }
        html += '</div>';

        //Remove previous results and append html content
        JKisio('#next_departures_results table, #change_direction, #schedule-form-new-search').remove();
        JKisio('#next_departures_results').append(html);

        showPlanEventListener();

        //Add new search button if no more results
        if (JKisio('#schedule-form-new-search button').length > 0) {
            JKisio('#schedule-form-new-search button').on('click', function () {
                window.location.href = Routing.generate('schedule_home', {type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''});
            });
        }

        //Show firsts results if there is realtime,
        //When there is no realtime, the offset is equal to zero
        if (htmlFront.length > 0 && typeof cutoffsetFront !== 'undefined') {
            offset = cutoffsetFront;
        }else if (htmlBack.length > 0 && typeof cutoffsetBack !== 'undefined') {
            offset = cutoffsetBack;
        }

        //Redefine offset if date is changed with datepicker
        if (typeof newOffset !== 'undefined' && newOffset !== 0) {
            offset = newOffset;
        }

        //If there is no more than max_nb_next_departures, show all results
        if (JKisio('#next_departures_table_front').is(':visible')) {
            if (JKisio('#next_departures_table_front tr').length === parseInt(Kisio.max_nb_next_departures)) {
                offset = 0;
            }
            if (route_direction == 'backward') {
                changeDirection = true;
            }
        }
        if (JKisio('#next_departures_table_back').is(':visible')) {
            if (JKisio('#next_departures_table_back tr').length === parseInt(Kisio.max_nb_next_departures)) {
                offset = 0;
            }
            if (route_direction === 'forward') {
                changeDirection = true;
            }
        }

        if (offset < 0 || typeof offset === 'undefined') offset = 0;

        let count;
        //When there is no realtime, check where is the nearest "min" value on the showing table result
        if (JKisio('#next_departures_table_back').is(":visible") && JKisio('#next_departures_table_back tbody tr').length > 0) {
            offset = parseInt(JKisio('#next_departures_table_back tbody tr:contains(" min")').closest("tr").index());
            if (offset < 0) {
                offset = getNewOffset(JKisio('#next_departures_table_back').html());
            }
            count = parseInt(Kisio.max_nb_next_departures) + offset;
            JKisio('#next_departures_table_back, #stop_area_hours #backward').show();
            showSelectedPartofTableDepartures('#next_departures_table_back tr', offset, count);
        } else if (JKisio('#next_departures_table_front').is(":visible") && JKisio('#next_departures_table_front tbody tr').length > 0) {
            JKisio('#next_departures_table_front, #stop_area_hours #forward').show();
            offset = parseInt(JKisio('#next_departures_table_front tbody tr:contains(" min")').closest("tr").index());
            if (offset < 0) {
                offset = getNewOffset(JKisio('#next_departures_table_front').html());
            }
            count = parseInt(Kisio.max_nb_next_departures) + offset;
            showSelectedPartofTableDepartures('#next_departures_table_front tr', offset, count);
        }
        manageNextPrevButtons(offset, count);

        //Click on change direction button, if there is only "terminus" as results
        if (isTerminusFront) {
            changeDirection = true;
            JKisio('#direction').val('backward');
        }
        if (changeDirection) {
            JKisio('#next_departures_results #change_direction').trigger('mouseup');
        }

        JKisio('#direction').val(route_direction);
        JKisio('#loading-box').css("visibility", "hidden");

        //If after the user search one hour after now
        //And the realtime was activated for this location
        //Then Reactivate the realtime
        if (Kisio.realtime_disabled === 1 && Kisio.realtime_saved === 1) {
            Kisio.realtime_activated = 1;
        }

        //Check first and last Passage
        showFirstAndLastPassage();

        delegateEvents(offset, count);

        //If the filter by stop point is enabled, there is no need to show Direction button
        if (Kisio.filter_by_stop_point == '0') {
            JKisio('#next_departures_results #change_direction').remove();
        }

        if (Kisio.vehicle_position_activate) {
            displayVehiclePositionByRouteId(route_direction);
            getCurrentDirectionEventListeners();
        }
    };

    /**
     * Manage opacity and activate/desactivate previous and next buttons
     * @param offset
     * @param count
     */
    const manageNextPrevButtons = function(offset, count = parseInt(Kisio.max_nb_next_departures)) {
        if (offset <= 0) {
            JKisio('.prev').css('opacity', 0.5).attr('disabled', true);
        }else{
            JKisio('.prev').css('opacity', 1).attr('disabled', false);
        }
        if (JKisio('#next_departures_table_front').is(':visible')) {
            if (count >= JKisio('#next_departures_table_front tr').length) {
                JKisio('.next').css('opacity', 0.5).attr('disabled', true);
            }else{
                JKisio('.next').css('opacity', 1).attr('disabled', false);
            }
            showSelectedPartofTableDepartures('#next_departures_table_front tr', offset, count);
        }
        if (JKisio('#next_departures_table_back').is(':visible')) {
            if (count >= JKisio('#next_departures_table_back tr').length) {
                JKisio('.next').css('opacity', 0.5).attr('disabled', true);
            }else{
                JKisio('.next').css('opacity', 1).attr('disabled', false);
            }
            showSelectedPartofTableDepartures('#next_departures_table_back tr', offset, count);
        }
    };

    /**
     * Function to show the proper part of the Departures table and after it's all done, manage the eventual notes
     * @param elt
     * @param offset
     * @param count
     */
    showSelectedPartofTableDepartures = function(elt, offset, count) {
        JKisio(elt).hide().slice(offset, count).show().promise().done(function () {
            manageDisplayOfNotes();
        });
    };

    /**
     * Function to manage the displaying of eventual notes, their indexes and legend
     */
    const manageDisplayOfNotes = function() {
        // We first empty the Container of notes and remove the notes-indexes and sr-onlys in any case
        JKisio('.notesContainer').html('').remove();
        JKisio('.notes-index').remove();
        JKisio('.next_departures_table .sr-only').remove();
        let trs = JKisio('.next_departures_table tr:visible');
        if (trs.length > 0) {
            let baseNotes = [];
            let isODTNoNotes = false;
            // We determine if those schedules are in the Forward or Backward direction and set the local variables accordingly
            let table = trs.parents('.next_departures_table');
            let tableID = JKisio(table).attr('id').replace('next_departures_table_', '');
            if (tableID === 'front') {
                for (const baseNoteForward of baseNotesForward) {
                    baseNotes[baseNoteForward['id']] = baseNoteForward;
                }
                isODTNoNotes = isODTNoNotesForward;
            } else if (tableID === 'back') {
                for (const baseNoteBackward of baseNotesBackward) {
                    baseNotes[baseNoteBackward['id']] = baseNoteBackward;
                }
                isODTNoNotes = isODTNoNotesBackward;
            }
            let odtDefaultMessage = Translator.trans('schedule.result.odt');
            let spans = JKisio(trs).find('td span');
            if (isODTNoNotes) {
                // We treat the case when there is no BaseNotes and there is at least one TAD icon in the displayed schedules
                let displayedODT = JKisio(spans).parent('td').find('.ikisio');
                if (displayedODT.length > 0) {
                    table.after('<div class="notesContainer"><div class="note"><i class="ikisio ikisio-tad"></i><span class="noteText">'+odtDefaultMessage+'</span></div></div>');
                }
            } else {
                let tableNotes = [];
                let tableNotesKey = [];
                for (const element of Object.entries(spans)) {
                    let parentTD = JKisio(element).parent('td');
                    if (JKisio(element).data('isodt') === 1) {
                        let icon = JKisio(parentTD).find('.ikisio');
                        JKisio(icon).after('<span class="sr-only">' + odtDefaultMessage + '</span>');
                    }
                    let spanNotesID = JKisio(element)?.data('notesid')?.split(';');
                    // We build an array of the relevant SpanNotes with all the pertinent information
                    if (spanNotesID) {
                        let spanNotes = [];
                        let automaticMessagePresent = '';
                        let specificMessagePresent = false;
                        for (const element of Object.entries(spanNotesID)) {
                            if (element in baseNotes) {
                                spanNotes[element] = baseNotes[element];
                                if (baseNotes[element]['comment_type'] === 'on_demand_transport') {
                                    automaticMessagePresent = element;
                                }
                                if (baseNotes[element]['comment_type'] === 'information') {
                                    specificMessagePresent = true;
                                }
                            }
                        }
                        // We filter this array to get rid of automatic TAD note when a client specific one is also present
                        if (specificMessagePresent && automaticMessagePresent !== '') {
                            delete spanNotes[automaticMessagePresent];
                        }
                        // We build the tableNotes & the tableNotesKey for the future legend and display the note index
                        for (let id in spanNotes) {
                            if (!(id in tableNotes)) {
                                tableNotes[id] = spanNotes[id];
                                tableNotesKey.push(id);
                            }
                            JKisio(parentTD).append('<span class="notes-index">('+(tableNotesKey.indexOf(id)+1)+')</span>');
                        }
                    }
                }
                // We build the html for the legend to be displayed
                let htmlNotesLegend = '<ol class="notesContainer">';
                for (let i = 0; i < tableNotesKey.length; i++) {
                    let preText = '';
                    if (tableNotes[tableNotesKey[i]]['category'] === 'terminus') {
                        // In case of terminus information we add the adequate prefix to the note
                        preText = Translator.trans('schedule.result.notes.terminus')+' ';
                    }
                    // We render any proper url in the note to be clickable and add the note legend
                    htmlNotesLegend += '<li class="note"><span class="noteText">('+(i+1)+') '+preText+clickableNote(tableNotes[tableNotesKey[i]]['value'])+'</span></li>';
                }
                htmlNotesLegend += '</ol>';
                table.after(htmlNotesLegend);
            }
        }
    };

    /**
     * Function to render clickable any proper url in note
     * @param note
     * @returns {undefined|void|string|*}
     */
    const clickableNote = function(note) {
      let regex = /(https?:\/\/\S+[a-zA-Z]\S+[^,\s.;?!:])/gm;
      let subst = '<a href="$1" target="_blank">$1</a>';
      let result = note.replace(regex, subst);
      return result;
    };

    /**
     * Get new offset when search is equal or after tomorrow
     * @param table
     */
    getNewOffset = function(html) {
        let rowCount = JKisio(html).get(0).rows.length;
        let rows = JKisio(html).get(0).rows;
        let newOffset = 0;
        let ts = 0;
        for (let i = 0; i < rowCount; i++) {
            let span = rows[i].lastChild.firstChild;
            if (span.attributes) {
                ts = parseInt(span.attributes[0].nodeValue);
            }
            if (parseInt(JKisio('#schedule_from_datetime').attr('data-timestamp')) > ts) {
                newOffset = i + 1;
            }
        }
        if (newOffset < parseInt(Kisio.max_nb_next_departures)) {
            newOffset = 0;
        }
        return newOffset;
    }

    /**
     * Manage line passing by a stop area
     */
    const managePassingLines = function() {
        JKisio('.schedule-lines span').tooltip();
        if (JKisio('#next_departures_results .schedule-lines .lines').length > 0) {
            JKisio(document).on('keyup', '#next_departures_results .schedule-lines .lines, #next_departures_results .schedule-lines .selected_line', function (e) {
                if (e.keyCode === 13) {
                    JKisio(this).children('span').trigger('click');
                }
            });
            JKisio(document).on('click', '#next_departures_results .schedule-lines .lines span, #next_departures_results .schedule-lines .selected_line span', function (e) {
                e.preventDefault();
                JKisio('#loading-box').css("visibility", "visible");
                JKisio('.schedule-lines div').removeClass('selected_line');
                JKisio('.schedule-lines div:not(.passing-lines)').addClass('lines');
                JKisio('.bs-tooltip-top.show').remove();
                JKisio(this).parent('div').removeClass('lines').addClass('selected_line');
                JKisio('.schedule-lines').attr('id', JKisio(this).attr('id')).attr('data-line-label', JKisio(this).attr('data-title'));
                JKisio('#prev_next').show();
                setTimeout(function () {
                    updateLinesByType(true);
                    showFirstAndLastPassage();
                }, 300);
            });
        }
        if (JKisio('#next_departures_results .schedule-lines .lines').length > 0){
            JKisio(document).on('click', '#next_departures_results .schedule-lines .passing-lines span', function (e, getAccess) {
                JKisio('.schedule-lines div').removeClass('selected_line');
                JKisio('.schedule-lines div:not(.passing-lines)').addClass('lines');
                JKisio('.bs-tooltip-top.show').remove();
                JKisio(this).parent('div').addClass('selected_line');
                let passing_lines_array = JKisio(this).data('passing-lines');
                setTimeout(function () {
                    showSelectPassingLinesNetwork(passing_lines_array, getAccess);
                }, 300);
            })
        }
    };

    showFirstAndLastPassage = function () {
        if (JKisio('#stop_area_hours #forward').is(":visible") && JKisio('#stop_area_hours #forward').length > 0) {
            JKisio('#stop_area_hours #backward').hide();
        } else if (JKisio('#stop_area_hours #backward').is(":visible") && JKisio('#stop_area_hours #backward').length > 0) {
            JKisio('#stop_area_hours #forward').hide();
        }
    }

    const showSelectPassingLinesNetwork = function(passing_lines_array, getAccess) {
        JKisio('#select-passing-lines-network-container').html('');
        if (getAccess !== true) {
            JKisio('#select-passing-lines-network-container').html('');
            JKisio('#prev_next').hide();
            JKisio('.notesContainer').html('');
            JKisio('#change_direction').remove();
            JKisio('#next_departures_table_back').remove();
            JKisio('#next_departures_table_front').remove();
            if (JKisio('#schedule-form-new-search').length > 0) {
                JKisio('#schedule-form-new-search').remove();
            }
        }
        let options = '';
        for (const element of passing_lines_array) {
            options += '<div class="select-passing-lines-network-option-prototype select-passing-lines-network-option" data-value="' + element[0] + '"><div class="select-passing-lines-network-option-prototype-container"><i class="ikisio ikisio-' + element[2] + '"></i><span>' + element[1]+'</span><i class="ikisio ikisio-arrow-drop-down"></i></div></div>';
        }
        let select = '<div id="select-passing-lines-network"><div class="select-passing-lines-network-option-prototype select-passing-lines-network-option-choice closed"><div class="select-passing-lines-network-option-prototype-container">'+Translator.trans('line_schedule.form.line.placeholder')+'<i class="ikisio ikisio-arrow-drop-down"></i></div></div>'+options+'</div>';
        JKisio('#select-passing-lines-network-container').append(select);
        JKisio('#select-passing-lines-network-container').show();
    };

    showTimeTable = function(stop_point_id, direction_type, raw_first_datetime) {
        if (Kisio.timetable_activated == 1) {
            if (JKisio('.kisio-global-popup-full').length === 0) {
                let popup_full = JKisio('<div/>').addClass('kisio-global-popup-full');
                let global_popup = JKisio('<div/>').addClass('kisio-global-popup show-time-table');
                let popup_header = JKisio('<div/>').addClass('kisio-popup-header');
                let close_icon = JKisio('<i/>').addClass('ikisio ikisio-close').attr('id', 'kisio-popup-close');
                popup_header.append(Translator.trans('canaltp_pnpcore.loading'));
                popup_header.append(close_icon);
                let popup_body = JKisio('<div/>').addClass('kisio-popup-body');
                let popup_footer = JKisio('<div/>').addClass('kisio-popup-footer');
                popup_body.append('<img class="loading-img" src="/bundles/canaltppnpcore/images/anim-chargement-PNP.gif">');
                global_popup.append(popup_header)
                    .append(popup_body)
                    .append(popup_footer);
                popup_full.append(global_popup);
                popup_full.appendTo('body');
            }

            let id_line = JKisio('.selected_line span').attr('id');
            if (Kisio.filter_by_stop_point == '0') {
                id_line = JKisio('#stop_area_label').attr('data-line-id');
            }
            if (typeof id_line !== 'undefined') {
                JKisio.ajax({
                    url: Routing.generate('schedule_timetable',
                        {
                            type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                            id_line: id_line,
                            direction_type: direction_type,
                            from_datetime: raw_first_datetime,
                            stop_point_id: stop_point_id
                        }),
                    success: function (result) {
                        if (result != '') {
                            let popup_header = JKisio('.kisio-popup-header');
                            let popup_footer = JKisio('.kisio-popup-footer');
                            let close_icon = JKisio('<i/>').addClass('ikisio ikisio-close').attr('id', 'kisio-popup-close');
                            const stop_area_label = JKisio('#stop_area_label').text();

                            /*
                             * Title construction
                             */
                            //Get selected line
                            let title = '<div id="direction">';
                            if (JKisio('.selected_line').prevAll('.icon-mode').length > 0) {
                                let obj = JKisio('.selected_line').prevAll('.icon-mode');
                                title += obj[Object.keys(obj)[0]].outerHTML;
                            }
                            if (typeof JKisio('.selected_line').html() !== "undefined") {
                                title += JKisio('.selected_line').html();
                            }
                            //Get stop area
                            title += '<span id="direction_title">' + stop_area_label + '</span></div>';
                            //Direction
                            let direction_title = '';
                            if (JKisio('#next_departures_table_front').is(":visible") && JKisio('#next_departures_table_front tbody tr').length > 0) {
                                direction_title = JKisio('#next_departures_table_front tr td:first').html();
                                title += '<div class="title">' + direction_title + '</div>';
                            } else if (JKisio('#next_departures_table_back').is(":visible") && JKisio('#next_departures_table_back tbody tr').length > 0) {
                                direction_title = JKisio('#next_departures_table_back tr td:first').html();
                                title += '<div class="title">' + direction_title + '</div>';
                            }
                            popup_header.html(title);
                            popup_header.append(close_icon);
                            JKisio('.kisio-popup-body').html(result);

                            // add download button
                            popup_footer.append(downloadTimeTableButton());
                            downloadEventListeners(id_line, direction_type, raw_first_datetime, stop_point_id, stop_area_label, direction_title);

                            // srollBar
                            let popupHeaderHeight = JKisio('.kisio-popup-header').outerHeight(true);
                            let popupFooterHeight = JKisio('.kisio-popup-footer').outerHeight(true);
                            let popupGlobalHeight = JKisio('.kisio-global-popup').outerHeight(true);
                            let popupBodyHeight = JKisio('.kisio-popup-body').outerHeight(true);

                            if (popupBodyHeight > (popupGlobalHeight - popupHeaderHeight)) {
                                JKisio('.kisio-popup-body').height(popupGlobalHeight - popupHeaderHeight - popupFooterHeight);
                                if (!isIE9) {
                                    try {
                                        new SimpleBar(JKisio('.kisio-popup-body')[0]);
                                    } catch (e) {
                                        console.log(e);
                                    }
                                }
                            }

                            // initialize timeTable controls
                            initTimeTable();

                            // close popup
                            hideOnClickOutside();
                        }
                        JKisio('#kisio-popup-close').click(function (e) {
                            JKisio('.kisio-global-popup-full').remove();
                        });
                    },
                    error: function (result) {
                        let close_icon = JKisio('<i/>').addClass('ikisio ikisio-close').attr('id', 'kisio-popup-close');
                        popup_header.html('').append(close_icon);
                        JKisio('.kisio-popup-body').html(Translator.trans('car_pooling.error_occurred'));
                        JKisio('#kisio-popup-close').click(function (e) {
                            JKisio('.kisio-global-popup-full').remove();
                        });
                    }
                });
            }
        }
    };

    const hideOnClickOutside = function () {
        JKisio(document).click(function (e) {
            const target = e.target;
            const targetClass = JKisio(target).attr('class');
            if (targetClass && targetClass === 'kisio-global-popup-full' ) JKisio(target).remove();
        })
    };


    /**
     * Function to delegate events
     */
    delegateEvents = function (offset, count) {
        JKisio('#back-to-lineScheduleNew').on('click', function (e) {
            clearIntervalVehiclePosition();
            // update url when go back
            removeQueryParamsFromUrl('direction_type');

            let stop_area_label = JKisio('#stop_area_label').text();
            let stop_area_id = JKisio('.lines span').attr('data-stop-area');
            let model = new modelDisplaySearchScheduleResults();
            if (typeof stop_area_id !== 'undefined' && typeof stop_area_label !== 'undefined') {
                model.displayLineInfos('r_public_transport_objects_filtered', stop_area_id, stop_area_label);
            }

            //Remove stop points drawed only when lines are shown
            if (JKisio('ul#ctp-searchScheduleResults > li').length <= 0) {
                if (!window.leaflet) {
                    return;
                }
                for (let i in window.leaflet.map._layers) {
                    try {
                        if (typeof i === 'undefined' || window.leaflet.map._layers[i]._radius === 5) {
                            map.removeLayer(window.leaflet.map._layers[i]);
                        }
                    } catch (e) {
                        console.log("problem with " + e + window.leaflet.map._layers[i]);
                    }
                }
            }
            if (JKisio('.schedule-vehicle-position-icon').length > 0) {
                JKisio('.schedule-vehicle-position-icon').remove();
            }
            JKisio('#next_departures_results').remove();
            JKisio('#ctp-searchSchedule').show();
            if (JKisio('#ctp-lineSccheduleLineStopAreasSearchInput').is(':visible') && JKisio('#ctp-lineSccheduleLineStopAreasSearchInput').length > 0) {
                JKisio(".leaflet-marker-icon").remove();
            }
            JKisio('#board_container').animate({'top': '2%'}, "fast", "swing");
        });

        if (JKisio('#schedule-datepicker').length > 0) {
            JKisio("#schedule-datepicker .input-group.date").bootstrapDP({
                startDate: "now",
                language: Kisio.locale,
                autoclose: true
            });
            JKisio('#schedule-datepicker .input-group-addon.icon-picker-input').on('click', function () {
                JKisio('#schedule-datepicker #date-input-picker').click().focus();
            })
        }
        if (typeof offset !== 'undefined') {
            manageStopArea(offset, count);
        }

        reloadNextDepartures();
    };

    /**
     * Function to set dynamically url without refreshing page
     * @param uri
     * @param label
     * @param scheduleSelected
     * @param directionType
     */
     setUrlDynamically = function(uri, label, scheduleSelected, directionType) {
         let uri_object = {
             'schedule': {
                 'stop_area_lines': {
                     'autocomplete': label,
                     'autocomplete-hidden': uri
                 }
             }
         };

        if (scheduleSelected !== undefined && scheduleSelected !== '') {
            uri_object.schedule_selected = scheduleSelected;
        }

        if (directionType !== undefined && directionType !== '') {
            uri_object.direction_type = directionType;
        } else {
            uri_object.direction_type = getStopScheduleDirectionFromLocation();
        }

        let query = JKisio.param(uri_object);
        let newState = (history.state || 0) + 1;
        let route = Routing.generate(
            'schedule_home',
            { type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : '' }) + '?' + query;
        history.pushState(newState, 'Pushed: ' + newState, route);
    };
}());

// Manage click on Selector for letting appear list of Passing Lines
JKisio(document).on('click', '.select-passing-lines-network-option-choice', function () {
    if (!(JKisio(this).hasClass('disabled'))) {
        if (JKisio(this).hasClass('closed')) {
            JKisio('.select-passing-lines-network-option').show();
            JKisio(this).removeClass('closed');
            JKisio(this).find('.ikisio').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
        } else {
            JKisio('.select-passing-lines-network-option').hide();
            JKisio(this).addClass('closed');
            JKisio(this).find('.ikisio').removeClass('ikisio-arrow-drop-up').addClass('ikisio-arrow-drop-down');
        }
    }
});

// Manage click on specific passing line in Selector list and show Next Departures
JKisio(document).on('click', '.select-passing-lines-network-option', function (e, getAccess) {
    if (getAccess !== true) {
        JKisio('#loading-box').css("visibility", "visible");
    }
    JKisio('.select-passing-lines-network-option-choice').addClass('disabled').hide();
    JKisio('.select-passing-lines-network-option-selected').find('.ikisio-arrow-drop-up').removeClass('ikisio-arrow-drop-up').addClass('ikisio-arrow-drop-down').hide();
    JKisio('.select-passing-lines-network-option-selected').removeClass('select-passing-lines-network-option-selected').addClass('select-passing-lines-network-option');
    JKisio('.select-passing-lines-network-option').hide();
    JKisio(this).removeClass('select-passing-lines-network-option');
    JKisio(this).addClass('select-passing-lines-network-option-selected');
    JKisio(this).show();
    JKisio(this).addClass('closed');
    JKisio(this).find('.ikisio-arrow-drop-down').show();
    if (getAccess !== true) {
        let id = JKisio(this).data('value');
        JKisio('.schedule-lines').attr('id', id).attr('data-line-label', JKisio(this).text());
        setTimeout(function () {
            JKisio('#prev_next').show();
            updateLinesByType();
        }, 300);
    }
});

// Manage click on specific passing line previously selected
JKisio(document).on('click', '.select-passing-lines-network-option-selected.closed', function () {
    let direction = JKisio('.next_departures_table:visible').attr('id');
    JKisio(this).data('direction-displayed', direction);
    JKisio(this).removeClass('closed');
    JKisio(this).find('.ikisio-arrow-drop-down').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
    JKisio('#prev_next').hide();
    JKisio('#next_departures_table_back').hide();
    JKisio('#next_departures_table_front').hide();
    if (JKisio('#schedule-form-new-search').length > 0) {
        JKisio('#schedule-form-new-search').hide();
    }
    JKisio('.select-passing-lines-network-option-choice').show();
    JKisio('.select-passing-lines-network-option').show();
    JKisio('.select-passing-lines-network-option-choice').find('.ikisio').hide();
});

// Manage click on Passing lines option (not closed)
JKisio(document).on('click', '.select-passing-lines-network-option-selected:not(.closed)', function () {
    let direction = JKisio(this).data('direction-displayed');
    JKisio(this).addClass('closed');
    JKisio(this).find('.ikisio-arrow-drop-up').removeClass('ikisio-arrow-drop-up').addClass('ikisio-arrow-drop-down');
    JKisio('#prev_next').show();
    JKisio('#'+direction).show();
    if (JKisio('#schedule-form-new-search').length > 0) {
        JKisio('#schedule-form-new-search').show();
    }
    JKisio(this).data('direction-displayed', '');
    JKisio('.select-passing-lines-network-option-choice').find('.ikisio').show();
    JKisio('.select-passing-lines-network-option-choice').hide();
    JKisio('.select-passing-lines-network-option').hide();
});

// Manage click/enter on Change direction button
JKisio(document).on('keyup', '#next_departures_results #change_direction', function (e) {
    if (e.keyCode === 13) {
        JKisio(this).trigger('mouseup', [true]);
    }
});
JKisio(document).on('mouseup', '#next_departures_results #change_direction', function (e, eventKeyboard) {
    e.stopPropagation();
    let queryString = decodeURIComponent(window.location.search);
    let queryAutocomplete = '';
    let queryAutocompleteHidden = '';
    let queryScheduleSelected = '';
    let offset = '';
    let count = '';
    if (queryString !== '') {
        let regex = /schedule\[stop_area_lines\]\[autocomplete\]=(.+)&schedule\[stop_area_lines\]\[autocomplete-hidden\]=(.+)&schedule_selected=(.+)&direction_type=(forward|backward)/;
        let m;
        if ((m = regex.exec(queryString)) !== null) {
            queryAutocomplete = m[1]
            queryAutocompleteHidden = m[2]
            queryScheduleSelected = m[3];
        }
    }
    if (JKisio('#next_departures_table_back').is(":visible") && JKisio('#next_departures_table_back tbody tr').length > 0) {
        JKisio('#next_departures_table_back, #stop_area_hours #backward').hide();
        JKisio('#next_departures_table_front, #stop_area_hours #forward').show();
        offset = parseInt(JKisio('#next_departures_table_front tbody tr:contains(" min")').closest("tr").index());
        if (offset < 0) {
            offset = getNewOffset(JKisio('#next_departures_table_front').html());
        }
        count = parseInt(Kisio.max_nb_next_departures) + offset;
        showSelectedPartofTableDepartures('#next_departures_table_front tr', offset, count);
        JKisio('#direction').val('forward');
        getNewOffset('#next_departures_table_front');
        if (eventKeyboard) {
            JKisio('#next_departures_table_front').focus();
        }
        JKisio('#link-show-plan').attr('data-direction', 'forward');
        setUrlDynamically(queryAutocompleteHidden, queryAutocomplete, queryScheduleSelected, 'forward');
    } else if (JKisio('#next_departures_table_front').is(":visible") && JKisio('#next_departures_table_front tbody tr').length > 0) {
        JKisio('#next_departures_table_front, #stop_area_hours #forward').hide();
        JKisio('#next_departures_table_back, #stop_area_hours #backward').show();
        offset = parseInt(JKisio('#next_departures_table_back tbody tr:contains(" min")').closest("tr").index());
        if (offset < 0) {
            offset = getNewOffset(JKisio('#next_departures_table_back').html());
        }
        count = parseInt(Kisio.max_nb_next_departures) + offset;
        JKisio('#direction').val('backward');
        showSelectedPartofTableDepartures('#next_departures_table_back tr', offset, count);
        if (eventKeyboard) {
            JKisio('#next_departures_table_back').focus();
        }
        JKisio('#link-show-plan').attr('data-direction', 'backward');
        setUrlDynamically(queryAutocompleteHidden, queryAutocomplete, queryScheduleSelected, 'backward')
    }
});

function updateStopAreaLabel(newLabel) {
    if (Kisio.filter_by_stop_point == '0' && newLabel) {
        JKisio('#stop_area_label').text(newLabel);
    }
}

/**
 * Array unique in js
 *
 * @param value
 * @param index
 * @param self
 * @returns {boolean}
 */
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

//Manage click on Timetable button
JKisio(document).on('click', '#link-show-plan', function () {
    let date = new Date();
    let month = date.getMonth();
    month = month + 1;
    if (month < 10) {
        month = '0'+month;
    }
    let day = date.getDate();
    if (day < 10) {
        day = '0'+day;
    }
    let raw_first_datetime = date.getFullYear()+month+day+'T040000';
    if (typeof JKisio('#forward').attr('data_first_datetime') !== 'undefined' && JKisio('#forward').attr('data_first_datetime') != '') {
        raw_first_datetime = JKisio('#forward').attr('data_first_datetime');
    }
    let object_id = JKisio('#stop_area_label').attr('data-stop-area');
    showTimeTable(object_id, JKisio('#link-show-plan').attr('data-direction'), raw_first_datetime);
});
