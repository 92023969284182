//Manage click on Timetable button
import {showTimeTable} from '../../display_search_schedule_result';
import {time_table_consts} from "./core/constants";

export function showPlanEventListener () {
    JKisio(time_table_consts.show_plan_link).on('click', function (e) {
        e.stopImmediatePropagation();
        let raw_first_datetime = '';

        //By default, direction_type is forward
        if (JKisio('#forward').is(':visible') && JKisio('#forward').attr('data_first_datetime') != '') {
            raw_first_datetime = JKisio('#forward').attr('data_first_datetime');
        }else if(JKisio('#backward').is(':visible') && JKisio('#backward').attr('data_first_datetime') != '') {
            raw_first_datetime = JKisio('#backward').attr('data_first_datetime');
        }

        let object_id = JKisio('.selected_line span').attr('data-stop-area') ?? JKisio('#stop_area_label').attr('data-stop-area');
        showTimeTable(object_id, JKisio('#link-show-plan').attr('data-direction'), raw_first_datetime);
    });
}
