Kisio.timepicker = function (element) {
    let twintyfour = true, $inputbox = JKisio('.' + element), wrapClass = '.' + element + '_wrap';

    let setTimearea = function (meridian) {
        let $div = JKisio('<div/>'), $input = JKisio('<input type="text"/>');
        let lists = ['hour', 'min'];
        $div.clone().addClass(element + '_wrap').insertAfter($inputbox);
        let titleButton = Translator.trans('timepicker.validate');

        for (const el of lists) {
            $div.clone().addClass(el).appendTo(wrapClass);
            $div.clone().addClass('btn ' + element + '-next ikisio ikisio-arrow-drop-up').appendTo(wrapClass + ' .' + el);
            $div.clone().addClass(element + '-ti_tx').append($input.clone().addClass(element + '-in_txt').attr('aria-label', Translator.trans('journey.form.timepicker.' + el))).appendTo(wrapClass + ' .' + el);
            $div.clone().addClass('btn ' + element + '-prev ikisio ikisio-arrow-drop-down').appendTo(wrapClass + ' .' + el);
        }
        if (meridian) {
            twintyfour = false;
            $div.clone().addClass('meridian').appendTo(wrapClass);
            $div.clone().addClass('btn next').appendTo('.meridian ikisio ikisio-arrow-drop-up');
            $div.clone().addClass('ti_tx').append($input.clone().addClass('in_txt ikisio')).appendTo('.meridian');
            $div.clone().addClass('btn prev').appendTo('.meridian ikisio ikisio-arrow-drop-down');
        }
        JKisio(wrapClass).append(
            '<button type="button" class="secondary-button ' + element + '-select" tabindex="0">' +
            '<i class="ikisio ikisio-validate"/>' + titleButton + '</button>'
        );
    };
    let checkTime = function (tnum, place) {
        let $area = JKisio(place.parentElement.parentElement).find('.' + element + '-in_txt'), m, h;
        switch (place.parentElement.className) {
            case 'hour':
                if (place.classList[1] === element + '-prev') {
                    h = resuceNum(tnum);
                    $area.eq(0).val(addZero(h, true));
                } else if (place.classList[1] === element + '-next') {
                    h = addNum(tnum);
                    $area.eq(0).val(addZero(h, true));
                }
                break;
            case 'min':
                if (place.classList[1] === element + '-prev') {
                    m = resuceNum(tnum);
                    $area.eq(1).val(addZero(m));
                } else if (place.classList[1] === element + '-next') {
                    m = addNum(tnum);
                    $area.eq(1).val(addZero(m));
                }
                break;
            case 'meridian':
                if ($area.eq(3).val() === 'AM') $area.eq(3).val('PM');
                else $area.eq(3).val('AM');
                break;
            default:
                alert('get fail');
        }
    };

    function InDelay(val, hours) {
        if (hours) {
            if (val > 23) val = null;
        } else if(val > 59) {
            val = null;
        }
        return val;
    }
    function addZero(i, hours) {
        if(hours){
            if (i > 23) i = '00';
            else if (i<0) i=23;
            !twintyfour ? i>12 ? i-=12 : '':'';
        }
        else{
            if (i > 59) i = '00';
            else if(i < 0) i=59;
        }
        if (i < 10 && !i.toString().match(/[0][0-9]/g)) {
            i = "0" + i;
        }
        return i;
    }
    function setInit(inputbox){
        let i;
        if (inputbox[0]){
            let $area = JKisio(inputbox[0].nextElementSibling).find('.' + element + '-in_txt');
            let date = new Date(), tz = 'AM';
            let list = [addZero(date.getHours(), true), addZero(date.getMinutes()), tz];
            if(inputbox.val().length===0){
                for(i = 0; i<$area.length; i++)	JKisio($area[i]).val(list[i]);
                setValue(inputbox, $area);
            }else {
                let formateTime = inputbox.val().split(':');
                for(i = 0; i<$area.length; i++)   JKisio($area[i]).val(formateTime[i]);
            }
        }
    }
    function isSetTimeArea(dom){
        let open = false;
        if(JKisio('body').find(wrapClass)[1] !==undefined)
            open=JKisio.contains(JKisio('body').find(wrapClass)[0],dom)|| JKisio.contains(JKisio('body').find(wrapClass)[1],dom);
        else if (typeof JKisio('body').find(wrapClass) !== "undefined" && '0' in JKisio('body').find(wrapClass))
            open=JKisio.contains(JKisio('body').find(wrapClass)[0],dom);
        return open;
    }
    function setValue(inputbox, area){
        let year, day, month, myDate;
        let hour = area.eq(0).val(), min = area.eq(1).val();
        if (hour.length <= 2 && min.length <= 2 && InDelay(hour, true) && InDelay(min)) {
            hour = addZero(hour, true);
            min = addZero(min);
            area.eq(3).val() === undefined ?
                inputbox.val(hour + ':' + min).trigger("change") :
                inputbox.val(hour + ':' + min + ':' + area.eq(2).val()).trigger("change");
            // make the value of input visible
            JKisio('.' + element + '.input-picker').attr('value', hour + ':' + min);

            let date = JKisio('#schedule_from_datetime').attr('data-date');
            if (typeof date === 'undefined') {
                myDate = new Date;
                month = myDate.getUTCMonth() + 1;
                day = myDate.getUTCDate();
                year = myDate.getUTCFullYear();
            }else{
                let values = date.split('/');
                myDate = new Date(values[0], values[1], values[2]);
                day = values[2];
                month = values[1];
                year = values[0];
            }
            let diff = myDate.getTimezoneOffset();
            if (diff == -120) {
                hour = parseInt(hour) + 1;
                hour = addZero(hour, true);
            }
            date = year + "/" + month + "/" + day+" "+hour+ ':' + min;
            let timestamp = Math.floor(new Date(date).getTime() / 1000);
            JKisio('#schedule_from_datetime').attr('data-timestamp', timestamp);
        }
    }
    function addNum(i){
        return ++i;
    }
    function resuceNum(i){
        return --i;
    }
    function closeIt() {
        let $tab = JKisio(wrapClass);
        $tab.hide();
        let $spanTimepicker = JKisio('span.icon-picker-input.icon-' + element);
        let $inputTimePicker = JKisio('input' + '.' + element);
        // hide timepicker
        $inputTimePicker.show();
        $spanTimepicker.show();
    }

    function onClickAndFocus(e, $tabTimePicker, $inputTimePicker) {
        let _this=e.target;
        setTimeout(function(){
            const focused_element = JKisio(document.activeElement);
            if (JKisio(document.activeElement).is(':input' + '.' + element) ||
                JKisio(e.target).hasClass('icon-' + element) ||
                JKisio(e.target).parent().hasClass('icon-' + element)){
                    $tabTimePicker.css('display', 'flex');
                    if ($tabTimePicker.is(":visible")){
                        let $spanTimepicker = JKisio('span.icon-picker-input.icon-' + element);
                        $spanTimepicker.hide();
                        $inputTimePicker.hide();
                        $tabTimePicker.css('display', 'flex');
                        $inputTimePicker.select();
                        setInit($inputTimePicker);
                    }
            } else if (!focused_element.is(':input' + '.' + element) && !isSetTimeArea(_this) ||
                JKisio(e.target).hasClass(element + '-select') ||
                JKisio(e.target).parent().hasClass(element + '-select')){
                let l=focused_element.find('.' + element + '-in_txt:visible').length;
                for(let i= 0; i<l; i++){
                    if(focused_element.find('.' + element + '-in_txt:visible')[i].value!== 'AM' && focused_element.find('.' + element + '-in_txt:visible')[i].value!=='PM'){
                        if(focused_element.find('.' + element + '-in_txt:visible')[i].value!==undefined){
                            JKisio(focused_element.find('.' + element + '-in_txt:visible')[i]).val((addZero(parseInt(focused_element.find('.' + element + '-in_txt:visible')[i].value))));
                        }
                    }
                }
                JKisio(wrapClass + ':visible')[0] !== undefined ?
                    setValue(JKisio(JKisio(wrapClass + ':visible')[0].parentElement).find('.' + element), JKisio(JKisio('.' + element + '-in_txt:visible'))) : '';
                closeIt();
            }
        },  0);
    }

    window.onLoad= setTimearea(false); //show merdian or not; Empty to hide merdian select

    if (JKisio('#' + element).length){
        !function (){
            'use strict';
            JKisio('input[type=submit]');
            let $inputTimePicker = JKisio('input' + '.' + element);
            let $tabTimePicker = $inputTimePicker.next();
            setInit($inputTimePicker);
            JKisio(document).on('click', function(e){
                onClickAndFocus(e, $tabTimePicker, $inputTimePicker);
            });
            JKisio('#timepicker input, #schedule-timepicker input').on('keyup', function (e) {
                if (e.keyCode === 9) {
                    onClickAndFocus(e, $tabTimePicker, $inputTimePicker);
                }
            });
            JKisio(wrapClass + ' .' + element + '-prev').on('click', function(e){
                let $area = JKisio(this.parentElement.parentElement).find('.' + element + '-in_txt');
                checkTime(JKisio(e.target.previousElementSibling.children).val(), e.target);
                setValue(JKisio(this.parentNode.parentElement.previousElementSibling), $area);
            });
            JKisio(wrapClass + ' .' + element + '-next').on('click', function(e){
                let $area = JKisio(this.parentElement.parentElement).find('.' + element + '-in_txt');
                checkTime(JKisio(e.target.nextElementSibling.children).val(), e.target);
                setValue(JKisio(this.parentNode.parentElement.previousElementSibling), $area);
            });
        }(window, document);
    }
}