import { parameters} from 'pnp_core_bundle/plugnplay/leaf-core';
import { createProximityButton } from './manageProximity'
import { getHTMLForBSSFavorite, getHTMLForDistanceObject } from './htmlUtils'
import { determineFavManager, checkFavoritesOnPage } from 'pnp_core_bundle/plugnplay/favorites';

/**
 * Draw on Map the POIs
 * @param pois
 */
export function drawPOIs(pois) {
    
    let urlStr;
    let iconPOIUrl;
    let title;
    let object_coord;
    let markerIcon;
    let iconPOI;
    const icon_default_config = {
        iconSize: 16,
        iconAnchor: [4, 30],
        shadowSize: 37,
        shadowAnchor: [15, 35],
        popupAnchor: [4, -25],
        className: 'proximity-poi-icon'
    };

    let iconURL = parameters.urlImages + 'pin-proximity.svg';
    Kisio.poiGroupLayer.clearLayers();
    let regex = /(asset->)/gm;
    let subst = "/";
    for (const poi of pois) {
        if (poi['poi'] !== undefined && poi['poi']['coord'] !== undefined && Object.keys(poi['poi']['coord']).length > 1) {
            object_coord = poi['poi']['coord'];
            title = Translator.trans('proximity.map.pointType.poi');
            iconPOIUrl = parameters.urlImages + 'poi.png';
            if (poi.poi_type in Kisio.proximityMarkerPoiType) {
                title = Translator.trans(Kisio.proximityMarkerPoiType[poi.poi_type]['trad_key']);
                urlStr = Kisio.proximityMarkerPoiType[poi.poi_type]['url_img'];
                iconPOIUrl = urlStr.replace(regex, subst);
            }
            title = title === '' ? poi.name : title + ' - ' + poi.name;

            iconPOI = L.icon({
                iconUrl: iconPOIUrl,
                shadowUrl: iconURL,
                ...icon_default_config
            });
            markerIcon = L.marker([object_coord.lat, object_coord.lon], {
                icon: iconPOI,
                title: title,
                keyboard: false
            });
            JKisio(markerIcon).data('poi-object', poi).data('icon-poi-url', iconPOIUrl);
            Kisio.poiGroupLayer.addLayer(markerIcon);
            markerIcon.on('click', function () {
                createPOIPopUp(this);
            });
        }
        if (poi['coord'] !== undefined && Object.keys(poi['coord']).length > 1) {
            object_coord = poi['coord'];
            title = Translator.trans('proximity.map.pointType.poi');
            iconPOIUrl = parameters.urlImages + 'poi.png';
            if (poi.poi_type in Kisio.proximityMarkerPoiType) {
                title = Translator.trans(Kisio.proximityMarkerPoiType[poi.poi_type]['trad_key']);
                urlStr = Kisio.proximityMarkerPoiType[poi.poi_type]['url_img'];
                iconPOIUrl = urlStr.replace(regex, subst);
            }
            if (typeof poi.name !== 'undefined') {
                title = title + ' - ' + poi.name;
            }
            if (typeof poi.provider_name !== 'undefined') {
                title = title + ' - ' + poi.provider_name;
            }
            iconPOI = L.icon({
                iconUrl: iconPOIUrl,
                shadowUrl: iconURL,
                ...icon_default_config
            });
            markerIcon = L.marker([object_coord.lat, object_coord.lon], {
                icon: iconPOI,
                title: title,
                keyboard: false
            });
            JKisio(markerIcon).data('poi-object', poi).data('icon-poi-url', iconPOIUrl);
            Kisio.poiGroupLayer.addLayer(markerIcon);
            markerIcon.on('click', function () {
                createPOIPopUpFreeFloatings(this);
            });
        }

    }
        Kisio.poiGroupLayer.addTo(window.leaflet.map);
};


/**
 * Create the HTML for the POI popUp
 * @param poi
 * @param iconPOIUrl
 * @returns {string}
 */
 export function createPOIPopUp(markerIcon) {
    let infoBattery;
    markerIcon.unbindPopup();
    let poi = JKisio(markerIcon).data('poi-object');
    let iconPOIUrl = JKisio(markerIcon).data('icon-poi-url');
    let autocompleteHidden = poi.poi.coord.lon + ';' + poi.poi.coord.lat;
    let latlng = encodeURIComponent(JSON.stringify(poi.poi.coord));
    let type_product = (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : 'standalone';
    let journeyButtons = '';
    let favoriteBSS = getHTMLForBSSFavorite(poi);
    let infoDistance = getHTMLForDistanceObject(poi);
    if (type_product === 'full') {
        journeyButtons = '<div class="poi-proximity-buttons-container">' +
                            '<button id="popup-departure-btn" class="ikisio ikisio-departure" data-type="from" data-label="' + poi.name + '" data-latlng="' + latlng + '"  data-autocomplete-hidden="'+ autocompleteHidden +'">' +
                                '<span>' + Translator.trans('popup.links.from.title') + '</span>' +
                            '</button>'+
                            '<button id="popup-arrival-btn" class="ikisio ikisio-arrival" data-type="to" data-label="' + poi.name + '" data-latlng="' + latlng + '"  data-autocomplete-hidden="'+ autocompleteHidden +'"> ' +
                                '<span>' + Translator.trans('popup.links.to.title') + '</span>' +
                            '</button>' +
                        '</div>';
    }
    let name = poi.name;
    let address = '';
    if (poi.poi !== undefined && poi.poi.address !== undefined && poi.poi.address.label !== undefined) {
        address = poi.poi.address.label;
    }
    let additionnal_content_bss = '';
    if (poi.poi_type === "amenity:bicycle_rental" && poi.poi !== undefined && poi.poi.stands !== undefined && poi.poi.stands !== null && (poi.poi.stands.available_bikes !== undefined || poi.poi.stands.available_places === undefined)) {
        if (poi.poi.stands.available_bikes !== undefined) {
            additionnal_content_bss = '<br /><span>' + Translator.trans('proximity.data.bss.available_bikes') + '<img src="'+Kisio.realtime_data_picto+'" /> ' + poi.poi.stands.available_bikes  +'</span>';
        }
        if (poi.poi.stands.available_places !== undefined) {
            additionnal_content_bss += '<br /><span>' + Translator.trans('proximity.data.bss.available_places') + '<img src="'+Kisio.realtime_data_picto+'" /> ' + poi.poi.stands.available_places  +'</span>';
        }
    }
    let additionnal_content_PPlusR = '';
    if (poi.poi_type === "amenity:parking" && poi.poi !== undefined && poi.poi.car_park !== undefined && poi.poi.car_park !== null && (poi.poi.car_park.available !== undefined || poi.poi.car_park.total_places === undefined)) {
        if (poi.poi.car_park.available !== undefined) {
            additionnal_content_PPlusR = '<br /><span>' + Translator.trans('proximity.data.car_park.available') + '<img src="'+Kisio.realtime_data_picto+'" /> ' + poi.poi.car_park.available  +'</span>';
        }
        if (poi.poi.car_park.total_places !== undefined) {
            additionnal_content_PPlusR += '<br /><span>' + Translator.trans('proximity.data.car_park.total_places') + '<img src="'+Kisio.realtime_data_picto+'" /> ' + poi.poi.car_park.total_places  +'</span>';
        }
    }

    //This test is to see if there is a special trad key for the client
    if (
        typeof Kisio.proximityMarkerPoiType[poi.poi_type].trad_key !== 'undefined'
        && Kisio.proximityMarkerPoiType[poi.poi_type].trad_key !== ''
        && Kisio.proximityMarkerPoiType[poi.poi_type].trad_key.indexOf('proximity.') < 0
    ) {
        name = Kisio.proximityMarkerPoiType[poi.poi_type].trad_key+ ' : '+name;
    }
    let queryShowStops = '';
    if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
        queryShowStops = '&proximity_search[show_stops]=true';
    } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
        queryShowStops = '&proximity_search[show_stops]=false';
    }
    let proximityButton = createProximityButton(poi.poi.coord, name, autocompleteHidden, queryShowStops);
    if(poi.provider_name != "Get Around" && typeof poi.battery !== 'undefined'){
        let picto = "";

        switch(true){
            case poi.battery == 0 :
                picto = 'picto/battery-0.svg';
                break;
            case poi.battery <= 25 :
                picto = 'picto/battery-25.svg';
                break;
            case poi.battery <= 50 :
                picto = 'picto/battery-50.svg';
                break;
            case poi.battery <= 75 :
                picto = 'picto/battery-75.svg';
                break;
            case poi.battery <= 100 :
                picto = 'picto/battery-100.svg';
                break;
        }

        infoBattery = '<div class="proximity-poi-distance proximity-poi-freefloating"><span><img src="'+(parameters.urlImages + picto)+'" /> ' + poi.battery + ' %</span></div>';
    }else {
        infoBattery = '';
    }
    // TO DO infoBattery when free-floating will be included
    let popupContent = '<div id="leaflet-popup" class="proximity-poi-popup">' +
        '<div id="leaflet-popup-header">' +
        '<img src="' + iconPOIUrl + '" width="24" height="24" />' +
        '<span>' + name + '</span>' +
        '</div>' +
        '<div id="leaflet-popup-content" class="clearfix">' +
        '<div class="proximity-poi-info-container">' +
        '<div class="proximity-poi-info-left-side">' +
        '<div class="proximity-poi-address">' + address + additionnal_content_bss + additionnal_content_PPlusR + '</div>' +
        '</div>' +
        '<div class="proximity-poi-info-right-side">' +
        favoriteBSS +
        infoDistance +
        '</div>' +
        '</div>' + journeyButtons + proximityButton +
        '</div>' +
        '</div>';
    markerIcon.bindPopup(popupContent).openPopup();
    setTimeout(function () {
        let favoritesManager = determineFavManager();
        if (Kisio.enable_favorites && favoritesManager !== undefined) {
            checkFavoritesOnPage(favoritesManager, '.proximity-poi-popup ');
        }
    }, 500);
};


/**
 * Create the HTML for the POI popUp FreeFloatings
 * @param poi
 * @param iconPOIUrl
 * @returns {string}
 */
 export function createPOIPopUpFreeFloatings(markerIcon) {
    markerIcon.unbindPopup();
    let poi = JKisio(markerIcon).data('poi-object');
    let iconPOIUrl = JKisio(markerIcon).data('icon-poi-url');
    let autocompleteHidden = poi.id;
    let latlng = encodeURIComponent(JSON.stringify(poi.coord));
    let type_product = (window.Kisio.type_product !== 'undefined') ? window.Kisio.type_product : 'standalone';
    let journeyButtons = '';
    let favoriteBSS = getHTMLForBSSFavorite(poi);
    let infoDistance = getHTMLForDistanceObject(poi);
    let coordonnees = Math.round(poi.coord.lon * 1000000) / 1000000 + ';' + Math.round(poi.coord.lat * 1000000) / 1000000;
    let address = '';
    if (poi.poi_type === "SCOOTER" || poi.poi_type === "MOTORSCOOTER" || poi.poi_type === "CAR" ) {
        if(poi.provider_name == "Get Around")
            address = '<img src="' + iconPOIUrl + '" width="24" height="24" /> ' + poi.provider_name  +'</span>';
        else
            address = poi.provider_name + ' ' + poi.public_id  +'</span>';
    }


    JKisio.ajax({
        url: Routing.generate('coords', {coords: coordonnees, type_product: type_product}),
        dataType: "json",
        async: false,
        success: function (result) {
            let header_adress;
            let infoBattery;
            if (typeof result.display_name !== 'undefined') {
                header_adress = result.display_name;
            }else{
                header_adress = coordonnees;
            }

            if (type_product === 'full') {
                journeyButtons = '<div class="poi-proximity-buttons-container">' +
                    '<button id="popup-departure-btn" class="ikisio ikisio-departure" data-type="from" data-label="' + header_adress + '" data-latlng="' + latlng + '"  data-autocomplete-hidden="'+ autocompleteHidden +'">' +
                    '<span>' + Translator.trans('popup.links.from.title') + '</span>' +
                    '</button>'+
                    '<button id="popup-arrival-btn" class="ikisio ikisio-arrival" data-type="to" data-label="' + header_adress + '" data-latlng="' + latlng + '"  data-autocomplete-hidden="'+ autocompleteHidden +'"> ' +
                    '<span>' + Translator.trans('popup.links.to.title') + '</span>' +
                    '</button>' +
                    '</div>';
            }

            //This test is to see if there is a special trad key for the client
            if (typeof Kisio.proximityMarkerPoiType[poi.poi_type].trad_key !== 'undefined' && Kisio.proximityMarkerPoiType[poi.poi_type].trad_key.indexOf('proximity.') < 0) {
                coordonnees = Kisio.proximityMarkerPoiType[poi.poi_type].trad_key+ ' : '+coordonnees;
            }
            let queryShowStops = '';
            if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
                queryShowStops = '&proximity_search[show_stops]=true';
            } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
                queryShowStops = '&proximity_search[show_stops]=false';
            }
            let proximityButton = createProximityButton(poi.coord, coordonnees, autocompleteHidden, queryShowStops);
            if(poi.provider_name != "Get Around"){
                infoBattery = '<div class="proximity-poi-distance"><img src="' + (parameters.urlImages + 'modes/Groupe 120.svg') + '" /><span>' + poi.battery + ' kms</span></div>';
            }else {
                infoBattery = '';
            }

            // TO DO infoBattery when free-floating will be included
            let popupContent = '<div id="leaflet-popup" class="proximity-poi-popup">' +
                '<div id="leaflet-popup-header">' +
                '<img src="' + iconPOIUrl + '" width="24" height="24" />' +
                '<span>' + header_adress + '</span>' +
                '</div>' +
                '<div id="leaflet-popup-content" class="clearfix">' +
                '<div class="proximity-poi-info-container">' +
                '<div class="proximity-poi-info-left-side">' +
                '<div class="proximity-poi-address">' + address + '</div>' +
                '</div>' +
                '<div class="proximity-poi-info-right-side">' +
                favoriteBSS +
                infoDistance +
                infoBattery +
                '</div>' +
                '</div>' + journeyButtons + proximityButton +
                '</div>' +
                '</div>';
            markerIcon.bindPopup(popupContent).openPopup();
            setTimeout(function () {
                let favoritesManager = determineFavManager();
                if (Kisio.enable_favorites && favoritesManager !== undefined) {
                    checkFavoritesOnPage(favoritesManager, '.proximity-poi-popup ');
                }
            }, 500);
        }
    });
};