import { addAddresses } from './manageAddresses'
import { showResultsOnMap, manageResults } from './manageResults';
import SimpleBar from 'pnp_core_bundle/simplebar/simplebar';
import { parameters} from 'pnp_core_bundle/plugnplay/leaf-core';
import { manageCounter } from './manageCounter'
import { drawCircle } from './drawCircle'
import { updateURLHistory } from './utils';
import { setURLDynamically } from './manageUrl';
import { initializeMap } from 'pnp_core_bundle/modules/map';

/**
 * Launch Update Process
 */
 export function launchUpdateProcess () {

    JKisio('#proximity_search_uri .popup').remove();
    if (checkAutocompletesValues()) {
        manageCounter();
        if (JKisio('#ctp-proximityPoiForm input[type=checkbox]:checked').length) {
            let showStops = '';
            if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
                showStops = 'true';
            } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
                showStops = 'false';
            }
            clearProximityMap();
            JKisio('#loading-box').css("visibility", "visible");
            setTimeout(function () {
                const coords = JKisio('#proximity_search_uri_autocomplete').attr('data-coord');
                const queryParams = {
                    proximity_search: {
                        coords: coords,
                        distance: JKisio('#proximity-search-time-input input:checked').val()
                    }
                };

                drawCircle(coords, JKisio('#proximity-search-time-input input:checked').val());
                updateProximityRequest(showStops, queryParams);
                JKisio('#loading-box').css("visibility", "hidden");
            }, 300);
        } else {
            Kisio.poiGroupLayer.clearLayers();
        }
    }
};


/**
 * Launch request and show results
 */
    export function launchProximityRequest(distance, filters, showStops) {
    JKisio('#proximity_search_uri .popup').remove();
    if (checkAutocompletesValues()) {
        // Launch request
        let params = {
            'proximity_search[uri][autocomplete-hidden]': JKisio('#proximity_search_uri_autocomplete-hidden').val(),
            'proximity_search[uri][autocomplete]': JKisio('#proximity_search_uri_autocomplete').val(),
            'proximity_search[distance]': distance,
            'proximity_search[coords]': JKisio('#proximity_search_uri_autocomplete').attr('data-coord')
        };
        let query = JKisio.param(params);
        if (filters !== undefined && filters.length > 0) {
            for (const filter of filters) {
                query += '&proximity_search[filter][]=' + filter;
            }
        }
        let route = Routing.generate('proximity_form_result', {type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''}) + '?' + query;
        JKisio.ajax({
            url: route,
            async: false,
            success: function(result) {
                JKisio('#div_show_poi_list').show().promise().done(function () {
                    addAddresses(result, 'launch');
                    manageResults();
                    manageCounter();
                    if (result.data.result_map !== undefined) {
                        showResultsOnMap(result.data.result_map, showStops);
                    }
                    route = addQueryParametersToRoute(route);
                    updateURLHistory(route);
                });
            },
            error: function (xhr, status, error) {
                JKisio('#proximity-results').html('').append(error);
                console.log(error);
            }
        });
    }
};



/**
 *
 * @param elt
 */
 export function launchProximitySearch(elt) {
    JKisio('#proximity_search_uri .popup').remove();
    if (JKisio('#ctp-searchProximity').is(':visible') && (JKisio('#proximity_content').length > 0 || JKisio('.proximity-standalone').length > 0)) {
        let that = elt;
        if (JKisio('#ctp-proximityPoiForm').length > 0) {
            if (that !== undefined) {
                JKisio('#ctp-proximityPoiForm #proximity_search_uri_autocomplete').val(JKisio(that).attr('title'));
                JKisio('#ctp-proximityPoiForm #proximity_search_uri_autocomplete-hidden').val(JKisio(that).attr('id'));
            } else if (JKisio('#proximity_search_uri #proximity_search_uri_autocomplete-hidden').val() !== undefined && JKisio('#proximity_search_uri #proximity_search_uri_autocomplete').val() !== undefined) {
                JKisio('#ctp-proximityPoiForm #proximity_search_uri_autocomplete').val(JKisio('#proximity_search_uri #proximity_search_uri_autocomplete').val());
                JKisio('#ctp-proximityPoiForm #proximity_search_uri_autocomplete-hidden').val(JKisio('#proximity_search_uri #proximity_search_uri_autocomplete-hidden').val());
            }
        }
        JKisio('#loading-box').css("visibility", "visible");
        let showStops = '';
        if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
            showStops = 'true';
        } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
            showStops = 'false';
        }
        clearProximityMap();
        setTimeout(function () {
            //Show marker on map
            let coords;
            if (that !== undefined && typeof JKisio(that).data('coord') !== 'undefined') {
                coords = JKisio(that).data('coord');
            } else if (JKisio('#proximity_search_uri #proximity_search_uri_autocomplete-hidden').data('coord') !== undefined) {
                let coord_object = JKisio('#proximity_search_uri_autocomplete-hidden').data('coord');
                coords = coord_object.lat + ';' + coord_object.lon;
            }
            if (coords !== undefined) {
                JKisio('#proximity_search_uri_autocomplete').attr('data-coord', coords);
                addMarker(coords);
                drawCircle(coords);
            }
            if (JKisio('#ctp-proximityPoiForm').length > 0) {
                const queryParams = {
                    proximity_search: {
                        coords: coords,
                        distance: JKisio('#proximity-search-time-input input:checked').val()
                    }
                };

                updateProximityRequest(showStops, queryParams);
            } else {
                launchProximityRequest(JKisio('#proximity-search-time-input input:checked').val(), undefined, showStops);
            }
            JKisio('#loading-box').css("visibility", "hidden");
        }, 300);
    }
};

/**
 * Clear the Proximity Map of all Markers, Circle, Tooltips, etc.
 */
 export function clearProximityMap() {
    window.leaflet = window.leaflet || {};
    if (window.leaflet) {
        if(!window.leaflet.map){
            window.leaflet.map = initializeMap(parameters);
            window.boardWidth = (JKisio('#board').length > 0) ? JKisio('#board').outerWidth() + JKisio('#board').offset().left : 0;
        }
        Kisio.poiGroupLayer.clearLayers();
        Kisio.stopPointGroupLayer.clearLayers();
        window.leaflet.map.eachLayer(function (layer) {
            if (typeof layer._path !== 'undefined') {
                window.leaflet.map.removeLayer(layer);
            }
        });
        if (JKisio('.label-map-marker-to').length == 0) {
            JKisio('#mapid .leaflet-interactive').remove();
        }
        if (JKisio('.label-map-marker-to').length > 0 && JKisio('.label-map-marker-from').length == 0) {
            JKisio('.label-map-marker-to').remove();
        }
        JKisio('#mapid .walk-legend').remove();
        JKisio('#proximity-show-hide-stops').remove();
    }
};

/**
 * Create Proximity Button for POI & StopPoints PopUp
 * @param elt
 * @param name
 * @param autocompleteHidden
 */
 export function createProximityButton(elt, name, autocompleteHidden, queryShowStops) {
    let queryInit = decodeURIComponent(JKisio('#ctp-proximityPoiForm').serialize());
    let regex = /proximity_search\[uri\]\[autocomplete\]=^$|(.+)&proximity_search\[uri\]\[autocomplete-hidden\]=^$|(.+)/gm;
    let subst = 'proximity_search[uri][autocomplete]=' + name.replace(/#/g, '%23') + '&proximity_search[uri][autocomplete-hidden]=' + autocompleteHidden.replace(/#/g, '%23');
    let query = queryInit.replace(regex, subst);
    query += '&proximity_search[coords]=' + elt.lat +';'+ elt.lon;
    query += queryShowStops;
    let href = Routing.generate('proximity_form_result', {type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''}) + '?' + query;
    return '<a href="' + href + '"><button id="popup-proximity-btn" class="ikisio ikisio-proximity"> ' +
        '<span>' + Translator.trans('proximity.form.poi.title') + '</span></button></a>';
};


/**
 *  Update request and update results
 */
 export function updateProximityRequest(showStops, queryParams) {
    let queryInit = JKisio('#ctp-proximityPoiForm').serialize() + '&' + JKisio.param(queryParams);
    let urlUpdate = Routing.generate(
        'proximity_form_result',
        {type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : ''}) + '?' + queryInit;
    // Launch request
        JKisio.ajax({
            url: urlUpdate,
            async: false,
            success: function (result) {
                if (!JKisio('#proximity-results').is(':visible')) {
                    JKisio('#proximity-results').show();
                }
                if (!JKisio('#div_show_poi_list').is(':visible')) {
                    JKisio('#div_show_poi_list').show();
                }
                addAddresses(result, 'update');
                manageCounter();
                if (result.data.result_map !== 'undefined') {
                    showResultsOnMap(result.data.result_map, showStops);
                }
                let route = urlUpdate;
                route = addQueryParametersToRoute(route);
                setURLDynamically(route);
            },
            error: function (xhr, status, error) {
                JKisio('#proximity-results').html('').append(error);
                console.log(error);
            }
        });
};


/**
 * Check if autocompletes Values are filled and show error popup if not
 * @returns {boolean}
 */
 export function checkAutocompletesValues() {
    let messageAria;
    let message;
    let showMessage = false;
    if (JKisio('#proximity_search_uri_autocomplete-hidden').val() === '') {
        if (JKisio('#proximity_search_uri_autocomplete').val() === '') {
            message = Translator.trans('places_nearby.uri.errors.blank');
            messageAria = Translator.trans('places_nearby.uri.errors.blankAria');
        } else {
            message = Translator.trans('places_nearby.uri.errors.no-solution');
            messageAria = Translator.trans('places_nearby.uri.errors.no-solution');
        }
        showMessage = true;
    } else {
        if (JKisio('#proximity_search_uri_autocomplete').val() === '') {
            message = Translator.trans('places_nearby.uri.errors.no-solution');
            messageAria = Translator.trans('places_nearby.uri.errors.no-solution');
            showMessage = true;
        }
    }
    if (showMessage) {
        if (JKisio('#error_popup').length > 0) {
            JKisio('#error_popup').remove();
        }
        let html = "<div class='popup show'><div id='error_popup'><span class='popuptext' id='myPopup' tabindex='0'>" +
            "<i class='ikisio ikisio-warning' aria-hidden='true'></i>" +
            "<span aria-hidden='true'>" + message + "</span>" +
            "<span class='sr-only' aria-live='assertive'>" + messageAria + "</span>" +
            "</span></div></div>";
        JKisio(html).insertBefore('#proximity_search_uri_autocomplete');
        JKisio('#myPopup').focus();
        return false;
    } else {
        return true;
    }
};

/**
 * Add supplemental parameters to query for rewriting GET URL
 * @param route
 * @returns {*}
 */
 export function addQueryParametersToRoute(route) {
    if (route.indexOf('coords') === -1) {
        route += '&proximity_search[coords]=' + JKisio('#proximity_search_uri_autocomplete').attr('data-coord');
    }
    if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
        route += '&proximity_search[show_stops]=true';
    } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
        route += '&proximity_search[show_stops]=false';
    }
    return route;
};


  /**
     * Add marker on map
     * @param coords
     */
    export function addMarker (coords) {
    if (typeof coords !== 'undefined') {
        if(JKisio('img.leaflet-marker-icon').length > 0) {
            JKisio('img.leaflet-marker-icon').remove();
        }
        let icon = parameters.L.icon({
            iconUrl: parameters.urlImages + 'map-marker-from.svg',
            iconSize: parameters.icon_size
        });
        let object_coord = coords.split(';');
        if (typeof object_coord !== 'undefined' && Object.keys(object_coord).length > 1) {
            parameters.L.marker([object_coord[0], object_coord[1]], {icon: icon, keyboard: false}).addTo(window.leaflet.map);
            window.leaflet.map.setView([object_coord[0], object_coord[1]]);
        }
    }
};