import { clearProximityMap, createProximityButton} from './manageProximity'
import { getHTMLForDistanceObject } from './htmlUtils'
import { getURLToSchedule, getUrlParameter } from './manageUrl'
import { getLinePicto, getModePicto } from 'pnp_core_bundle/plugnplay/customize_client';
import { hideProxFavContent} from 'pnp_core_bundle/plugnplay/favorites';
import { manageClickPopUpJourneyButtons} from 'pnp_core_bundle/plugnplay/leaf-core';

/**
 * Manage PopUp behavior when clicking on #popup-departure-btn OR #popup-arrival-btn
 */
export function managePopUp () {
    JKisio(document).on('click', '#popup-departure-btn, #popup-arrival-btn', function () {
        let params;
        clearProximityMap();
        if (JKisio(this).closest('div').hasClass('poi-proximity-buttons-container')) {
            params = {
                address: JKisio(this).attr('data-label'),
                fieldType: JKisio(this).attr('data-type'),
                latlng: JSON.parse(decodeURIComponent(JKisio(this).attr('data-latlng'))),
                autocompleteHiddenValue: JKisio(this).attr('data-autocomplete-hidden'),
                type: 'prox'
            };
            hideProxFavContent(JKisio("#proximity_content"), 'proximity', function () {
                manageClickPopUpJourneyButtons(params, window.leaflet.map);
            });
        } else {
            let link = decodeURIComponent(JKisio("#iKisio-link").attr('href'));
            let linkAddress = getUrlParameter(link, 'proximity_search[uri][autocomplete]');
            let linkCoords = getUrlParameter(link, 'proximity_search[coords]').split(";");
            let linkAutocompleteHidden = getUrlParameter(link, 'proximity_search[uri][autocomplete-hidden]');
            params = {
                address: linkAddress,
                fieldType: JKisio(this).attr('data-type'),
                latlng: linkCoords,
                autocompleteHiddenValue: linkAutocompleteHidden,
                type: 'prox'
            };
            hideProxFavContent(JKisio("#proximity_content"), 'proximity', function() {
                manageClickPopUpJourneyButtons(params, window.leaflet.map);
            });
        }
    });
};

/**
 * Create the HTML for the stop_point PopUp
 * @param markerStop
 */
 export function createStopPointPopUp(markerStop) {
    let stop = JKisio(markerStop).data('stop-object');
    let stopAreaLabel = '';
    if (stop.stop_point !== undefined && stop.stop_point.stop_area !== undefined && stop.stop_point.stop_area.label !== undefined) {
        stopAreaLabel = stop.stop_point.stop_area.label;
    }
    let stopAreaId = '';
    if (stop.stop_point !== undefined && stop.stop_point.stop_area !== undefined && stop.stop_point.stop_area.id !== undefined) {
        stopAreaId = stop.stop_point.stop_area.id;
    }
    let autocompleteHidden = stop.id;
    let latlng = encodeURIComponent(JSON.stringify(stop.stop_point.coord));
    let type_product = (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : 'standalone';
    let journeyButtons = '';
    let name = stop.name;
    let infoDistance = getHTMLForDistanceObject(stop);
    let classNoContent = ' class="clearfix"';
    if (type_product === 'full') {
        journeyButtons = '<div class="poi-proximity-buttons-container">' +
                            '<button id="popup-departure-btn" class="ikisio ikisio-departure" data-type="from" data-label="' + name + '" data-latlng="' + latlng + '"  data-autocomplete-hidden="'+ autocompleteHidden +'">' +
                                '<span>' + Translator.trans('popup.links.from.title') + '</span>' +
                            '</button>'+
                            '<button id="popup-arrival-btn" class="ikisio ikisio-arrival" data-type="to" data-label="' + name + '" data-latlng="' + latlng + '"  data-autocomplete-hidden="'+ autocompleteHidden +'"> ' +
                                '<span>' + Translator.trans('popup.links.to.title') + '</span>' +
                            '</button>' +
                        '</div>';
    }
    let queryShowStops = '';
    if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
        queryShowStops = '&proximity_search[show_stops]=true';
    } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
        queryShowStops = '&proximity_search[show_stops]=false';
    }
    let proximityButton = createProximityButton(stop.stop_point.coord, name, autocompleteHidden, queryShowStops);
    let htmlHeader = '<span>' + name + '</span>';
    let htmlContent = '';
    let URLToSchedule = '#';
    let level_severity = '';
    JKisio.ajax({
        url: Routing.generate('r_public_transport_objects_filtered',
            {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                public_transport_object_type: 'stop_points',
                public_transport_object_id: stop.id,
                action: 'routes'
            }),
        dataType: 'json',
        success: function (result) {
            let arrayOfDisruption = [];
            if (typeof result.disruptions !== "undefined") {
                let disruptions = result.disruptions;
                disruptions.forEach(function (d) {
                    let severity = d.severity.effect;
                    switch (severity) {
                        case 'NO_SERVICE':
                            level_severity = 'disrupt';
                            break;
                        case 'SIGNIFICANT_DELAYS':
                        case 'REDUCED_SERVICE':
                        case 'DETOUR':
                        case 'ADDITIONAL_SERVICE':
                        case 'MODIFIED_SERVICE':
                        case 'UNKNOWN_EFFECT':
                        case 'STOP_MOVED':
                            level_severity = 'warning';
                            break;
                        case 'OTHER_EFFECT':
                        default:
                            level_severity = 'information';
                            break;
                    }
                    arrayOfDisruption[d.impacted_objects[0].pt_object.id] = {
                        'cause' : d.cause,
                        'image': '/bundles/canaltppnpcore/images/disruptions/'+level_severity+'.svg'
                    }
                });
            }

            if (result.routes !== undefined && Array.isArray(result.routes) && result.routes.length > 0) {
                let routes = result.routes;
                if (routes.length > 1) {
                    htmlHeader = '<div class="img-mode-container">';
                    let listModes = [];
                    routes.forEach(function (r) {
                        let l = r.line;
                        let mode = l.commercial_mode.name.toLowerCase();
                        if (listModes.indexOf(mode) === -1) {
                            listModes.push(mode);
                            htmlHeader += getModePicto(l.commercial_mode.name);
                        }
                    });
                    if (listModes.length === 1) {
                        let listLines = [];
                        routes.forEach(function (r) {
                            let l = r.line;
                            let idLine = l.id;
                            if (listLines.indexOf(idLine) === -1) {
                                listLines.push(idLine);
                                htmlHeader += getLinePicto(l, arrayOfDisruption, 'lines_in_popup');
                            }

                        });
                        if (listLines.length === 1) {
                            htmlContent = Translator.trans('places_nearby.popup.stop-points.line') + ' ' + routes[0].line.name;
                        }
                    }
                    htmlHeader += '</div><span>' + name + '</span>';
                    URLToSchedule = getURLToSchedule (stopAreaLabel, stopAreaId);
                } else {
                    let line = routes[0].line;
                    htmlHeader = '<div class="img-mode-container">';
                    htmlHeader += getModePicto(line.commercial_mode.name) + getLinePicto(line, arrayOfDisruption, 'lines_in_popup');

                    htmlHeader += '</div><span>' + name + '</span>';
                    let directionType;
                    if (routes[0].direction !== undefined && routes[0].direction.name !== undefined && routes[0].direction.name !== '') {
                        if (routes[0].direction_type !== undefined && routes[0].direction_type !== '') {
                            directionType = routes[0].direction_type;
                        }
                        htmlContent = Translator.trans('places_nearby.popup.stop-points.towards') + ' ' + routes[0].direction.name;
                    }
                    URLToSchedule = getURLToSchedule(stopAreaLabel, stopAreaId, line.id, directionType);
                }
            }

            // Prepare html with link to Schedule Page
            let scheduleContent = '';
            if (type_product === 'full' && stopAreaLabel !== '' && stopAreaId !== '' && URLToSchedule !== '#') {
                scheduleContent = '<a href="' + URLToSchedule + '"><button id="popup-proximity-btn" class="ikisio ikisio-times"> <span>' + Translator.trans('proximity.form.schedule') + '</span></button></a>';
            }
            let popupContent = '<div id="leaflet-popup" class="proximity-poi-popup">' +
                '<div id="leaflet-popup-header"' + classNoContent + '>' + htmlHeader + '</div>' +
                '<div id="leaflet-popup-content"' + classNoContent + '>' +
                '<div class="proximity-poi-info-container">' +
                '<div class="proximity-poi-info-left-side">' +
                '<div class="proximity-poi-address">' + htmlContent + '</div>' +
                '</div>' +
                '<div class="proximity-poi-info-right-side">' +
                infoDistance +
                '</div>' +
                '</div>' + journeyButtons + proximityButton +
                scheduleContent +
                '</div>' +
                '</div>';
            markerStop.bindPopup(popupContent).openPopup();
        },
        error: function (xhr, status, error) {
            console.log(error);
        }
    });
};

