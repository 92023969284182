import { clearMap } from 'pnp_core_bundle/modules/map';
import { showProxFavContent, hideProxFavContent} from 'pnp_core_bundle/plugnplay/favorites';
import { parameters, manageClickPopUpJourneyButtons} from 'pnp_core_bundle/plugnplay/leaf-core';
import { addEventListeners } from './event-listener';
import { getUrlParameter } from './core/manageUrl'
import { reloadPositionBoard } from './core/reloadPositionBoard'
import { drawCircle } from './core/drawCircle'
import { clearProximityMap,  launchProximitySearch, updateProximityRequest, launchProximityRequest, checkAutocompletesValues, addMarker } from './core/manageProximity';
import { hideProximityResultContent, showProximityResultContent, showResults, manageResults } from './core/manageResults';
    

const icon_default_config = {
    iconSize: 16,
    iconAnchor: [4, 30],
    shadowSize: 37,
    shadowAnchor: [15, 35],
    popupAnchor: [4, -25],
    className: 'proximity-poi-icon'
};

export const modelProximity = Kisio.loadBoardEvents.models.libModels.extend({

    /**
     * Initialize Proximity
     */
    initializeProximity: function () {
        
        if (Kisio.proximity_clustering_activated !== undefined && Kisio.proximity_clustering_activated) {
            Kisio.poiGroupLayer = new  parameters.MarkerClusterGroup({
                maxClusterRadius: Kisio.proximity_clustering_maxClusterRadius
            });
            Kisio.stopPointGroupLayer = new parameters.MarkerClusterGroup({
                maxClusterRadius: Kisio.proximity_clustering_maxClusterRadius
            });
        } else {
            Kisio.poiGroupLayer = new parameters.LayerGroup();
            Kisio.stopPointGroupLayer = new parameters.LayerGroup();
        }

        const _this = this;
        let queryString = decodeURIComponent(window.location.search);
        let queryAutocompleteValue = '';
        let queryAutocompleteHiddenValue = '';
        let queryAutocompleteCoords = '';
        let queryDistance = '';
        let queryFilters = [];
        let queryShowStops = '';
        const initialCoords = Kisio.map_config.latitude+';'+Kisio.map_config.longitude;
        if (queryString !== '') {
            queryAutocompleteValue = getUrlParameter(queryString, 'proximity_search[uri][autocomplete]');
            queryAutocompleteHiddenValue = getUrlParameter(queryString, 'proximity_search[uri][autocomplete-hidden]');
            queryAutocompleteCoords = getUrlParameter(queryString, 'proximity_search[coords]');
            queryDistance = getUrlParameter(queryString, 'proximity_search[distance]');
            queryFilters = getUrlParameter(queryString, 'filter');
            queryShowStops = getUrlParameter(queryString, 'proximity_search[show_stops]');
        }
        
        //Desactivate click to avoid recalculation of top board
        JKisio(document).on('click', '#proximity_title', function () {
            return false;
        });

        //Modify behavior for /full/proximity
        if (JKisio('#show_proximity').val() === '1') {
            JKisio('#proximity_content').show();
            JKisio('#board_title_container, #journey_content, #board_footer').hide();
            JKisio(".autocomplete-search-icon, #proximity-search-form .locate-position").show();
        }

        //Product full and proximity option activated
        if (JKisio('#footer_proximity').length > 0 && !JKisio('#proximity_content').is(':visible')) {
            JKisio("#footer_proximity, #proximity-search-form .locate-position").click(function (e) {
                e.preventDefault();
                clearMap();
                JKisio('.leaflet-pane .leaflet-shadow-pane').show();
                JKisio('.leaflet-pane .leaflet-marker-pane').show();
                if (JKisio('#proximity-results').length <= 0) {
                    let div = JKisio("#proximity_content");
                    if (!JKisio(div).is(':visible')) {
                        _this.manageChange();
                        addMarker(initialCoords)
                        JKisio.ajax({
                            url: Routing.generate('proximity_home',
                                {
                                    type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''
                                }),
                            success: function (result) {
                                JKisio("<div id='proximity_content'></div>").insertAfter('#schedule_content');
                                JKisio('#proximity_content').html(result.html);
                                JKisio('#proximity_search_uri_autocomplete').autocomplete();
                                Kisio.Callbacks.autocomplete();

                                //Hide journey results if present
                                if (JKisio('#result_content').length > 0 && JKisio('#result_content').is(':visible')) {
                                    JKisio('#result_content').hide();
                                }
                                showProxFavContent("#proximity_content", 'proximity');
                                JKisio('#board_title_container, #journey_content, #schedule_content, #traffic_content').hide();
                                showResults(result);
                            },
                            error: function () {
                                console.log('Error in loading Proximity template');
                            },
                            async: false
                        });
                    } else {
                        hideProxFavContent(div, 'proximity');
                    }
                }else{
                    JKisio('#board_title_container, #journey_content, #schedule_content, #result_content').hide();
                    JKisio('#proximity_content').show();
                    JKisio('#board_container').animate({'top': '2%'}, "fast", "swing");
                }
            });

        }

        //Product standalone
        if ((JKisio('#ctp-proximityForm').length > 0 && JKisio('#ctp-proximityForm').is(':visible')) || JKisio('#proximity-search-form').length > 0) {
            _this.manageChange();
            JKisio(".autocomplete-search-icon, #proximity-search-form .locate-position").show();
            if (queryAutocompleteValue === '') {
                JKisio.ajax({
                    url: Routing.generate('proximity_home',
                        {
                            type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : ''
                        }),
                    success: function (result) {
                        JKisio('#proximity_search_uri_autocomplete').autocomplete();
                        Kisio.Callbacks.autocomplete();
                        showResults(result);
                    },
                    error: function () {
                        console.log('Error in loading Proximity template');
                    },
                    async: false
                });
            }
            if (queryAutocompleteValue !== '' && queryAutocompleteHiddenValue !== '' && queryAutocompleteCoords !== '') {
                JKisio('#loading-box').css("visibility", "visible");
                clearProximityMap();
                let distance = JKisio('#proximity-search-time-input input:checked').val();
                if (queryDistance !== '') {
                    JKisio('#proximity-search-time-input input:checked').prop('checked', false);
                    JKisio('#proximity-search-time-input input[value="' + queryDistance + '"]').prop('checked', true);
                    distance = queryDistance;
                }
                let filters = [];
                if (queryFilters.length > 0) {
                    filters = queryFilters;
                }

                clearProximityMap()
                
                setTimeout(function () {
                    //Show marker on map
                    let coords = queryAutocompleteCoords;
                    JKisio('#proximity_search_uri_autocomplete').attr('data-coord', coords);
                    addMarker(coords);
                    drawCircle(coords, distance);
                    if (JKisio('#ctp-proximityPoiForm').length > 0) {
                        const queryParams = {
                            proximity_search: {
                                coords: coords,
                                distance: distance
                            }
                        };
                        updateProximityRequest(queryShowStops, queryParams);
                    } else {
                        launchProximityRequest(distance, filters, queryShowStops);
                    }
                    JKisio('#loading-box').css("visibility", "hidden");
                }, 300);
                manageResults();
            }
        }

        //On poi list, launch actions on buttons Departure/Arrival/Geoloc
        JKisio(document).on('click', ".prox-departure-btn, .prox-arrival-btn, .prox-recenter-btn", function () {
            let fieldType = JKisio(this).attr('data-type');
            let address = JKisio(this).attr('data-address');
            let latlng = JKisio(this).attr('data-coord');
            let object_coord = latlng.split(';');
            let coords = {lat: object_coord[0], lng: object_coord[1]};
            let type = 'map';
            let autocompleteHiddenValue = '';
            if (JKisio(this).attr('data-autocompleteHidden') !== undefined) {
                type = 'prox';
                autocompleteHiddenValue = JKisio(this).attr('data-autocompleteHidden');
            }
            let params = {
                fieldType: fieldType,
                address: address,
                latlng: coords,
                type: type,
                autocompleteHiddenValue: autocompleteHiddenValue
            };
            //In case, the user is in the favorite section, hide it to show other section
            if (JKisio('#favorites_content').length > 0) {
                JKisio('#favorites_content').hide();
            }
            manageClickPopUpJourneyButtons(params, window.leaflet.map);
        });

        addEventListeners();
    },
    /**
    * Manage change
    */
    manageChange: function () {
        let _this = this;

        JKisio(document).on('change, keyup', '#proximity_search_uri_autocomplete-hidden', function () {
            console.log(JKisio('#proximity_search_uri_autocomplete-hidden').val());
        });

        /*
            * Prevent Submitting Form by traditionnal way
            */
        JKisio(document).on('submit', '#ctp-proximityForm, #ctp-proximityPoiForm', function (event) {
            event.preventDefault();
            checkAutocompletesValues();
        });

        /*
            * Add marker when click on an autocomplete element
            */
        JKisio(document).on('click', '.ui-menu-item a', function () {
            launchProximitySearch(this);
        });

        // Change distance
        JKisio(document).on('click', '#proximity-search-time-input input', function () {
            JKisio('#proximity_search_uri .popup').remove();
            if (checkAutocompletesValues()) {
                JKisio('#loading-box').css("visibility", "visible");
                let distance = JKisio(this).val();
                let distanceId = JKisio(this).attr('id');
                if (JKisio('#ctp-proximityPoiForm').length > 0) {
                    JKisio('#ctp-proximityPoiForm #proximity_search_distance').val(distance);
                }
                let showStops = '';
                if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
                    showStops = 'true';
                } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
                    showStops = 'false';
                }
                clearProximityMap();
                setTimeout(function () {
                    const autocompleteInput = JKisio('#proximity_search_uri_autocomplete');
                    const queryParams = {
                        proximity_search: {
                            coords: autocompleteInput.attr('data-coord'),
                            uri: {
                                "autocomplete": autocompleteInput.val(),
                                "autocomplete-hidden": JKisio('#proximity_search_uri_autocomplete-hidden').val()
                            }
                        }
                    };
                    addMarker(JKisio('#proximity_search_uri_autocomplete').attr('data-coord'));
                    drawCircle(JKisio('#proximity_search_uri_autocomplete').attr('data-coord'), distance);
                    updateProximityRequest(showStops, queryParams);
                    JKisio('#loading-box').css("visibility", "hidden");
                },300);
            }
        });

        //Show clear button when user enter letter(s)
        JKisio(document).on('keydown', '#proximity_search_uri_autocomplete', function () {
            if(JKisio('#proximity_search_uri_autocomplete').val() !== '') {
                JKisio('.autocomplete-clear, .autocomplete-clear i').show();
                JKisio('#proximity_search_uri .popup').remove();
            }
        });
        // Keyboard Enter event for Close Proximity button
        JKisio(document).on('keyup', '#proximity_clear', function (e) {
            if (e.keyCode === 13) {
                JKisio(this).trigger('click');
            }
        });
        // Show previous results when clicking on Close Proximity
        JKisio(document).on('click', '#proximity_clear', function () {
            JKisio('#proximity_search_uri .popup').remove();
            hideProxFavContent(JKisio("#proximity_content"), 'proximity');
            JKisio('.leaflet-pane .leaflet-shadow-pane').hide();
            JKisio('.leaflet-pane .leaflet-marker-pane').hide();
            clearProximityMap()
        });
        // Keyboard Enter event for autocomplete clear button
        JKisio(document).on('keyup', '#proximity-search-form .autocomplete-clear', function (e) {
            if (e.keyCode === 13) {
                JKisio('#proximity-autocomplete-clear').trigger('click');
            }
        });
        // Click event for autocomplete clear button
        JKisio(document).on('click', '#proximity-autocomplete-clear', function () {
            JKisio('#proximity_search_uri .popup').remove();
            JKisio('#proximity_search_uri_autocomplete').val('');
            JKisio('#proximity_search_uri_autocomplete-hidden').val('');
            clearProximityMap();
            JKisio('.autocomplete-clear, #proximity-results, #div_show_poi_list').hide();
            JKisio('#proximity_search_uri_autocomplete').focus();
            JKisio("#proximity-results .poi_list").html('');
            JKisio('#board_container').animate({'top': 130}, "fast", "swing");
        });
    },
});

JKisio(document).on('keyup', '.ctp-proxi-accordion legend', function (e) {
    if (e.keyCode === 13) {
        e.preventDefault();
        JKisio(this).trigger('click');
    }
});

// Click on category title
JKisio(document).on('click', '.ctp-proxi-accordion legend', function (e) {
    if (!JKisio(e.target).hasClass('chk_all')) {
        if (JKisio(this).next('div').is(':visible')) {
            JKisio(this).attr('aria-expanded', 'false');
            JKisio(this).find('i').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
            JKisio(this).next('div').hide().promise().done(function () {
                JKisio('#proximity-results').css({'height': 'auto'});
                window.proximitySimpleBar.recalculate();
                reloadPositionBoard();
            });
        } else {
            let __this = this;
            JKisio('#ctp-proximity-poi .ctp-proxi-accordion fieldset > div').hide().promise().then(function () {
                JKisio(__this).attr('aria-expanded', 'true');
                JKisio('#ctp-proximity-poi .ctp-proxi-accordion legend i').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
                JKisio(__this).find('i').removeClass('ikisio-arrow-drop-up').addClass('ikisio-arrow-drop-down');
                JKisio(__this).next('div').show().promise().then(function () {
                    JKisio('#proximity-results').css({'height': 'auto'});
                    window.proximitySimpleBar.recalculate();
                    reloadPositionBoard();
                });
            });
        }
    }
});

/**
 * Deal with Enter key pressed
 */
JKisio(document).on('keyup', '#proximity_search_uri_autocomplete', function (e) {
    if (e.keyCode === 13) {
        e.preventDefault();
        if (checkAutocompletesValues()) {
            setTimeout(function () {
                launchProximitySearch();
            }, 150);
        }
    }
});

/**
 * Click on button to Show addresses or Show Preferences
 */
JKisio(document).on('click', '#show_poi_list', function () {
    if (JKisio('.poi_list').is(':visible')) {
        JKisio('#show_poi_list').html(Translator.trans('proximity.result.show_poi_list'));
        showProximityResultContent('.poi_list', function () {
            JKisio('#ctp-poi-bloc .ctp-proxi-accordion:first-child fieldset legend').focus();
        });
    } else {
        JKisio('#show_poi_list').html(Translator.trans('proximity.result.show_category_list'));
        hideProximityResultContent('#ctp-proximity-poi', function () {
            JKisio('#display_poi > div:first-child .ctp-list-point > fieldset:first-child legend').focus();
        });
    }
});

/**
 * Fold and Unfold list of Poi
 */
JKisio(document).on('click', '.poi_list .ctp-list-point legend', function () {
    if (JKisio(this).next('ul').is(':visible')) {
        JKisio(this).attr('aria-expanded', 'false');
        JKisio(this).find('i').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
        JKisio(this).next('ul').hide().promise().done();
    } else {
        let __this = this;
        JKisio('.poi_list fieldset > ul').hide().promise().then(function () {
            JKisio('.poi_list legend .ikisio').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
            JKisio(__this).attr('aria-expanded', 'true');
            JKisio(__this).find('i').removeClass('ikisio-arrow-drop-up').addClass('ikisio-arrow-drop-down');
            JKisio(__this).next('ul').show().promise().done();
        });
    }

});
