import { mapPopupConfig } from 'pnp_core_bundle/plugnplay/leaf-core';
import { showNextDepartures, showLinesByType, setUrlDynamically, addMarker } from '../../display_search_schedule_result';
import { showStopPointsByLineId, showOneLineById } from '../../load_infos';
import { clickOnFavorite } from 'pnp_core_bundle/plugnplay/favorites'

export const lineResults = Backbone.View.extend({
    params: {
        lineItem: '#ctp-searchScheduleResults .ui-menu-item a',
        nextDepartureId: '#next_departures_results',
        scheduleId: '#ctp-searchSchedule',
        inputCachedId: '#schedule_stop_area_lines_autocomplete-hidden',
        scheduleContentId: '#schedule_content',
        scheduleResultId: '#ctp-searchScheduleResults'
    },

    /**
     * this function manage stop area by line
     * @param stop_area_id
     */
    manageLineEvents: function(stop_area_id) {
        let _this = this;
        let defaultStyle, layerLine = {};
        let center, zoom, id, lineClicked;
        let count = 0;
        JKisio('#schedule_lines_content, #schedule_lines').hide();

        JKisio(_this.params.lineItem).on('keyup', function (e) {
            if (e.keyCode === 13) {
                JKisio(this).trigger('mouseover').trigger('click');
            }
        });

        JKisio(_this.params.lineItem).on('mouseover mouseenter', function (e) {
            id = JKisio(this).attr('id');
            if (typeof Kisio.schedule_map_config !== 'undefined' && Kisio.schedule_map_config.line_drawings) {
                if (layerLine instanceof L.LayerGroup) layerLine = {};
                window.leaflet.map.eachLayer(function (layer) {
                    if (typeof layer.options.customId !== 'undefined') {
                        if (layer.options.customId === id) {
                            defaultStyle = layer.options.style;
                            layerLine = layer;
                        }
                        layer.setStyle({opacity: 0});
                    }
                });
                if (count === 0) {
                    center = window.leaflet.map.getCenter();
                    zoom = window.leaflet.map.getZoom();
                    count++;
                }
                if (layerLine && layerLine instanceof L.LayerGroup && Object.keys(layerLine.getBounds()).length !== 0) {
                    window.leaflet.map.flyToBounds(layerLine.getBounds(), mapPopupConfig(window.boardWidth));
                    window.leaflet.map.on('moveend', function () {
                        if (layerLine instanceof L.LayerGroup) {
                            layerLine.setStyle({opacity: 1});
                        }
                    });
                }
            }
        }).click(function (e) {
            e.preventDefault();
            let self = this;
            let target = e.target;
            let direction_type;
            // In case of target of Click is a fav-star, we prevent the usual behavior and we enable the management of clicks on Favorites
            if (JKisio(target).hasClass('fav-star')) {
                clickOnFavorite(target);

                return false;
            }
            if (typeof target.attributes['data-direction'] !== 'undefined') {
                direction_type = target.attributes['data-direction'].nodeValue;
            }
            JKisio('#loading-box').css("visibility", "visible");
            setTimeout(function () {
                let object_coord = decodeURI(JKisio(self).attr('data-coord'));
                addMarker(object_coord);
                if (id) {
                    let queryString = decodeURIComponent(window.location.search);
                    let queryDirectionType = direction_type;
                    if (typeof direction_type === 'undefined' || direction_type === '') {
                        queryDirectionType = '';
                    }

                    if (queryString !== '') {
                        let regex = /&direction_type=(forward|backward)/;
                        let m;
                        if ((m = regex.exec(queryString)) !== null) {
                            queryDirectionType = m[1];
                        }
                    }
                    let labelStopArea = JKisio(self).attr('data-content');
                    let network_id = JKisio(self).attr('data-network');
                    if (Object.keys(layerLine).length > 0) {
                        lineClicked = true;
                        layerLine.setStyle({opacity: 1});
                        if (Kisio.schedule_map_config.stop_point_drawings && defaultStyle.color)
                            showStopPointsByLineId(id, defaultStyle.color);
                    }
                    if (JKisio(_this.params.nextDepartureId).length > 0) {
                        JKisio(_this.params.nextDepartureId).remove();
                    }
                    JKisio(_this.params.scheduleId).hide();

                    JKisio.ajax({
                        url: Routing.generate('schedule_next_time_board', {type_product: (window.Kisio.type_product !== 'undefined') ? window.Kisio.type_product : ''}),
                        async:false,
                        success: function(data) {
                            JKisio('#schedule_content').append(data);
                            JKisio('.result-back-button a').addClass('line');
                            JKisio('#stop_area_label').text(labelStopArea).attr('data-coord', object_coord).attr('data-line-id', id);
                            JKisio('.schedule-datetime-header .prev,.schedule-datetime-header .next').attr('data-content', labelStopArea).attr('data-coord', object_coord).attr('id', id);
                        },
                        error: function (xhr, status, error) {
                            console.log(error);
                        }
                    });
                    let lineColor;
                    if (Kisio.schedule_map_config.line_drawings) {
                        lineColor = showOneLineById(id, 1);
                    }
                    if (Kisio.schedule_map_config.stop_point_drawings && lineColor) showStopPointsByLineId(id, lineColor);

                    //Extract the object from the query and add "s" for navitia query
                    let type = stop_area_id.split(':', 1);
                    let object_type = type[0]+'s';
                    showLinesByType(stop_area_id, object_type, id, network_id);
                    JKisio('.schedule-lines').attr('id', id);
                    JKisio('#stop_area_label').attr('data-stop-area', stop_area_id);

                    showNextDepartures(stop_area_id, id, undefined, network_id, queryDirectionType);

                    const direction = queryDirectionType !== '' ? queryDirectionType : 'forward';
                    setUrlDynamically(stop_area_id, labelStopArea, id, direction);
                }
            }, 150);

        });
        JKisio(_this.params.scheduleResultId).mouseleave(function () {
            if (Object.keys(layerLine).length > 0 && JKisio(_this.params.nextDepartureId).length === 0) {
                window.leaflet.map.eachLayer(function(layer){
                    // show all line on the map
                    if (typeof layer.options.customId !== 'undefined') {
                        layer.setStyle({opacity: .5});
                    }
                });
                if (center && zoom) window.leaflet.map.flyTo(center, zoom);
                // reset layerLine
                layerLine = {};
            }
        });
    }
});
