import { addMarker } from './manageProximity';
import { createHideShowButtonStops } from './manageAddresses'
import { showResultsOnMap, manageResults } from './manageResults';
import { drawCircle } from './drawCircle'
/**
 * Get number of input checked and total by categories
 */
export function manageCounter () {
    let arrayOfBtnIds = {};
    JKisio('.ctp-proxi-accordion').each(function () {
        let parent_id = JKisio(this).attr('id');
        arrayOfBtnIds[parent_id] = {};
        let nbr_checked = 0;
        JKisio('#' + parent_id + ' div input').each(function (index) {
            arrayOfBtnIds[parent_id][index] = JKisio('#' + this.id).is(':checked');
            if (JKisio('#' + this.id).is(':checked')) {
                JKisio('#' + this.id).parent().parent().removeClass('poi-unchecked').addClass('poi-checked');
                addMarker(JKisio('#proximity_search_uri_autocomplete').attr('data-coord'));
                nbr_checked++;
            } else {
                JKisio('#' + this.id).parent().parent().addClass('poi-unchecked').removeClass('poi-checked');
            }
        });
        let total = Object.keys(arrayOfBtnIds[parent_id]).length;
        JKisio('#' + parent_id + ' .poi-legend').text(nbr_checked);
        JKisio('#' + parent_id + ' .poi-total').text(total);
        if (nbr_checked === total) {
            JKisio('#' + parent_id + ' .chk_all').prop('checked', true);
            JKisio('#' + parent_id + ' legend').addClass('category_checked').removeClass('category_unchecked');
            JKisio('#' + parent_id + ' .ctp-proximity-poi-checkbox').removeClass('poi-unchecked').addClass('poi-checked');
        } else {
            JKisio('#' + parent_id + ' .chk_all').prop('checked', false);
            JKisio('#' + parent_id + ' legend').removeClass('category_checked').addClass('category_unchecked');
        }
        if (nbr_checked === 0) {
            JKisio('#' + parent_id + ' .chk_all').prop('checked', false);
            JKisio('#' + parent_id + ' legend').removeClass('category_checked').addClass('category_unchecked');
        }
    });
};

export function showResults(result) {
    createHideShowButtonStops();
    JKisio('#board_container').animate({'top': '2%'}, "fast", "swing");
    manageCounter();
    manageResults();
    const coords = Kisio.map_config.latitude+';'+Kisio.map_config.longitude;
    const inverted_coords = Kisio.map_config.longitude+';'+Kisio.map_config.latitude;
    const input = JKisio('#proximity_search_uri_autocomplete').attr('data-coord', coords).val(coords);
    JKisio('#proximity_search_uri_autocomplete-hidden').val(inverted_coords);
    JKisio('.autocomplete-clear, .autocomplete-clear i').show();

    if (!JKisio('#proximity-results').is(':visible')) {
        JKisio('#proximity-results').show();
    }
    if (!JKisio('#div_show_poi_list').is(':visible')) {
        JKisio('#div_show_poi_list').show();
    }
    showResultsOnMap(result.data.result_map, true);
    drawCircle(
        JKisio('#proximity_search_coords').val(),
        JKisio('#proximity-search-time-input input:checked').val()
    );
}