import { parameters } from 'pnp_core_bundle/plugnplay/leaf-core';
import { initializeMap } from 'pnp_core_bundle/modules/map';
import { createStopPointPopUp } from './managePopUp'

/**
 * Draw on Map the Stop Points
 * @param stops
 */
export function drawStopPoints(stops, showStops) {
    let icon;
    let iconDefaultURL;
    const icon_default_config = {
        iconSize: 16,
        iconAnchor: [4, 30],
        shadowSize: 37,
        shadowAnchor: [15, 35],
        popupAnchor: [4, -25],
        className: 'proximity-poi-icon'
    };

    let _this = this;
    let iconURL = parameters.urlImages + 'pin-proximity.svg';
    let title = Translator.trans('proximity.map.pointType.stop_point');
    for (const stop of stops) {
        if (stop['stop_point'] !== undefined && stop['stop_point']['coord'] !== undefined && Object.keys(stop['stop_point']['coord']).length > 1) {
            let object_coord = stop['stop_point']['coord'];
            // Test if mode TC is defined
            if (stop['stop_point']['commercial_modes'] !== undefined) {
                let mode = stop['stop_point']['commercial_modes'][0]['name'].toLowerCase();
                let iconModeURL = Kisio.modes_img[mode];
                if (typeof Kisio.modes_img[mode] === 'undefined') {
                    iconModeURL = Kisio.modes_img['commercial_mode:' + mode];
                }
                if (typeof iconModeURL !== "undefined") {
                    iconURL = parameters.urlImages + 'pin-transport.svg';
                    icon = L.icon({
                        iconUrl: iconModeURL,
                        shadowUrl: iconURL,
                        ...icon_default_config
                    });
                } else {
                    // In case we don't have icon URL, we define a default icon
                    iconDefaultURL = parameters.urlImages + 'default-stop.svg';
                    icon = L.icon({
                        iconUrl: iconDefaultURL,
                        shadowUrl: iconURL,
                        ...icon_default_config
                    });
                }
            } else {
                // In case we don't have mode TC
                iconDefaultURL = parameters.urlImages + 'default-stop.svg';
                icon = L.icon({
                    iconUrl: iconDefaultURL,
                    shadowUrl: iconURL,
                    ...icon_default_config
                });
            }
            let markerStop = L.marker([object_coord.lat, object_coord.lon], {
                icon: icon,
                title: title + stop.name,
                keyboard: false
            });
            JKisio(markerStop).data('stop-object', stop);
            Kisio.stopPointGroupLayer.addLayer(markerStop);
            markerStop.on('click', function () {
                createStopPointPopUp(this);
            })
        }
    }

    window.leaflet = window.leaflet || {};

    if(!window.leaflet.map){
        window.leaflet.map = initializeMap(parameters);
        window.boardWidth = (JKisio('#board').length > 0) ? JKisio('#board').outerWidth() + JKisio('#board').offset().left : 0;
    }

    window.leaflet.map._layersMaxZoom = parameters.max_zoom;
    Kisio.stopPointGroupLayer.addTo(window.leaflet.map);
    if (showStops === 'false') {
        JKisio('#proximity-show-hide-stops button').trigger('click');
    }
    window.leaflet.map.on('popupopen', function (e) {
        let popupHtml = e.popup._content;
        if (popupHtml.indexOf('nocontent') !== -1) {
            let tip = JKisio(e.popup._tip);
            tip.css('background-color', '#f0f0f0');
        }
    });
};