export function removeQueryParamsFromUrl(queryParam = null) {
    if (!queryParam) {
        return;
    }

    const queryString = decodeURIComponent(window.location.search);
    const uri_object = queryStringToObject(queryString);
    delete uri_object[queryParam];

    const newState = (history.state || 0) + 1;
    const route = Routing.generate(
        'schedule_home',
        { type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : '' }) + '?' + JKisio.param(uri_object);

    history.pushState(newState, 'Pushed: ' + newState, route);
}

export function queryStringToObject(querystring) {
    const params = new URLSearchParams(querystring);
    const obj = {};

    for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
            obj[key] = params.getAll(key);
        } else {
            obj[key] = params.get(key);
        }
    }

    return obj;
};
