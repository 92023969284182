/**
 * Classe Model de recherche et d'affichage des lignes/stop_area passant par un arret/line
 */
import {
    setUrlDynamically,
    showPassingLine,
    addMarker,
    getDisruptions,
    showNextDepartures,
    showLinesByType,
    delegateEvents
} from '../display_search_schedule_result';
import {mapPopupConfig} from 'pnp_core_bundle/plugnplay/leaf-core';
import {isIE9, isIE10, isIE11} from 'pnp_core_bundle/plugnplay/browser';
import SimpleBar from 'pnp_core_bundle/simplebar/simplebar';
import {params} from 'pnp_core_bundle/structure/constants';
import {positionBoard, testSupportsSmoothScroll} from 'pnp_core_bundle/structure/board/board_dynamic_position';
import {enableFavorites, checkFavoritesOnPage} from 'pnp_core_bundle/plugnplay/favorites';
import {getLineGeoJsonObject, showStopPointsByLineId} from 'schedule_bundle/load_infos';
import {getModePicto, getLinePicto} from 'pnp_core_bundle/plugnplay/customize_client';
import {clearMap} from 'pnp_core_bundle/modules/map';
import {showAllLines} from '../show_all';
import { getStopScheduleDirectionFromLocation } from '../module/stop-schedule';
import { hasValidStopId } from '../utils';
import {drawLineOnMap} from '../module/line-schedule';
import { lineResults } from './line/line_index';
import { AutocompleteItems } from 'places_bundle/views/autocomplete.view';
import { getEachScheduleTimeByLine } from './line/schedule_time_by_line';
import DisruptionsModel from './disruptions';

export var modelDisplaySearchScheduleResults = Backbone.Model.extend({

    params: {
        scheduleDivId: '#schedule_stop_area_lines',
        containerId: '#ctp-linesSearchResultsContainer',
        ulScheduleId: '#ctp-searchScheduleResults',
        divScheduleId: '#ctp-searchSchedule',
        autocompleteId: '#schedule_stop_area_lines_autocomplete',
        autocompleteClear: '#schedule-stop-area-lines-autocomplete-clear',
        boardContainerId: '#board_container',
        stopScheduleBloc: '.kisio-stop-schedule',
        disruptionsArray: []
    },

    /**
     * Recherche et affichage des lignes pour un stop_area
     * @param listLinesrouteId      id de la liste des lignes
     * @param uri                   Uri de la stop area
     * @param label                 label de la stop area
     * @param scheduleSelected
     * @param directionType
     */
    updateList: function(listLinesrouteId, uri, label, scheduleSelected, directionType, stopType) {
        JKisio('#loading-box').css("visibility", "visible");
        setUrlDynamically(uri, label, scheduleSelected, directionType);

        let coords = JKisio('#schedule_stop_area_lines_autocomplete').attr('data-coord');
        addMarker(coords);

        if (typeof stopType === 'undefined') {
            stopType = 'stop_areas';
        }

        JKisio.ajax({
            url: Routing.generate(
                listLinesrouteId, {
                    type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : '',
                    public_transport_object_type: stopType,
                    public_transport_object_id: uri,
                    action: 'lines',
                    count: Kisio.max_lines
                }),
            async: true,
            context: this
        }).done(function (result) {
            if (typeof result.lines === 'undefined') {
                JKisio('#ctp-searchSchedule').append('<div id="no_results">' + Translator.trans('line_schedule.result.no_stop_times') + '</div>');
            } else {
                let self = this;
                if (typeof Kisio.schedule_map_config !== 'undefined' && Kisio.schedule_map_config.line_drawings && typeof result.lines !== 'undefined') {
                    // draw lines on the map
                    self.showLinesOnMap(result.lines);
                }

                // Tri par mode de transport
                let shedule_line = new Kisio.shedule_line_order();
                if (typeof result.lines !== 'undefined') {
                    JKisio.each(result.lines, function (index, line) {
                        let physicalMode = line.physical_modes[0].id;
                        if (typeof shedule_line[physicalMode] !== 'undefined') {
                            let m = shedule_line[physicalMode].length;
                            shedule_line[physicalMode][m++] = line;
                        }
                    });
                }
                JKisio.each(shedule_line, function (index, mode) {
                    if (mode.length === 0) {
                        delete shedule_line[index];
                    }
                });

                //Affichage des lignes passant par le stop area/stop point
                if (Kisio.filter_by_stop_point == '1') {
                    showPassingLine(shedule_line, uri);
                }

                if (JKisio(self.params.ulScheduleId).length > 0) {
                    JKisio(self.params.ulScheduleId).remove();
                }
                JKisio(self.params.containerId).html('');
                JKisio('#ctp-lineSchedule').show();
                JKisio(self.params.containerId).css('height', 'auto');
                JKisio(self.params.containerId).append('<ul id="ctp-searchScheduleResults"></ul>');
                let ul = JKisio(self.params.ulScheduleId);
                ul.html('');
                // Affichage des lignes par mode de transport
                self.renderLines(shedule_line, uri, ul, label, coords);
                if (!isIE9) {
                    try {
                        new SimpleBar(JKisio(self.params.ulScheduleId)[0]);
                    } catch (e) {
                        console.log(e);
                    }
                }
                let widthBody = JKisio('body').outerWidth(true);
                let newHeight = JKisio('body').outerHeight(true) - 200;
                if (widthBody < params.desktopBreakPoint) {
                    JKisio('#ctp-linesSearchResultsContainer').css({
                        'height': newHeight,
                        'scroll': 'auto'
                    })
                }

                self.updateDisplay(params, scheduleSelected);
                if (Kisio.show_schedule_by_line == 1) {
                    self.getScheduleTimeByLine();
                }

                // Show clear buttons on result page
                if (JKisio(self.params.ulScheduleId).length > 0 && JKisio(self.params.autocompleteId).val() !== '') {
                    JKisio(self.params.autocompleteClear).css('display', 'inline');
                }
                if (JKisio('#myPopup').length > 0) {
                    JKisio('#myPopup').remove();
                }
                if (JKisio(self.params.autocompleteClear).length > 0) {
                    JKisio(self.params.autocompleteClear).click(function () {
                        self.newSearch();
                        if (JKisio('#myPopup').length > 0) {
                            JKisio('#myPopup').remove();
                        }
                        JKisio('#schedule_lines_content, #schedule_lines').show();
                    });
                }

                if (window.Kisio.show_one_line_by_id) {
                    let line = new lineResults();
                    line.manageLineEvents(uri);
                }
            }
            JKisio('#loading-box').css("visibility", "hidden");
        });
    },

    /**
     *
     * @param stop_area_id
     */
    getCoordsByStopArea: function (stop_area_id) {
        let coords = '';
        JKisio.ajax({
            url: Routing.generate(
                'r_public_transport_objects_filtered', {
                    type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                    public_transport_object_type: 'stop_areas',
                    public_transport_object_id: stop_area_id
                }) + '/',
            async: false,
            context: this
        }).done(function (result) {
            if (typeof result.stop_areas !== 'undefined') {
                coords = result.stop_areas[0].coord.lat + ';' + result.stop_areas[0].coord.lon;
            }
        });
        return coords;
    },

    /**
     *
     * @param lines
     */
    showLinesOnMap: function (lines) {
        if(!window.leaflet){
            return;
        }
        // clear layers
        window.leaflet.map.eachLayer(function (layer) {
            if (!('_url' in layer)) window.leaflet.map.removeLayer(layer);
        });
        // draw lines on the map
        let groupLines = L.featureGroup();
        JKisio.each(lines, function (index, line) {
            let groupLine = getLineGeoJsonObject(line, false, false, .5);
            if (typeof groupLine !== 'undefined') groupLine.addTo(groupLines);
        });
        groupLines.addTo(window.leaflet.map);
        if (groupLines.getLayers().length !== 0 && Object.keys(groupLines.getBounds()).length !== 0) {
            window.leaflet.map.fitBounds(groupLines.getBounds(), mapPopupConfig(window.boardWidth));
        }
    },

    /**
     *
     * @param shedule_line
     * @param uri
     * @param ul
     * @param label
     * @param coords
     */
    renderLines: function (shedule_line, uri, ul, label, coords) {
        let _this = this;
        JKisio.each(shedule_line, function (physicalMode, linesByMode) {
            let physicalModeDiv = JKisio('<div />')
                .addClass('schedule-physical-mode')
                .append(Translator.trans(physicalMode.toLowerCase()).toUpperCase());
            physicalModeDiv.appendTo(ul);
            JKisio.each(linesByMode, function (index, line) {
                let item = {
                    line: line,
                    type: 'line',
                    value: line.name,
                    label: line.name,
                    icon_mode_type: uri,
                    id: line.id,
                    entryPoint: {},
                    coord: line.coord,
                    stop_area_label: label,
                    stop_area_coords: coords
                };

                addMarker(coords);

                item.position = _this.getSectionPos(index, linesByMode.length);
                item = new AutocompleteItems({
                    ul: ul,
                    item: item,
                    showMatcher: false
                });
                item.setItem('schedule_line_list');
            });
        });
    },

    /**
     * Function to launch the subfunction 'getEachScheduleTimeByLine'
     */
    getScheduleTimeByLine: function () {
        const stop_id_type = JKisio('#schedule_stop_area_lines_autocomplete-hidden').val();
        const stop_id = hasValidStopId(stop_id_type) ? stop_id_type : null;
        let menu_item = JKisio('li.ui-menu-item a');
        let count = menu_item.length;
        let items_processed = 0;
        // Initiate variables for favorites management
        let isIE = false;
        let resultEnableFavorites = enableFavorites('stop_area_lines_direction');
        let enable_favorites = resultEnableFavorites[0];
        let favorites_manager = resultEnableFavorites[1];
        if (isIE9 || isIE10 || isIE11) {
            isIE = true;
        }
        // In case of IE browsers NOT supporting JS Promises we use a counter to wait for all schedule times before checking favorites
        if (isIE) {
            JKisio.each(menu_item, function (index, item) {
                getEachScheduleTimeByLine(undefined, item, stop_id, isIE, enable_favorites, favorites_manager);
                items_processed++;
                if (items_processed === count) {
                    // IE Case: last item's times appended --> we can work on the favorites
                    if (enable_favorites && favorites_manager !== undefined) {
                        checkFavoritesOnPage(favorites_manager);
                    }
                }
            });
        } else {
            // Modern browser, we can use JS Promises to wait on all asymc calls to check favorites
            let menu_item_array = [];
            for (let i = 0; i < count; i++) {
                menu_item_array.push(menu_item[i]);
            }
            let requests = menu_item_array.map(function (item) {
                return new Promise(function (resolve) {
                    getEachScheduleTimeByLine(resolve, item, stop_id, isIE, enable_favorites, favorites_manager);
                });
            });
            Promise.all(requests).then(function () {
                // Modern browser Case: last item's times appended --> we can work on the favorites
                if (enable_favorites && favorites_manager !== undefined) {
                    checkFavoritesOnPage(favorites_manager);
                }
            });
        }
    },

    /**
     * Function to fetch Next Departures for Times Favorites on Favorites Page
     * @param item
     * @param stop_area_id
     * @param line_id
     * @param network_id
     */
    getScheduleTimesForFavorites: function (item, stop_area_id, line_id, network_id, direction_type) {
        let query = {
            type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
            stop_area_id: stop_area_id,
            line_id: line_id,
            network_id: network_id,
            from_datetime: 'now',
            data_freshness: 'realtime'
        };

        JKisio.ajax({
            url: Routing.generate('schedule_next_time', query),
            success: function (data) {
                let htmlLine = '';
                let imgPicto = '';
                if (data.line_informations.commercial_mode !== undefined) {
                    imgPicto = getModePicto(data.line_informations.commercial_mode);
                }
                htmlLine += imgPicto;
                let spanLineCode = '';
                if (data.line_informations.color !== undefined && data.line_informations.text_color !== undefined && data.line_informations.code !== undefined) {
                    spanLineCode = '<span class="line-code" style="background-color: #' + data.line_informations.color + '; color: #' + data.line_informations.text_color + '">' + data.line_informations.code + '</span>'
                } else {
                    spanLineCode = '<span class="line-code">—</span>';
                }
                htmlLine += spanLineCode;
                let schedule_direction_type = [];
                for (let direction in data.direction_type) {
                    let key = data.direction_type[direction];
                    let value = direction;
                    schedule_direction_type[key] = value;
                }
                let direction_name = '';
                if (schedule_direction_type[direction_type] !== undefined) {
                    direction_name = schedule_direction_type[direction_type];
                }
                let time = '';
                if (data.schedule[direction_name] !== undefined) {
                    let htmlTimes = data.schedule[direction_name];
                    time = htmlTimes[0];
                    if (time === 'no_departure_this_day') {
                        time = Translator.trans('stop_schedules.' + time);
                    }
                    if (time === 'active_disruption') {
                        time = Translator.trans('schedule.result.disruptions.multiple');
                    }
                }
                let directionHtml = '';
                if (direction_name !== '') {
                    directionHtml = Translator.trans('journey.section.street_network.walking.title') + ' ' + direction_name;
                } 
                JKisio(item).find('.line-name').html('').html(htmlLine);
                JKisio(item).find('.times-container .line-direction').html('').html(directionHtml);
                JKisio(item).find('.times-container .times').html('').html(time);
            },
            error: function (data) {
                console.log(data);
            }
        });
    },

    /**
     * Mise en page d'un element
     * @param {domelement} ul Noeud parent de l'element à afficher
     * @param {object} item Element à mettre en page
     * @return {domelement} Noeud parent dans lequel on à ajouté l'element
     */
    renderStopAreaItem: function (ul, item) {
        let element = '';
        let name = '<div class="title">' + item.name + '</div>';

        let iconModeType = item.physical_modes[0].id.split(":")[1].toLowerCase();
        let type = getModePicto(iconModeType);
        if (item.code) {
            element = getLinePicto(item, 'undefined', ' ');
        }
        let networkSpan = JKisio('<span class="schedule-line-network" />');
        let networkName = Translator.trans('places.autocomplete.title.network') + ' : ' + item.network.name;
        networkSpan.append('<p>' + networkName.toUpperCase() + '</p>');

        let lineMode = JKisio('<div />')
            .addClass('schedule-line-mode')
            .append(type)
            .append(element);

        let lineHeader = JKisio('<div />')
            .addClass('schedule-line').attr('tabindex', 0)
            .append(lineMode)
            .append(networkSpan);

        let link = JKisio('<div />')
            .append(lineHeader)
            .append(name);

        let div = JKisio('<div />')
            .append(link);


        let li = JKisio('<li />');
        div.appendTo(li);

        return li.appendTo(ul);
    },

    /**
     * Redimensionnement du tableau de bord et de la liste
     * @param params
     * @param scheduleSelected
     */
    updateDisplay: function (params, scheduleSelected) {
        let widthBody = this.getWidthOfElement('body');
        let topBoard = this.getPositionOfElement(params.boardContainerId);
        let positionBoardInstance = new positionBoard();
        positionBoardInstance.calculate(topBoard, widthBody, params.desktopBreakPoint, 'init', undefined, scheduleSelected);
    },

    getPositionOfElement: function (elementId) {
        let topBoard;
        let boardElement = JKisio(elementId);
        if (JKisio(boardElement).length > 0)
            topBoard = parseInt(boardElement.css('top').replace(/[^-\d\.]/g, ''), 10);

        return topBoard;
    },

    /**
     * Calcul de la largeur d'un element
     * @param   id      Id de l'element
     * @return  int
     */
    getWidthOfElement: function (id) {
        return JKisio(id).outerWidth(true);
    },

    /**
     * Affichage des informations sur une ligne
     * @param route_name
     * @param id_line
     * @param label
     */
    displayLineInfos: function (route_name, id_line, label, scheduleSelected, directionType) {
        // Récupération des informations sur la ligne
        JKisio.ajax({
            url: Routing.generate(route_name, {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                public_transport_object_type: 'lines',
                public_transport_object_id: id_line,
                action: 'lines',
                count: 1
            }),
            context: this
        }).done(function (result) {
            if (!result.error) {
                if (typeof id_line !== "undefined") {
                    setUrlDynamically(id_line, label, scheduleSelected, directionType);
                }
                // init
                clearMap();
                JKisio('.disruption-line').remove();
                if (typeof Kisio.schedule_map_config !== 'undefined') {
                    let lineColor = (typeof result.lines[0] !== 'undefined' && result.lines[0].color !== 'undefined') ?
                        '#' + result.lines[0].color : '#000000';
                    if (lineColor === '#FFFFFF') {
                        lineColor = result.lines[0].text_color;
                    }
                    if (Kisio.schedule_map_config.line_drawings) drawLineOnMap(result, '1');
                    if (Kisio.schedule_map_config.stop_point_drawings && lineColor) showStopPointsByLineId(id_line, lineColor);
                }
                // display stop schedule bloc
                JKisio(this.params.stopScheduleBloc).show();
                // Cache l'autocomplete
                JKisio(this.params.scheduleDivId).hide();
                JKisio('#ctp-lineSchedule').show();
                JKisio('#schedule_lines_content, #schedule_lines').hide();

                // Clic sur nouvelle recherche
                let self = this;
                JKisio('#kisio-lineScheduleNew').click(function () {
                    self.newSearch();
                    if (JKisio('#show_all_lines_onload').val() === '1') {
                        JKisio('.schedule_section, .sections').remove();
                        JKisio.each(Kisio.networks, function (network_id, network_conf) {
                            showAllLines(network_id, network_conf);
                        });
                    }
                });

                // Saisie dans le champ de recherche
                JKisio('#ctp-lineSccheduleLineStopAreasSearchInput').keyup(this.gotoStopArea);

                if (JKisio('#ctp-searchScheduleResults').length > 0) {
                    JKisio('#ctp-searchScheduleResults').remove();
                }
                JKisio('#ctp-linesSearchResultsContainer').html('');
                JKisio('#ctp-linesSearchResultsContainer').append('<nav aria-label="' + Translator.trans('line_schedule.result.stops_list') + '"><ul id="ctp-searchScheduleResults" role="group"></ul></nav>');
                let ul = JKisio(self.params.ulScheduleId);
                if (!isIE9) {
                    try {
                        new SimpleBar(JKisio('#ctp-linesSearchResultsContainer')[0]);
                    } catch (e) {
                        console.log(e);
                    }
                }
                let lineSelected = this.renderStopAreaItem(ul, result.lines[0]);
                JKisio('.schedule-line').attr('id', id_line);
                JKisio('#ctp-lineScheduleLineInfos').html('').append(lineSelected).append('<div id="stop_area_hours"></div>');

                // Line disruptions
                getDisruptions(result.lines[0].disruptions);

                // Récupération des infos sur les stop areas
                this.getLineStopArea(route_name, id_line, label, scheduleSelected);
            } else {
                console.log(result.error.message);
            }
            JKisio('#loading-box').css("visibility", "hidden");
        });
    },

    /**
     *
     * @param route_name
     * @param id_line
     * @param label
     * @param scheduleSelected
     */
    getLineStopArea: function (route_name, id_line, label, scheduleSelected) {
        JKisio.ajax({
            url: Routing.generate(
                route_name, {
                    type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                    public_transport_object_type: 'lines',
                    public_transport_object_id: id_line,
                    action: 'stop_points',
                    count: Kisio.max_stop_areas
                }),
            async: true,
            context: this
        }).done(function (result) {
            let self = this;
            let disruptionsStopAreaArray = [];
            let disruptionsLineArray = [];
            if (result.disruptions) {
                result.disruptions.forEach(function (disruption) {
                    if (disruption.impacted_objects.length > 0 && disruption.impacted_objects[0].pt_object.embedded_type === 'stop_area') {
                        let stop_area_id = disruption.impacted_objects[0].pt_object.id;
                        if (!disruptionsStopAreaArray[stop_area_id]) {
                            disruptionsStopAreaArray[stop_area_id] = [disruption];
                        } else {
                            disruptionsStopAreaArray[stop_area_id].push(disruption);
                        }
                    }
                    if (disruption.impacted_objects.length > 0 && disruption.impacted_objects[0].pt_object.embedded_type === 'line') {
                        let stop_area_id = disruption.impacted_objects[0].pt_object.id;
                        if (!disruptionsLineArray[stop_area_id]) {
                            disruptionsLineArray[stop_area_id] = [disruption];
                        } else {
                            disruptionsLineArray[stop_area_id].push(disruption);
                        }
                    }
                })
            }
            let stop_points = self.removeDuplicateStopPoint(result.stop_points);
            // Tri les stop areas
            stop_points.sort(Intl.Collator().compare);

            // Affiche les stop areas
            let ul = JKisio(self.params.ulScheduleId);
            ul.html('');
            let containerHeightOrigin = JKisio('#ctp-searchSchedule').outerHeight(true);
            JKisio('#ctp-searchSchedule').attr('data-id-line', id_line);
            self.renderStopAreas(ul, stop_points);
            let ulHeight = ul.outerHeight(true);

            //Affiche les détails d'un stop area
            self.getStopArea(self.params.disruptionsArray, label);

            if (containerHeightOrigin < ulHeight) {
                // Redimensionneemnt de la div parente de la liste
                JKisio('#ctp-searchSchedule').outerHeight(containerHeightOrigin + ulHeight);
            }
            this.updateDisplay(params, scheduleSelected);
        });
    },

    /**
     * Click sur un stop area renvoie vers la page de résultat
     * @param disruptions
     * @param label
     */
    getStopArea: function (disruptions, label) {
        JKisio('#ctp-linesSearchResultsContainer li').on('keyup', function (e) {
            if (e.keyCode === 13) {
                JKisio(this).trigger('click');
            }
        });
        JKisio('#ctp-linesSearchResultsContainer li').on('click', function (e) {
            JKisio('#loading-box').css("visibility", "visible");
            let self = this;
            let object_id = JKisio('.schedule-lines .lines span').attr('data-stop-area');
            if (typeof object_id === 'undefined') {
                object_id = JKisio(self).attr('id');
            }

            let id_line = JKisio('#ctp-searchSchedule').attr('data-id-line');
            if (typeof id_line === 'undefined') {
                id_line = JKisio(self).attr('id');
            }
            if(!window.leaflet){
                return;
            }
            setTimeout(function () {
                let layerGroup = L.layerGroup().addTo(window.leaflet.map);

                layerGroup.clearLayers();
                let labelStopArea = JKisio(self).attr('data-content');

                let object_coord = decodeURI(JKisio(self).attr('data-coord'));
                let stop_point_id = decodeURI(JKisio(self).attr('data-stop_point_id'));

                if (JKisio('#next_departures_results').length > 0) {
                    JKisio('#next_departures_results').remove();
                }
                JKisio('#ctp-searchSchedule').hide();
                JKisio.ajax({
                    url: Routing.generate('schedule_next_time_board', {type_product: (window.Kisio.type_product !== 'undefined') ? window.Kisio.type_product : ''}),
                    async: false,
                    success: function (data) {
                        JKisio('#schedule_content').append(data);
                        JKisio('.result-back-button a').addClass('line');
                        JKisio('#stop_area_label').text(labelStopArea)
                            .attr('data-coord', object_coord)
                            .attr('data-stop-area', object_id)
                            .attr('data-stop-point', stop_point_id)
                            .attr('data-line-id', id_line)
                            .attr('aria-label', Translator.trans('schedule.result.title.next_departures') + ' ' + labelStopArea)
                            .attr('tabindex', 0);
                        JKisio('.schedule-datetime-header .prev,.schedule-datetime-header .next').attr('data-content', labelStopArea).attr('data-coord', object_coord).attr('id', id_line);
                    },
                    error: function (xhr, status, error) {
                        console.log(error);
                    }
                });

                const direction = getStopScheduleDirectionFromLocation();
                showNextDepartures(object_id, id_line, false, null, direction);

                let target = JKisio(e.target).prev('span').find('img');
                if (target.hasClass('schedule-disruption')) {
                    let stop_area_id = JKisio(self).closest('li').attr('id');
                    let disruptionByStopArea = disruptions[stop_area_id];
                    getDisruptions(disruptionByStopArea);
                }
                JKisio('#stop_area_label').attr('data-coord', object_coord);
                showLinesByType(object_id, 'stop_areas', id_line);
                addMarker(object_coord);

                JKisio('#loading-box').css("visibility", "hidden");
                delegateEvents();
                setUrlDynamically(id_line, label, object_id, direction);
            }, 150);

        });
    },

    renderStopAreas: function (ul, stop_points) {
        let previous = '';
        let _this = this;
        JKisio.each(stop_points, function (index, stop_point) {
            let stop_area_id = stop_point.stop_area.id;
            let letter = stop_point.label.substr(0, 1);
            if (letter !== previous) {
                previous = letter;
            } else {
                letter = '&nbsp;';
            }
            if (stop_point.disruptions || _this.params.disruptionsArray[stop_area_id]) {
                if (!_this.params.disruptionsArray[stop_area_id]) {
                    _this.params.disruptionsArray[stop_area_id] = stop_point.disruptions;
                } else if (stop_point.disruptions) {
                    stop_point.disruptions.forEach(function (disruption) {
                        _this.params.disruptionsArray[stop_area_id].push(disruption);
                    });
                }

                let model = new DisruptionsModel();
                let icon = model.renderIcon({
                    'disruptions': _this.params.disruptionsArray[stop_area_id]
                });
                letter += icon;
            }
            if (stop_point.disruptions && stop_point.disruptions.status === 'active' && stop_point.disruptions.severity) {
                letter += '<i class="ikisio ikisio-' + stop_point.disruptions.severity.level + '"></i>'
            }
            let li = JKisio('<li />')
                .attr('id', stop_point.stop_area.id)
                .attr('data-stop_point_id', stop_point.id)
                .attr('data-coord', stop_point.stop_area.coord.lat + ';' + stop_point.stop_area.coord.lon)
                .attr('data-content', stop_point.label)
                .attr('tabindex', 0)
                .attr("role", "button");

            li.append('<span class="letter">' + letter + '</span>');
            li.append(JKisio('<span />').addClass('label').append(stop_point.label));
            ul.append(li);
        });
    },

    removeDuplicateStopPoint: function (object) {
        let stop_points = [],
            last_stop_area_id = '',
            stop_arrea_id = '';
        for (const element of object) {

            //Filter by stop area id
            if (Kisio.filter_by_stop_point === 1) {
                stop_arrea_id = element.stop_area.id;
            } else {
                //Filter by stop point id
                stop_arrea_id = element.id;
            }
            if (last_stop_area_id !== stop_arrea_id) {
                stop_points.push(element);
                last_stop_area_id = stop_arrea_id;
            } else if (last_stop_area_id === stop_arrea_id && element.disruptions) {
                let key = stop_points.length - 1;
                if (stop_points[key]['disruptions']) {
                    if (stop_points[key]['disruptions'].id !== element.disruptions.id) {
                        stop_points[key]['disruptions'].push(element.disruptions);
                    }
                } else {
                    stop_points[key]['disruptions'] = element.disruptions;
                }
            }
        }
        return stop_points;
    },

    /**
     * Fonction appelée lors du clic sur "Nouvelle recherche"
     */
    newSearch: function () {
        JKisio(this.params.scheduleDivId).show();
        JKisio('#ctp-lineSchedule' + ', ' + this.params.autocompleteClear + ', ' + this.params.stopScheduleBloc).hide();
        JKisio(this.params.ulScheduleId + ', #ctp-lineScheduleLineInfos' + ', #ctp-linesSearchResultsContainer').html('');
        JKisio('#ctp-linesSearchResultsContainer').removeAttr('style');
        JKisio(this.params.boardContainerId).animate({'top': 130}, "fast", "swing");
        JKisio(this.params.autocompleteId + ', ' + this.params.autocompleteId + '-hidden').val('');
        if(!window.leaflet){
            return;
        }
        window.leaflet.map.eachLayer(function (layer) {
            if (!('_url' in layer)) window.leaflet.map.removeLayer(layer);
        });
        // remove query string from url
        let href = window.location.href;
        let url = href.split('/?');
        history.pushState(null, null, url[0]);
    },

    /**
     * Fonction appelée lors de la saisie dans le champ de recherche des stop area
     * @param e
     */
    gotoStopArea: function (e) {
        let stop_areas = JKisio('#ctp-searchScheduleResults li span.label');
        stop_areas.parent().removeClass('selected');
        JKisio.each(stop_areas, function (index, stop_area) {
            if (stop_area.textContent.toLowerCase().search(e.currentTarget.value.toLowerCase()) >= 0) {
                let elem = JKisio(stop_area);
                let height = elem.parent()[0].offsetTop;
                elem.parent().addClass('selected');
                let simpleBar;
                if (!isIE9) {
                    simpleBar = new SimpleBar(JKisio('#ctp-linesSearchResultsContainer')[0]);
                } else {
                    simpleBar = JKisio('#ctp-linesSearchResultsContainer')[0];
                }
                if (testSupportsSmoothScroll()) {
                    simpleBar.getScrollElement().scrollTo(0, height);
                } else {
                    simpleBar.getScrollElement().scrollLeft = 0;
                    simpleBar.getScrollElement().scrollTop = height;
                }
                return false;
            }
        });
    },

    /**
     * Function to know the position of the section
     * @param {Number} index
     * @param {Number} lenght
     * @returns {String}
     */
    getSectionPos: function (index, lenght) {
        let sectionPos = {};
        if (index === 0) {
            sectionPos.libelle = 'first';
            if (index === lenght - 1) {
                sectionPos.libelle = 'last';
            }
        } else if (index === lenght - 1) {
            sectionPos.libelle = 'last';
        } else {
            sectionPos.libelle = 'other';
        }
        sectionPos.index = index;
        return sectionPos;
    },

    reloadNextDepartures: function() {

        setTimeout(function(){
            let stop_area_id = JKisio('#next_departures_results .lines span').attr('data-stop-area');
            if (typeof stop_area_id === 'undefined') {
                stop_area_id = JKisio('#next_departures_results .selected_line span').attr('data-stop-area');
            }
            let line_id = JKisio('.schedule-lines').attr('id');
            JKisio('#button_action, .notesContainer, #next_departures_table_front, #next_departures_table_back').remove();
            JKisio('.next-departures-result').html('<img src="/bundles/canaltppnpcore/images/loading.gif">');
            showNextDepartures(stop_area_id, line_id);
            JKisio('.next-departures-result').html('');
        }, Kisio.schedule_reload_time);
    }
});