import { proximity_const } from './constants';
import { updateURLHistory } from '../core/utils';
import 'pnp_core_bundle/plugnplay/leaf-core';

export function addEventListeners() {
    showHideStopsListener();
}

function showHideStopsListener() {
    JKisio(document).on('click', proximity_const.show_hide_stops, function (e) {

        e.preventDefault();
        const queryString = decodeURIComponent(window.location.search);
        const regex = /(proximity_search\[show_stops\]=)([a-z]+)/;
        let showStops = '';
        if (JKisio(this).hasClass('hideStops')) {
            if(window.leaflet.map.hasLayer(Kisio.stopPointGroupLayer)) {
                window.leaflet.map.removeLayer(Kisio.stopPointGroupLayer);
            }

            JKisio(this).removeClass(proximity_const.hide_stops_class).addClass(proximity_const.show_stops_class);
            JKisio(this).html(Translator.trans('proximity.map.showStopsOnMap'));

            showStops = 'false';
        } else if (JKisio(this).hasClass('showStops')) {
            if(!window.leaflet.map.hasLayer(Kisio.stopPointGroupLayer)) {
                window.leaflet.map.addLayer(Kisio.stopPointGroupLayer);
            }

            JKisio(this).removeClass(proximity_const.show_stops_class).addClass(proximity_const.hide_stops_class);
            JKisio(this).html(Translator.trans('proximity.map.hideStopsOnMap'));

            showStops = 'true';
        }
        
        const route = Routing.generate('proximity_form_result', { type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : '' })
            + queryString.replace(regex, "$1"+showStops);

        updateURLHistory(route);
    });
}
