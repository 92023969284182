import { determineFavManager, checkFavoritesOnPage } from 'pnp_core_bundle/plugnplay/favorites';
import { parameters} from 'pnp_core_bundle/plugnplay/leaf-core';
import { getURLToSchedule } from './manageUrl'
import { isIE9 } from 'pnp_core_bundle/plugnplay/browser';
import SimpleBar from 'pnp_core_bundle/simplebar/simplebar';
import { reloadPositionBoard } from './reloadPositionBoard'

/**
 * Add list of POI && Stops Addresses
 * @param {object} result 
 * @param {string} type 
 */
    export function addAddresses(result, type) {
    if (result.html !== undefined) {
        if (type === 'launch') {
            JKisio('#proximity-results').html('').show().append(result.html).promise().done(function () {
                let favoritesManager = determineFavManager();
                if (Kisio.enable_favorites && favoritesManager !== undefined) {
                    checkFavoritesOnPage(favoritesManager, '#poi_list ');
                }
                createHideShowButtonStops();
            });
        }
        if (type === 'update') {
            let regex = /(<div id="poi_list">[\s\S]*<\/div>)/gm;
            let m;
            let newHTML;
            while ((m = regex.exec(result.html)) !== null) {
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }
                newHTML = m[0];
            }
            JKisio('.poi_list').empty().html(newHTML).promise().done(function () {
                let favoritesManager = determineFavManager();
                if (Kisio.enable_favorites && favoritesManager !== undefined) {
                    checkFavoritesOnPage(favoritesManager, '#poi_list ');
                }
                createHideShowButtonStops();
            });
        }
    }
    if (result.data !== undefined && result.data.result_list !== undefined &&
        result.data.result_list['proximity.form.poi.group.stop'] !== undefined &&
        result.data.result_list['proximity.form.poi.group.stop'].response !== undefined && result.data.result_list['proximity.form.poi.group.stop'].response[0] !== undefined &&
        result.data.result_list['proximity.form.poi.group.stop'].response[0].places_nearby !== undefined) {

        let type_product = (Kisio.type_product !== undefined) ? Kisio.type_product : 'standalone';
        let stops = result.data.result_list['proximity.form.poi.group.stop'].response[0].places_nearby;
        let stopsHTML = '<div id="display_stops_addresses"><div>' +
            '<ul class="ctp-list-point"><fieldset>' +
            '<legend tabindex="0" aria-expanded="false" aria-controls="section-stops-content-id">' +
            '<i class="arrow-ikisio ikisio ikisio-arrow-drop-up"></i>' +
            '<img src="' + (parameters.urlImages + 'stop.png') + '" title="' + Translator.trans('proximity.data.type.stop_point') + '" alt="' + Translator.trans('proximity.data.type.stop_point') + '" />' +
            '<span class="ctp-libelle-picto">' + Translator.trans('proximity.data.type.stop_area') + ' (' + stops.length + ')</span>' +
            '</legend><ul id="section-stops-content-id" class="section-poi-content">';
        for (const stop of stops) {
            let id = stop.id;
            let name = stop.name;
            let buttonsTravel = '';
            let scheduleLinkButton = '';
            let latlng;
            if (type_product !== 'standalone') {
                latlng = stop.stop_area.coord.lat + ';' + stop.stop_area.coord.lon;
                buttonsTravel = '<button class="prox-departure-btn ikisio ikisio-departure" data-type="from" data-html="true" aria-label="' + Translator.trans('popup.links.from.prox_aria_label_stop') + name + '" data-title="' + Translator.trans('popup.links.from.title') + '" data-address="' + name + '" data-coord="' + latlng + '" data-autocompleteHidden="' + id + '"></button>' +
                    '<button class="prox-arrival-btn ikisio ikisio-arrival" data-type="to" data-html="true" aria-label="' + Translator.trans('popup.links.to.prox_aria_label_stop') + name + '" data-title="' + Translator.trans('popup.links.to.title') + '" data-address="' + name + '" data-coord="' + latlng + '" data-autocompleteHidden="' + id + '"></button>';
                if (stop.stop_area !== undefined && stop.stop_area.label !== undefined) {
                    let href = getURLToSchedule(stop.stop_area.label, id);
                    scheduleLinkButton = '<div><a class="secondary-button" href="' + href + '">' + Translator.trans('proximity.result.links.see_board') + '<i class="ikisio ikisio-right"></i></a></div>';
                }
            }
            buttonsTravel += '<button class="prox-recenter-btn ikisio ikisio-recenter" data-type="recenter" data-html="true" aria-label="' + Translator.trans('popup.links.recenter.prox_aria_label_stop') + name + '" data-title="' + Translator.trans('popup.links.recenter.title') + '" data-address="' + name + '" data-coord="' + latlng + '" data-autocompleteHidden="' + id + '"></button>';
            let modesHTML = '';
            if (stop.stop_area.commercial_modes !== undefined && stop.stop_area.commercial_modes.length > 0) {
                let modes = stop.stop_area.commercial_modes;
                for (const elMode of modes) {
                    let mode = (elMode.id).replace('commercial_mode:', '').toLowerCase();
                    let modeName = Translator.trans(elMode.id.toLowerCase(), {}, 'modes');
                    modesHTML += '<i class="ikisio ikisio-' + mode + '" title="' + modeName + '" aria-hidden="true"></i><span class="sr-only">' + modeName + '</span>';
                }
            }
            stopsHTML += '<li id="ctp-proxi-' + id + '" class="clearfix ctp-stop-address">' +
                '<h4 tabindex="0">' + modesHTML + name + ' / ' + Translator.trans('proximity.data.distance.meter', {"meter": stop.distance }) +'</h4>'+
                            '<div class="buttons_travel">'+buttonsTravel+'</div>'+
                            scheduleLinkButton +
                        '</li>';
        }
        stopsHTML += '</ul></fieldset></ul></div></div>';
        if (JKisio('#display_stops_addresses').length > 0) {
            JKisio('#display_stops_addresses').remove();
            JKisio('#poi_list').append(stopsHTML);
        } else {
            JKisio('#poi_list').append(stopsHTML);
        }
    }
};

/**
 * Creation of button to Hide / Show the Stops on the map
 */
 export function createHideShowButtonStops(){
    if (JKisio('#proximity-show-hide-stops').length === 0) {
        JKisio('#ctp-searchProximity').append('<div id="proximity-show-hide-stops"><button class="hideStops" tabindex="-1">' + Translator.trans('proximity.map.hideStopsOnMap') + '</button></div>');
        if (!isIE9) {
            if (window.proximitySimpleBar === undefined) {
                window.proximitySimpleBar = new SimpleBar(JKisio('#proximity-results')[0]);
            }
            window.proximitySimpleBar.recalculate();
        }
        reloadPositionBoard();
    } else {
        if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
            JKisio('#proximity-show-hide-stops button').removeClass('showStops').addClass('hideStops');
            JKisio('#proximity-show-hide-stops button').html(Translator.trans('proximity.map.hideStopsOnMap'));
        }
    }
};

