import { time_table_consts } from "../core/constants";

export function downloadTimeTableButton() {
    const buttonDownload = document.createElement('a');
    const linkText = Translator.trans('timetables.download');

    buttonDownload.className = time_table_consts.download_class;
    buttonDownload.href = '#';
    buttonDownload.title = linkText;
    buttonDownload.innerText = linkText;

    return buttonDownload;
}
