import { manageJourneySearchModes } from './journey/board_journey_search';
import { positionBoard } from './board_dynamic_position';
import { showAllLines } from 'schedule_bundle/show_all';
import { clearMarker, clearMap, reverseMarker } from '../../modules/map';
import { initAutocompleteInputs } from 'places_bundle/models/autocomplete';
import { params } from '../constants';

Kisio.loadBoardEvents = Kisio.loadBoardEvents || {};
Kisio.loadBoardEvents.models = Kisio.loadBoardEvents.models || {};

Kisio.loadBoardEvents.models.libModels = Backbone.Model.extend({

    /**
     * this function return css top value
     * @param elementId
     * @returns {number}
     */
    getPositionOfElement: function (elementId) {
        let topBoard;
        let boardElement = JKisio(elementId);
        if (JKisio(boardElement).length > 0)
            topBoard = parseInt(boardElement.css('top').replace(/[^-\d\.]/g, ''), 10);

        return topBoard;
    },

    /**
     * this function allows to display content selected
     * @param elementId
     */
    showContentSelected: function (elementId) {
        let attr = 'content-selected';
        let contentSelected = JKisio(elementId).data(attr);
        JKisio('#'+contentSelected+'_content').show();
    },

    /**
     * this function display content selected
     * @param params
     * @param topBoard
     * @param widthBody
     */
    manageDisplayBoardContent: function (params, topBoard, widthBody) {
        self = this;
        let boardContentElements = JKisio(params.boardContentId).children();
        let boardTitleElements = JKisio(params.boardTitleId);
        if (boardContentElements.length > 0 && boardTitleElements.length > 0){
            let journeySearchModes = new manageJourneySearchModes();
            let positionBoardInstance = new positionBoard();
            journeySearchModes.initialize(widthBody, topBoard, params.desktopBreakPoint, false);
            positionBoardInstance.calculate(topBoard, widthBody, params.desktopBreakPoint, 'init');
            let tabs = document.querySelectorAll('.board_title');
            let tablist = document.querySelectorAll('#board_title_container');
            let tabFocus = 0;
            JKisio(tablist).on('keydown', function (e) {
                // Move right
                if (e.keyCode === 39 || e.keyCode === 37) {
                    tabs[tabFocus].setAttribute("tabindex", -1);
                    if (e.keyCode === 39) {
                        tabFocus++;
                        // If we're at the end, go to the start
                        if (tabFocus >= tabs.length) {
                            tabFocus = 0;
                        }
                        // Move left
                    } else if (e.keyCode === 37) {
                        tabFocus--;
                        // If we're at the start, move to the end
                        if (tabFocus < 0) {
                            tabFocus = tabs.length - 1;
                        }
                    }
                    tabs[tabFocus].setAttribute("tabindex", 0);
                    tabs[tabFocus].focus();
                    JKisio(tabs).attr('aria-selected', 'false');
                    JKisio(tabs[tabFocus]).attr('aria-selected', 'true');
                    JKisio(tabs[tabFocus]).trigger('click');
                }
            });

            const journeyTitle = JKisio('#journey_title');
            journeyTitle?.click(function () {
                //hide all contents
                self.hideAllContents();

                // display current content
                journeyTitle.addClass('selected');
                const journeyHomeContent = JKisio('#journey_content');
                const journeyContent = journeyHomeContent.length ? journeyHomeContent : JKisio('#result_content');
                journeyContent?.show();

                // display last layers
                self.displayCachedLayers('journey');

                // hide lines when layers are present on the map
                self.showHideLayers('journey');
                // unfold now/settings when load another board content
                journeySearchModes.initialize(widthBody, topBoard, params.desktopBreakPoint, true);
                if (!JKisio(params.fromId).val() || !JKisio(params.toId).val()) {
                    JKisio(params.classContent).hide();
                }
                if (JKisio(params.classContent).length > 0 && JKisio(params.fromId).val() && JKisio(params.toId).val())
                    JKisio(params.classContent).show();
                // Modify section
                if ( JKisio(params.formModifyId).is(":visible")) {
                    JKisio(params.formModifyId).hide();
                }
                if (JKisio('#kisio-full-details').not('visible'))
                    positionBoardInstance.calculate(topBoard, widthBody, params.desktopBreakPoint);
            });

            const scheduleTitle = JKisio('#schedule_title');
            scheduleTitle?.click(function () {
                JKisio(params.boardContainerId).css({'top': topBoard + 'px'});
                //hide all contents
                self.hideAllContents();

                // display current content
                scheduleTitle.addClass('selected');
                JKisio('#schedule_content')?.show();

                // display last layers
                self.displayCachedLayers('schedule');

                // show lines when layers are present on the map
                self.showHideLayers('line');
                // unfold now/settings when load another board content
                journeySearchModes.initialize(widthBody, topBoard, params.desktopBreakPoint, 'disable');
                positionBoardInstance.calculate(topBoard, widthBody, params.desktopBreakPoint, 'init');

                let networks = Kisio.networks;
                if (Kisio.show_all_lines && JKisio('#mapid').is(':visible')) {
                    if (JKisio('#schedule_lines').is(':empty')) {
                        JKisio.each(networks, function (network_id, network_conf) {
                            showAllLines(network_id, network_conf);
                        });
                    }
                }
                if (Kisio.show_all_stop_areas && JKisio('#mapid').is(':visible')) {
                    JKisio.each(networks, function (network_id, network_conf) {
                        showAllStopAreas(network_id);
                    });
                }
                if (Kisio.show_all_stop_points && JKisio('#mapid').is(':visible')) {
                    JKisio.each(networks, function (network_id, network_conf) {
                        showStopPoints(network_id);
                    });
                }
            });

            const trafficTitle = JKisio('#traffic_title');
            trafficTitle?.click(function () {
                // dispatch event to display traffic section
                document.body.dispatchEvent(new CustomEvent('display_traffic_content'));

                //hide all contents
                self.hideAllContents();

                // display current content
                trafficTitle.addClass('selected');
                JKisio('#traffic_content')?.show();

                // display last layers
                self.displayCachedLayers('traffic');

                // update board position
                JKisio(params.boardContainerId).css({'top': topBoard + 'px'});
                positionBoardInstance.calculate(topBoard, widthBody, params.desktopBreakPoint, 'init');
            });

            if (Kisio.show_all_lines_button === 1 && JKisio('#mapid').is(':visible')) {
                JKisio(document).on('click', '#show_all_lines', function () {
                    JKisio.each(Kisio.networks, function (network_id, network_conf) {
                        showAllLinesWithStopPoint(network_id);
                    });
                });
            }

            JKisio('#ui-accordion-content-settings input:checkbox, #ui-accordion-content-settings input:radio').on('change, click', function () {
                //In case the user click on the traveler type button, we desactivate bike and VLS
                if (this.id === 'search_traveler_type' && JKisio('input#search_traveler_type').is(':checked')) {
                    if (typeof JKisio('#drawdown_modes_0_0') !== 'undefined') {
                        JKisio('#drawdown_modes_0_0').attr('checked', false);
                    }
                    if (typeof JKisio('#drawdown_modes_0_1') !== 'undefined') {
                        JKisio('#drawdown_modes_0_1').attr('checked', false);
                    }
                    if (typeof JKisio('#self_service_modes_0_0') !== 'undefined') {
                        JKisio('#self_service_modes_0_0').attr('checked', false);
                    }
                }else if (this.id === 'search_traveler_type' && !JKisio('input#search_traveler_type').is(':checked')) {
                    if (typeof JKisio('#drawdown_modes_0_0') !== 'undefined') {
                        JKisio('#drawdown_modes_0_0').attr('checked', true);
                    }
                    if (typeof JKisio('#drawdown_modes_0_1') !== 'undefined') {
                        JKisio('#drawdown_modes_0_1').attr('checked', true);
                    }
                    if (typeof JKisio('#self_service_modes_0_0') !== 'undefined') {
                        JKisio('#self_service_modes_0_0').attr('checked', true);
                    }
                }
                //In case the user click on bike or vls button and the traveler type button is checked, we desactivate the traveler type button
                if ( (this.id === 'drawdown_modes_0_0' || this.id === 'drawdown_modes_0_1' || this.id === 'self_service_modes_0_0') && JKisio(this.id).is(':checked')
                    && (JKisio('#ui-accordion-content-settings input#search_traveler_type').attr('checked') == 'checked' ||  JKisio('#ui-accordion-content-settings input#search_traveler_type').attr('checked') == 'undefined')) {
                    JKisio('input#search_traveler_type').attr('checked', false);
                }
            });
        }
    },

    hideAllContents: function () {
        const boardTitleElements = JKisio(params.boardTitleId);
        const boardContent = JKisio('#board_content');

        // hide title
        boardTitleElements.children().each(function () {
            if (this.classList.contains('selected')) {
                const selectedTitle = this.id?.replace('_title', '');
                self.savaCurrentLayers(selectedTitle);
            }

            JKisio(this).removeClass('selected');
        });

        // hide content
        boardContent.children().each(function () {
            JKisio(this).hide();
        });

        // clear map
        this.clearMap();
    },

    savaCurrentLayers: function (type) {
        window.map = window.map || {};
        window.map.cached_layers = window.map.cached_layers || [];
        window.map.cached_layers[type] = [];

        window.leaflet.map.eachLayer(function(layer) {
            window.map.cached_layers[type].push(layer);
        });
    },

    displayCachedLayers: function (type) {
        if (window.map.cached_layers && window.map.cached_layers[type]) {
            window.map.cached_layers[type].forEach(function (layer) {
                layer.addTo(window.leaflet.map);
            });
        }
    },

    clearMap: function () {
        window.leaflet.map.eachLayer(function(layer) {
            if (!('_url' in layer)) window.leaflet.map.removeLayer(layer);
        });
    },

    /**
     * show or hide layers on map
     * @param type
     */
    showHideLayers: function(type) {
        if (typeof type !== 'undefined') {
            let style = {};
            switch (type) {
                case 'journey' :
                    style['opacity'] = style['fillOpacity'] = '1';
                    this.setStyleLayer(type, style);
                    break;
                case 'line' :
                    style['opacity'] = '.5';
                    this.setStyleLayer(type, style);
                    break;
                default:
                    console.log('The mentioned layer type: '+ type +' is not recognized');
                    break;
            }
        }
    },

    /**
     * function to set layers style
     * @param type
     * @param style
     */
    setStyleLayer: function(type, style) {
        if (typeof type !== 'undefined' && typeof style === 'object' && JKisio('#mapid').is(':visible')) {
            window.leaflet.map.eachLayer(function(layer){
                let el = layer.options.layerType;
                if (typeof el !== 'undefined') {
                    let name = el.split('-');
                    if (el !== type && el !== (type + '-marker') ) {
                        if (Array.isArray(name) && name.includes('marker')) {
                            layer.setOpacity(0);
                        } else {
                            layer.setStyle({opacity: '0', fillOpacity: '0'})
                        }
                    } else if (el === type) {
                        layer.setStyle(style);
                    } else if (Array.isArray(name) && name.includes('marker')) {
                        layer.setOpacity(1);
                    }
                }
            });
        }
    },

    /**
     * this function manage display or non of the blocs setting/now
     * @param topBoard
     * @param widthBody
     * @param params
     * @param id
     */
    manageSettingNowBlocs: function (topBoard, widthBody, params, id) {

        /**
                 *
                 * @param id
                 * @param event
                 */
        let showHideContentById = function (event, id) {
            if (JKisio(id).length > 0) {
                if (event === 'show') {
                    JKisio(id).show();
                }
                if (event === 'hide')
                    JKisio(id).hide();
            }
        };
// Accessibility compliance
        JKisio(params.accordionHeaderClass).on('keyup', function (e) {
            if (e.keyCode == 32 || e.keyCode == 13) {
                JKisio(this).trigger('click');
            }
        });

        JKisio(params.accordionHeaderClass).click(function(e) {
            e.preventDefault();
            if (widthBody >= params.desktopBreakPoint) {
                JKisio(params.boardContainerId).animate({'top': topBoard}, "fast", "swing");
            }
            let $this = JKisio(this);
            if ($this.hasClass('show')){
                $this.attr('aria-expanded', false);
                setElementStatus($this, 'default', 'true', 'hide', params.classContent);
                JKisio(params.formModifyId).hide();
                if ( JKisio(params.formNewSearchId).is(":visible")) {
                    JKisio(params.classContent).hide();
                }
            } else if ($this.next().hasClass('show')){
                $this.attr('aria-expanded', true);
                $this.next().attr('aria-expanded', false);
                setElementStatus($this, 'next', 'false', 'show', params.classContent);
            } else if ($this.prev().hasClass('show')){
                $this.attr('aria-expanded', true);
                $this.prev().attr('aria-expanded', false);
                setElementStatus($this, 'prev', 'false', 'show', params.classContent);
            } else {
                $this.attr('aria-expanded', true);
                setElementStatus($this, 'default', 'true', 'show', params.classContent);
                if ( !JKisio(params.formNewSearchId).is(":visible")) {
                    let bg = JKisio(params.formModifyId+' button').css('background-color');
                    localStorage.setItem('bgFormModifyId', bg);
                    JKisio(params.formModifyId).show();
                }
            }
        });

        /**
         * this function find element in dom
         * @param e
         * @param index
         * @param someElement
         * @param type
         * @param id
         */
        var setElementStatus = function (e, index, someElement, type, id) {
            let positionBoardInstance = new positionBoard();
            let _this = '';
            switch (index) {
                case 'next':
                    _this = e.next();
                    break;
                case 'prev':
                    _this = e.prev();
                    break;
                default:
                    _this = e;
            }
            let indexOfElement = _this.index();
            _this.removeClass(params.activeFileds);
            _this.find(params.arrowClass).removeClass(params.iconUp).addClass(params.iconDown);
            if (someElement !== 'true')
                e.parent().parent().find(params.journeySearchContent)
                    .eq(indexOfElement).removeClass('show').slideUp('250');
            if (type === 'hide') {
                e.parent().parent().find(params.journeySearchContent)
                    .eq(e.index()).removeClass('show').slideUp('250', function () {
                    positionBoardInstance.calculate(topBoard, widthBody, params.desktopBreakPoint);
                });
                showHideContentById('show', id);
            }
            if (type === 'show') {
                e.toggleClass(params.activeFileds);
                e.find(params.arrowClass).removeClass(params.iconDown).addClass(params.iconUp);
                e.parent().parent().find(params.journeySearchContent)
                    .eq(e.index()).toggleClass('show')
                    .slideDown('200', function () {
                        positionBoardInstance.calculate(topBoard, widthBody, params.desktopBreakPoint);
                        if (JKisio(this).is('#ui-accordion-content-settings')) {
                            JKisio(this).children(".simplebar-content div:first-child").focus();
                        } else if (JKisio(this).is('#ui-accordion-content-now')) {
                            JKisio(this).children(":first-child").focus();
                        }
                    });
                showHideContentById('hide', id);
            }
        };

    },

    /**
     * Display clearable button and hide placeholder when focus input
     * @param params
     */
    clearable: function (params) {
        let placeholderJourneySearchForm;
        JKisio(params.fromId + ', ' + params.toId + ', ' + params.scheduleInputId).bind("keyup change",function (e) {
            if (JKisio('#' + e.currentTarget.id).val()){
                // remove error message when inputs autocomplete are not empty
                if (JKisio('#' + e.currentTarget.id).parent().hasClass(params.errorAttr)){
                    JKisio('#' + e.currentTarget.id).parent().removeClass(params.errorAttr);
                    JKisio('.popup.show').remove();
                }
                let formId = JKisio(this).parents('form:first').attr('id');
                if (formId !== 'ctp-precisionForm') JKisio('#' + e.currentTarget.id.replace(/_/gi, '-') + '-clear').show();
            }
            let currentElement = e.currentTarget.id;
            let autocompleteValue = JKisio(this).val();

            if (!autocompleteValue){
                JKisio('#' + e.currentTarget.id.replace(/_/gi, '-') + '-clear').hide();
            } else {
                if (e.currentTarget.id === params.fromAttr){
                    window.autocomplete.from = autocompleteValue;
                } else if (e.currentTarget.id === params.toAttr){
                    window.autocomplete.to = autocompleteValue;
                }
                JKisio('#' + e.currentTarget.id.replace(/_/gi, '-') + '-clear').on('keyup', function (evt) {
                    if (evt.keyCode == 32 || evt.keyCode == 13) {
                        JKisio(this).trigger('click');
                    }
                });
                JKisio('#' + e.currentTarget.id.replace(/_/gi, '-') + '-clear').click(function () {
                    JKisio('#' + currentElement).val('');
                    JKisio('#' + currentElement + '-hidden').val('');
                    JKisio('#' + e.currentTarget.id.replace(/_/gi, '-') + '-clear').hide();
                    window.autocomplete.to = JKisio(params.toId).val();
                    window.autocomplete.from = JKisio(params.fromId).val();
                    clearMarker('#' +currentElement);
                })
            }
        }).focusin(function () {
            placeholderJourneySearchForm = JKisio(this).attr('placeholder');
            JKisio(this).attr('placeholder', '');
            window.autocomplete.to = JKisio(params.toId).val();
            window.autocomplete.from = JKisio(params.fromId).val();
        }).focusout(function () {
            JKisio(this).attr('placeholder', placeholderJourneySearchForm);
            window.autocomplete.to = JKisio(params.toId).val();
            window.autocomplete.from = JKisio(params.fromId).val();
        });

        this.reverse(params)
    },

    /**
     * this function reverse value of inputs
     * @param params
     */
    reverse: function (params) {
        JKisio(params.reverseButtonId).click(function (e) {
            initAutocompleteInputs();

            let inputFromId = params.fromId.replace(/_/g, '-') + '-clear';
            let inputToId = params.toId.replace(/_/g, '-') + '-clear';
            if (e) {
                e.preventDefault();
            }

            const fromAutocomplete = window.autocomplete.from;
            const toAutocomplete = window.autocomplete.to;
            const fromAutocompleteHidden = window.autocompleteHidden.from;
            const toAutocompleteHidden = window.autocompleteHidden.to;

            JKisio(params.fromId).val(toAutocomplete).change();
            window.autocomplete.from = toAutocomplete;

            JKisio(params.toId).val(fromAutocomplete).change();
            window.autocomplete.to = fromAutocomplete;

            JKisio(params.fromIdHidden).val(toAutocompleteHidden);
            window.autocompleteHidden.from = toAutocompleteHidden;

            JKisio(params.toIdHidden).val(fromAutocompleteHidden);
            window.autocompleteHidden.to = fromAutocompleteHidden;

            if (!JKisio(params.fromId).val()){
                JKisio(inputFromId).hide();
            } else {
                JKisio(inputFromId).show();
            }
            if (!JKisio(params.toId).val()){
                JKisio(inputToId).hide();
            } else {
                JKisio(inputToId).show();
            }

            reverseMarker();

            //Detect if we are on result page, if yes user can reverse and relaunch the search
            if (JKisio('#result_content').length > 0) {
                JKisio('#journey-target-form').submit();
            }
        });
    },

    /**
     * Manage change in departure/arrival
     *
     * @param params
     * @param topBoard
     * @param widthBody
     */
    manageInputChange: function (params, topBoard, widthBody) {
        JKisio(params.fromId+','+ params.toId).on("change, click, keyup", function (e) {
            if (JKisio(params.classContent).length > 0 && e.keyCode !== 9) {
                JKisio(params.classContent).hide();
                JKisio(params.formNewSearchId).show();
                // reset Top board if Settings are not visible
                if (!JKisio('#ui-accordion-content-settings').is(':visible')) {
                    JKisio(params.boardContainerId).animate({'top': 130}, "fast", "swing");
                    let positionBoardInstance = new positionBoard();
                    positionBoardInstance.calculate(topBoard, widthBody, params.desktopBreakPoint, 'init');
                }
            }
        });
    }
});

/*
 * Show all lines if show_all_lines_onload is true
 */
JKisio('#mapid').ready(function () {

    let url = window.location.search;
    let params = new URLSearchParams(url);

    if ( JKisio('#show_all_lines_onload').val() === '1' && JKisio('#schedule_lines').is(':empty') && JKisio('#schedule_lines').length > 0 
    && JKisio('#mapid').length > 0 
    && params.toString() == "" ) {
        JKisio.each(Kisio.networks, function (network_id, network_conf) {
            showAllLines(network_id, network_conf);
        });
    }

    if (!window.Kisio?.map?.results?.no_result) {
        clearMap();
    }

    if ( JKisio('#show_all_lines_onload').val() === '1' && JKisio('#schedule_lines').is(':empty') && JKisio('#schedule_lines').length > 0 && JKisio('#mapid').length > 0 && JKisio('#ctp-searchScheduleResults').length <= 0 && JKisio('#ctp-linesSearchResultsContainer').length <= 0 ) {
        JKisio.each(Kisio.networks, function (network_id, network_conf) {
            showAllLines(network_id, network_conf);
        });
    }
});