import _ from 'pnp_core_bundle/lib/underscore.js';

Kisio.DatePicker = function () {
    let datepicker = JKisio.fn.datepicker.noConflict();
    JKisio.fn.bootstrapDP = datepicker;

    JKisio("#datepicker #date-input-picker").bootstrapDP({
        startDate: "now",
        language: window.Kisio.locale,
        autoclose: true
    });

    JKisio.ajax({
        url: Routing.generate('r_ws_status', {
            type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''
        }),
        dataType: 'json',
        success: function (data) {
            let endDate
            let regex;
            let m;
            if (!JKisio.isEmptyObject(data) && data.regions !== undefined && data.regions.length > 0 && data.regions[0].end_production_date !== undefined && data.regions[0].end_production_date !== '') {
                endDate = data.regions[0].end_production_date;
                // Validate dates in the format of 20201231 with leap year check on the 29th of February
                regex = /^(([0-9][0-9][0-9][0-9])((0[13578]|1[02])(0[1-9]|[12][0-9]|3[01])|(0[469]|11)(0[1-9]|[12][0-9]|30)|(02)(0[1-9]|1[0-9]|2[0-8]))|([0-9][0-9])([02468][048]|[13579][26])(02)(29))$/;
                if ((m = regex.exec(endDate)) !== null && m[0] !== undefined) {
                    let endDateYear = endDate.substring(0,4).toString();
                    let endDateMonth = endDate.substring(4,6).toString()-1;
                    let endDateDay = endDate.substring(6,8).toString();
                    JKisio("#datepicker #date-input-picker").bootstrapDP('setEndDate', new Date(endDateYear, endDateMonth, endDateDay));
                } else {
                    console.log("Error with end_production_date NOT a correct date from coverage.");
                    JKisio('#journey-form-submit').html(Translator.trans('canaltp_pnpcore.form.expired_date'));
                }
            } else {
                console.log("Error with end_production_date NOT correct from coverage.");
                JKisio('#journey-form-submit').html(Translator.trans('canaltp_pnpcore.form.expired_date'));
            }
        },
        error: function(xhr, status, error) {
            console.log("Error in getting information from coverage: " + error);
        }
    });

    JKisio('#datepicker .input-group-addon.icon-picker-input').on('click', function (e) {
        JKisio('#datepicker #date-input-picker').click().focus();
    })

};

var getSelectedDatetimeRepresents = function(dateValue, locale) {
    let selectedDatetimeRepresents = Translator.trans('journey.form.clockwise_choices.departure');
    let dateValueText = dateValue;
    if (JKisio('#search_datetime_represents_1').is(':checked')) {
        selectedDatetimeRepresents = Translator.trans('journey.form.clockwise_choices.arrival');
    }
    if (dateValue !== Translator.trans('journey.form.today') && dateValue !== Translator.trans('journey.form.tomorrow')) {
        selectedDatetimeRepresents += Translator.trans('journey.form.onDate');
        dateValueText = getShortMonthForHeader(dateValue, locale);
    }
    if (dateValue === Translator.trans('journey.form.today') || dateValue === Translator.trans('journey.form.tomorrow')) {
        dateValueText = dateValue.toLowerCase();
    }
    return [selectedDatetimeRepresents, dateValueText];
};
/**
 * Function to retrieve the parameters of a given URL
 * @param query
 */
function getQueryParameter(query)
{
    let params = {};
    let elements;
    let regex = /[?&]?([^=]+)=([^&]*)/g;
    // Iterate through the results of the regex as long as there are any in the browser query.
    while (elements = regex.exec(query)) {
        params[decodeURIComponent(elements[1])] = decodeURIComponent(elements[2]);
    }
    return params;
};

var getShortMonthForHeader = function(date, locale) {
    let regex;
    let monthsArray;
    let m;
    let day;
    let monthShort;
    let year;
    if (locale === 'fr') {
        regex = /(\d{2}\s)(.*)(\s\d{4})/gm;
        monthsArray = {
            "janvier": "janv.",
            "février": "févr.",
            "mars": "mars",
            "avril": "avril",
            "mai": "mai",
            "juin": "juin",
            "juillet": "juil.",
            "août": "août",
            "septembre": "sept.",
            "octobre": "oct.",
            "novembre": "nov.",
            "décembre": "déc."
        };
        while ((m = regex.exec(date)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            day = m[1];
            monthShort = monthsArray[m[2]];
            year = m[3];
        }
        let newDate = day + monthShort + year;
        return newDate;
    } else if (locale === 'en') {
        regex = /(.*)(\s\d{2})(\s\d{4})/gm;
        monthsArray = {
            "January": "Jan.",
            "February": "Feb.",
            "March":  "Mar.",
            "April": "Apr.",
            "May": "May",
            "June": "Jun.",
            "July": "Jul.",
            "August": "Aug.",
            "September": "Sep.",
            "October": "Oct.",
            "November": "Nov.",
            "December": "Dec."
        };
        while ((m = regex.exec(date)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            monthShort = monthsArray[m[1]];
            day = m[2];
            year = m[3];
        }
        let newDate = monthShort + day + year;
        return newDate;
    } else {
        return date;
    }
};

Kisio.TimePicker = function (element) {
    Kisio.timepicker(element);
};

Kisio.initHeaderDateTime = Backbone.View.extend({

    params: {
        datepickerId: '#date-input-picker',
        timepickerClass: '.timepicker.input-picker',
        datetimeId: '#time-ui-accordion-header-text',
        datetimeRepresentsId: '#search_datetime_represents_',
        displayedOn: 'journey',
        repositionDatePicker: false
    },

    initialize: function (params) {
        this.params = _.extend({}, this.params, params);
        let dates = JKisio.fn.bootstrapDP.dates;
        let locale = window.Kisio.locale;
        this.default(dates, locale);
    },

    default: function (dates, locale) {
        let self = this;
        let date = new Date();
        let time = this.addZero(date.getHours(), true) + ':' + this.addZero(date.getMinutes());
        this.setup(dates, locale, time);
        this.addEventListener(dates, locale, time);
        if(this.params.repositionDatePicker){
            const datepicker = JKisio(this.params.datepickerId);
            datepicker.on('click',function (){
                self.repositionDatePicker(datepicker);
            })
        }
    },

    setup: function (dates, locale, time) {
        let self = this;
        let dateInitValue = JKisio(self.params.datepickerId).val();
        let timeInitValue = JKisio(self.params.timepickerClass).val();
        if (dates) self.setValue(dateInitValue, timeInitValue, dates, locale, time);
    },

    addEventListener: function (dates, locale, time) {
        let self = this;
        JKisio(self.params.datepickerId + ', ' + self.params.timepickerClass).change( function () {
            let dateValue = JKisio(self.params.datepickerId).val();
            let timeValue = JKisio(self.params.timepickerClass).val();
            let values = getSelectedDatetimeRepresents(dateValue, locale);
            let selectedDatetimeRepresents = values[0];
            let dateValueText = values[1];
            if(JKisio('#schedule-datepicker-bloc').length > 0) {
                JKisio(self.params.datetimeId).text(dateValueText + ' ' + Translator.trans('journey.form.at') +' '+ timeValue);
            } else {
                JKisio(self.params.datetimeId).text(selectedDatetimeRepresents + ' ' + dateValueText + ' ' + Translator.trans('journey.form.at') +' '+ timeValue);
            }
            if (dates) self.setValue(dateValue, timeValue, dates, locale, time);
        });
        JKisio(self.params.datetimeRepresentsId + '0, ' + self.params.datetimeRepresentsId + '1').change(function () {
            let dateValue = JKisio(self.params.datepickerId).val();
            let timeValue = JKisio(self.params.timepickerClass).val();
            let values = getSelectedDatetimeRepresents(dateValue, locale);
            let selectedDatetimeRepresents = values[0];
            let dateValueText = values[1];
            JKisio(self.params.datetimeId).text(selectedDatetimeRepresents + ' ' + dateValueText + ' ' + Translator.trans('journey.form.at') +' '+ timeValue);
        });
    },

    setValue: function (dateInitValue, timeInitValue, dates, locale, time) {
        //Get datetime from URL
        let parameters = getQueryParameter(window.location.search);
        let hour = JKisio(parameters).attr('search[datetime][time][hour]');
        let minute = JKisio(parameters).attr('search[datetime][time][minute]');
        let values = getSelectedDatetimeRepresents(dateInitValue, locale);
        let selectedDatetimeRepresents = values[0];
        let dateInitValueText = values[1];
        dateInitValue = dates[locale] !== 'undefined' ? dates[locale]?.today : dates['fr']?.today;
        if (dateInitValue && timeInitValue === time && JKisio('#schedule-datepicker-bloc').length == 0) {
            let textInfo = this.params.displayedOn + '.form.now';
            JKisio(this.params.datetimeId).text(selectedDatetimeRepresents + ' ' + Translator.trans(textInfo).toLowerCase());
        } else if ( hour != null && minute != null) {
            JKisio(this.params.datetimeId).text(selectedDatetimeRepresents + ' ' + dateInitValueText + ' ' + Translator.trans('journey.form.at') + ' ' +hour+':'+minute);
        } else if(JKisio('#schedule-datepicker-bloc').length == 0){
            JKisio(this.params.datetimeId).text(selectedDatetimeRepresents + ' ' + dateInitValueText + ' ' + Translator.trans('journey.form.at') + ' ' + timeInitValue);
        }
    },

    addZero: function(i, hours) {
        if(hours){
            if(i>23) i=0;
            else if (i<0) i=23;
        }
        else{
            if(i>59) i=0;
            else if(i < 0) i=59;
        }
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    },
    repositionDatePicker : function (datepicker) {
        // repositionne le datepicker pour le mode paysage
        JKisio(this.params.boardContentId).on('scroll',function() {
            if(JKisio('.datepicker').length){
                JKisio('.datepicker').css('top', datepicker.offset().top + datepicker.outerHeight(true));
            }
        })
    }
});