/**
 * Get a specified by name parameter from the queryString
 * @param queryString
 * @param name
 * @returns {string}
 */
 export function getUrlParameter(queryString, name) {
    if (name !== 'filter') {
        const nameReplaced = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + nameReplaced + '(=([^&]*)|&|$)');
        const results = regex.exec(queryString);
        return results === null ? '' : decodeURIComponent(results[2].replace(/\+/g, ' '));
    } else {
        let regex = /[\?&]proximity_search\[filter\]\[\d*\]=([^&#]*)/gm;
        let m;
        let results = [];
        while ((m = regex.exec(queryString)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            m.forEach(function (match, groupIndex) {
                if (groupIndex == 1) {
                    results.push(match);
                }
            });
        }
        return results;
        }
    };
    

    /**
 * Get URL to Schedule page, target one depending if line.id is provided
 * @param stopAreaLabel
 * @param stopAreaId
 * @param line
 * @param directionType
 * @returns {string}
 */
export function getURLToSchedule(stopAreaLabel, stopAreaId, line, directionType) {
    let uri_object;
        if (line !== undefined) {
        if (directionType !== undefined) {
            uri_object = {
                'schedule': {
                    'stop_area_lines': {
                        'autocomplete': stopAreaLabel,
                        'autocomplete-hidden': stopAreaId
                    }
                },
                'schedule_selected': line,
                'direction_type': directionType
            };
        } else {
            uri_object = {
                'schedule': {
                    'stop_area_lines': {
                        'autocomplete': stopAreaLabel,
                        'autocomplete-hidden': stopAreaId
                    }
                },
                'schedule_selected': line
            };
        }
    } else {
        uri_object = {
            'schedule': {
                'stop_area_lines': {
                    'autocomplete': stopAreaLabel,
                    'autocomplete-hidden': stopAreaId
                }
            }
        };
    }
        let query = JKisio.param(uri_object);
        return Routing.generate(
        'schedule_home',
        { type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : '' }) + '?' + query;
}

 /**
     * Set URL in the Browser Bar dynamically
     * @param route
     */
export function setURLDynamically(route) {
     let newState = (history.state || 0) + 1;
     history.replaceState(newState, 'Pushed: ' + newState, route);
};