/**
* Draw circle on map contextualize by distance
* @param coords
* @param radius
*/
export function drawCircle(coords, radius) {
    if (!coords) {
        return;
    }
    let object_coord = coords.split(';');
    if (typeof radius === 'undefined') {
        radius = JKisio('#proximity-search-time-input input:checked').val();
    }
    // We slightly enlarge the Drawn Circle, for compensate the fact that Leaflet sometimes draw PINs outside of the one based on the radius initial-value
    radius = parseInt(radius) + 75;
    radius = radius.toString();
    let circle = L.circle([object_coord[0], object_coord[1]], {
        radius: radius,
        weight: 3,
        dashArray: '5, 10',
        color: 'grey',
        fillColor: 'transparent',
        fillOpacity: 1,
        className: 'solutions-circle'
    });
    circle.addTo(window.leaflet.map);
    let htmlTooltip = JKisio('#proximity-search-time-input input:checked + label').html();
    circle.bindTooltip(htmlTooltip, {
        direction: 'top',
        permanent: true,
        opacity: 1,
        offset: [0, 30],
        className: 'walk-legend'
    }).openTooltip([circle.getBounds().getNorth(), object_coord[1]]);
    window.leaflet.map.fitBounds(circle.getBounds(), {paddingTopLeft: [boardWidth, 0]});
};
