import { time_table_consts } from '../core/constants';

export function downloadEventListeners(id_line, direction_type, raw_first_datetime, stop_point_id, stop_area_name, direction_title) {
    document.querySelector('.' + time_table_consts.download_class)?.addEventListener('click', function () {
        const url = Routing.generate('schedule_timetable_download',{
            type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : '',
            id_line: id_line,
            direction_type: direction_type,
            from_datetime: raw_first_datetime,
            stop_point_id: stop_point_id,
            stop_area_name: stop_area_name,
            direction_title: direction_title
        });
        
        window.open(url);
    });
}
