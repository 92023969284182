import {getLinePicto} from 'pnp_core_bundle/plugnplay/customize_client';
import {showNextDepartures, addMarker, showLinesByType, delegateEvents} from './display_search_schedule_result';
import { ManagePopup } from 'pnp_core_bundle/plugnplay/leaf-core';

export var getImgByMode, getLineGeoJsonObject, showStopPointsByLineId, showOneLineById;

(function () {

    /**
     * Show one line by id
     */
    showOneLineById = function (id_line, opacity) {
        let lineColor = '';

        if (typeof id_line === "undefined") {
            console.log('Error : no id line');
        } else {
            JKisio.ajax({
                url: Routing.generate('r_public_transport_objects_filtered',
                    {
                        type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                        public_transport_object_type: 'lines',
                        public_transport_object_id: id_line,
                        action: 'lines',
                        count: 1
                    }),
                async: false,
                dataType: 'json',
                success: function (data) {
                    let line = data.lines;
                    if (line[0].color !== 'FFFFFF') {
                        lineColor = '#' + line[0].color;
                    } else {
                        lineColor = '#' + line[0].text_color;
                    }
                    if (typeof data.lines === "undefined") {
                        console.log("No data for line " + id_line + " available");
                    }
                },
                error: function (xhr, status, error) {
                    console.log(error);
                }
            });
        }
        return lineColor;
    };

    /**
     * Draw line by passing by object line
     *
     * @param line
     * @param clear
     * @param centerView
     * @param opacity
     */
    getLineGeoJsonObject = function (line, clear, centerView, opacity) {
        if (typeof clear !== 'undefined' && clear === true && window.leaflet.map.hasLayer(group)) {
            window.leaflet.map.removeLayer(group);
        }

         group = L.featureGroup();

        if (typeof line !== "undefined" && typeof line.geojson !== "undefined") {
            let myStyle = {
                "color": "#" + line.color,
                "weight": 5,
                "opacity": opacity,
                "fillOpacity": opacity
            };

            return L.geoJSON(line.geojson, {style: myStyle, customId: line.id, layerType: 'line'});
        }
    };

    let group;

    /**
     * Show stop_points by id_line
     *
     * @param id_line
     * @param color
     */
    showStopPointsByLineId = function (id_line, color) {
        if (window.Kisio.show_one_line_by_id) {
            JKisio('#loading-box').css("visibility", "visible");

            if (typeof id_line === "undefined") {
                return console.log('Error : no id line, sent:' + id_line);
            }

            JKisio.ajax({
                url: Routing.generate('r_public_transport_objects_filtered',
                    {
                        type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                        public_transport_object_type: 'lines',
                        public_transport_object_id: id_line,
                        action: 'stop_points',
                        count: 100
                    }),
                async: false,
                dataType: 'json',
                success: function (data) {

                    let stopPoints = data.stop_points;

                    if (typeof stopPoints !== "undefined") {
                        if(!window.leaflet){
                            return;
                        }
                        let group = L.featureGroup().addTo(window.leaflet.map);

                        stopPoints.forEach(function (sp) {
                            if (!sp.coord || !sp.coord.lat || !sp.coord.lon) {
                                return;
                            }

                            let circleLayer = [];
                            let circleMarker = L.circleMarker(
                                [sp.coord.lat, sp.coord.lon], {
                                    radius: 5,
                                    width: 0.1,
                                    color: color,
                                    fillColor: 'white',
                                    fillOpacity: 1,
                                    sp: sp,
                                    layerType: 'stop_point'
                                }
                            ).addTo(group);
                            circleLayer.push(circleMarker);

                            L.layerGroup(circleLayer).addTo(window.leaflet.map);
                        });
                        group.on('mouseover click', function (e) {
                            if (!window.leaflet){
                                return;
                            }
                            window.leaflet.map.closePopup();
                            let popupContent = showLinesInPopup(e.layer.options.sp, 'stop_points');
                            let popup = new L.Popup();
                            popup.setLatLng(e.latlng).setContent(popupContent).openOn(window.leaflet.map);
                            let point = window.leaflet.map.latLngToContainerPoint(e.layer.options.sp.coord);
                            ManagePopup(point, window.leaflet.map);
                            L.DomEvent.stopPropagation(e);
                        });
                    } else {
                        console.log("No data for stop points available");
                    }
                },
                error: function (xhr, status, error) {
                    console.log(error);
                }
            });

        }
    };


    /**
     * Show stop_areas by id_line
     *
     * @param id_line
     * @param color
     */
    var showStopAreasByLineId = function (id_line, color) {
        JKisio('#loading-box').css("visibility", "visible");

        if (typeof id_line === "undefined") {
            console.log('Error : no id line, sent:' + id_line);
        }

        JKisio.ajax({
            url: Routing.generate('r_public_transport_objects_filtered',
                {
                    type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                    public_transport_object_type: 'lines',
                    public_transport_object_id: id_line,
                    action: 'stop_areas'
                }),
            dataType: 'json',
            success: function (data) {

                let stopPoints = data.stop_points;

                if (typeof stopPoints !== "undefined") {

                    let group = L.featureGroup().addTo(window.leaflet.map);

                    stopPoints.forEach(function (sp) {
                        if (!sp.coord || !sp.coord.lat || !sp.coord.lon) {
                            return;
                        }

                        let circleLayer = [];

                        let circleMarker = L.circleMarker(
                            [sp.coord.lat, sp.coord.lon], {
                                radius: 5,
                                width: 0.1,
                                color: color,
                                fillColor: 'white',
                                fillOpacity: 1,
                                weight: 3,
                                sp: sp
                            }
                        ).addTo(group);

                        circleLayer.push(circleMarker);

                        L.layerGroup(circleLayer).addTo(window.leaflet.map);
                    });

                    group.on('click', function (e) {
                        let popupContent = showLinesInPopup(e.layer.options.sp, 'stop_areas');
                        let popup = new L.Popup();
                        popup.setLatLng(e.latlng).setContent(popupContent).openOn(window.leaflet.map);
                        let point = window.leaflet.map.latLngToLayerPoint(e.layer.options.sp.coord);
                        ManagePopup(point, window.leaflet.map);
                        L.DomEvent.stopPropagation(e);
                    });

                } else {
                    console.log("No data for stop points available");
                }

                JKisio('#loading-box').css("visibility", "hidden");

            },
            error: function (xhr, status, error) {
                console.log(error);
            }
        });
    };

    /**
     * Get image by mode
     *
     * @param mode
     */
    getImgByMode = function (mode) {
        let img = window.Kisio.modes_img[mode];
        if (typeof window.Kisio.modes_img[mode] === 'undefined') {
            img = window.Kisio.modes_img['commercial_mode:' + mode];
        }
        return img;
    };

    /**
     * When clicking on a stop point or stop area, this will show a popup containing all lines passing by
     *
     * @param object
     * @param object_type
     * @returns {HTMLDivElement}
     */
    let showLinesInPopup = function (object, object_type) {

        let el = document.createElement('div');
        let content = [];

        JKisio.ajax({
            url: Routing.generate('r_public_transport_objects_filtered',
                {
                    type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                    public_transport_object_type: object_type,
                    public_transport_object_id: object.id,
                    action: 'lines'
                }),
            dataType: 'json',
            success: function (result) {
                let lines = result.lines;

                if (typeof lines !== "undefined" && Kisio.filter_by_stop_point == '1') {
                    lines.forEach(function (l) {
                        let stop_area_id = object.stop_area.id;
                        let coords;
                        if (object.coord !== undefined && object.coord.lat !== undefined && object.coord.lon !== undefined) {
                            coords = object.coord.lat + ';' + object.coord.lon;
                        }
                        let html_content = getLinePicto(l, 'undefined', 'popup_lines', stop_area_id, true, coords);
                        content.push(html_content);
                    });
                    content.push('</div>');
                }

                el.innerHTML = '<div id="leaflet-popup">' +
                    '<div id="leaflet-popup-header">' +
                    '<div class="popup-info-line"><i class="ikisio ikisio-stop_point"></i> ' + object.label + '</div>' +
                    '</div>';

                if (Kisio.filter_by_stop_point == '1') {
                    el.innerHTML += '<div id="leaflet-popup-content">' + content.join(' ') + '</div>';
                }
                el.innerHTML += '</div>';
                JKisio('.popup_lines, .lines_in_popup').click(function (e) {
                    JKisio('#loading-box').css("visibility", "visible");
                    let stop_area = JKisio(this).attr('data-stop-area');
                    let stopAreaLinked = document.getElementById(stop_area);
                    addMarker(JKisio(this).attr('data-coords'));
                    if (JKisio('#next_departures_results').length > 0) {
                        let _this = this;
                        setTimeout(function () {
                            let labelStopArea = JKisio('#leaflet-popup .popup-info-line').text();
                            JKisio('#stop_area_label').text(labelStopArea);
                            JKisio('#ctp-searchSchedule').hide();
                            JKisio('.schedule-lines').html('');
                            let id_line = JKisio(_this).attr('data-line');
                            let id = stop_area;
                            showLinesByType(id, 'stop_areas', id_line);
                            JKisio('#button_action').remove();
                            showNextDepartures(id, id_line, false);
                            delegateEvents();
                        }, 150);
                    } else {
                        JKisio(stopAreaLinked).trigger('click');
                    }
                });

            },
            error: function (xhr, status, error) {
                console.log(error);
            }
        });

        return el;
    };
}());
