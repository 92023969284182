
import { launchUpdateProcess } from './manageProximity'
import { drawStopPoints } from './drawStopPoints'
import { drawPOIs } from './managePOI'
import { managePopUp } from './managePopUp'
import { reloadPositionBoard } from './reloadPositionBoard'
import { drawCircle } from './drawCircle'
import { createHideShowButtonStops } from './manageAddresses'
import { manageCounter } from './manageCounter'

/**
 * Show/hide POI categories
 */
export function manageResults() {
    
    JKisio('.autocomplete-clear, .autocomplete-clear i, .locate-position, .autocomplete-search-icon').show();

    //Click on checkbox in category's legend
    JKisio(document).on('click', '.check_all input', function () {
        const id_parent = JKisio(this).closest('div.ctp-proxi-accordion').attr('id');
        const that = this;
        JKisio('#ctp-proximity-poi .ctp-proxi-accordion legend').each(function(index) {
            JKisio(this).attr('aria-expanded', 'false')
            JKisio(this).find('i').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
            JKisio(this).next('div').hide().promise().done(function () {
                resetHeight();
            });
        })

        const legend = JKisio(that).closest('legend');
        if (legend.attr('aria-expanded') == 'false') {
            legend.attr('aria-expanded', 'true')
            JKisio('#ctp-proximity-poi .ctp-proxi-accordion legend i').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
            legend.find('i').removeClass('ikisio-arrow-drop-up').addClass('ikisio-arrow-drop-down');
            legend.next('div').show().promise().then(function () {
                resetHeight();
            });
        }
        JKisio('#' + id_parent + ' div input[type=checkbox]').each(function () {
            if (JKisio(that).prop('checked') === true) {
                JKisio(this).prop('checked', true);
                JKisio(this).parents('.ctp-proximity-poi-checkbox').removeClass('poi-unchecked').addClass('poi-checked');
            } else if (JKisio(that).prop('checked') === false) {
                JKisio(this).prop('checked', false);
                JKisio(this).parents('.ctp-proximity-poi-checkbox').removeClass('poi-checked').addClass('poi-unchecked');
            }
        });
        launchUpdateProcess();
    });

    //Click on checkbox in subcategory
    JKisio(document).on('click', '.checkbox-container input', function () {
        launchUpdateProcess();
    });
};

/**
 * Show POIs and Stops on Map
 * @param data
 */
 export function showResultsOnMap(data, showStops) {
    let stops = [];
    let pois = [];
    if (data['proximity.form.poi.group.stop'] !== undefined && data['proximity.form.poi.group.stop']['response'] !== undefined && data['proximity.form.poi.group.stop']['response'].length > 0) {
        let response = data['proximity.form.poi.group.stop']['response'];
        for (const stopGroup of response) {
            if (stopGroup.places_nearby !== undefined && stopGroup.places_nearby.length > 0) {
                stops = stopGroup.places_nearby;
            }
        }
        delete data['proximity.form.poi.group.stop'];
    }
    if (stops.length > 0) {
        drawStopPoints(stops, showStops);
    }
    if (!JKisio.isEmptyObject(data)) {
        for (let poiGroup in data) {
            if (data[poiGroup].response !== undefined && data[poiGroup].response.length > 0) {
                let response = data[poiGroup].response;
                for (const poiSubGroup of response) {
                    if (poiSubGroup.places_nearby !== undefined && poiSubGroup.places_nearby.length > 0) {
                        let places = poiSubGroup.places_nearby;
                        for (const place of places) {
                            place.poi_type = poiSubGroup.poi_type;
                            pois.push(place);
                        }
                    }
                    if (poiSubGroup.free_floatings !== undefined && poiSubGroup.free_floatings.length > 0) {
                        let floatings = poiSubGroup.free_floatings;
                        for (const floating of floatings) {
                            floating.poi_type = poiSubGroup.poi_type;
                            pois.push(floating);
                        }
                    }

                }
            }
        }
    }
    if (pois.length > 0) {
        drawPOIs(pois);
        // We wait for disabling tabindex on cluster divs for better accessibility behavior (excluding objects map-related)
        setTimeout(function () {
            JKisio(document).find('.marker-cluster').attr('tabindex', '-1');
        }, 1000);
    }
    managePopUp();
};

export function showProximityResultContent(div, callback) {
    const height = JKisio(div).css({
        display: "block"
    }).height();

    JKisio(div).css({
        marginTop: 0,
        height: height
    }).animate({
        marginTop: height,
        height: 0
    }, 500, function () {
        JKisio(this).css({
            display: "block",
            height: "",
            marginTop: ""
        }).hide().promise().then(function () {
            JKisio('#ctp-proximity-poi').show().promise().then(function () {
                resetHeight();
                if (typeof callback === "function") {
                    callback();
                }
            });
        });
    });
};

/**
 * Hide proximity results content and show poi lists
 * @param div
 */
 export function hideProximityResultContent(div, callback) {
    const height = JKisio(div).height();

    JKisio(div).css({
        marginTop: height,
        height: 0
    }).animate({
        marginTop: 0,
        height: height
    }, 500, function () {
        JKisio(this).css({
            display: "none",
            height: "",
            marginTop: ""
        });
    });
    JKisio('.poi_list').show().promise().then(function () {
        JKisio('#proximity-results').css({'height': 'auto'});
        window.proximitySimpleBar.recalculate();
        reloadPositionBoard();
        JKisio('.prox-departure-btn, .prox-arrival-btn, .prox-recenter-btn').tooltip();
        if (typeof callback === "function") {
            callback();
        }
    });
};

export function showResults(result) {
    createHideShowButtonStops();
    JKisio('#board_container').animate({'top': '2%'}, "fast", "swing");
    manageCounter();
    manageResults();
    const coords = Kisio.map_config.latitude+';'+Kisio.map_config.longitude;
    const inverted_coords = Kisio.map_config.longitude+';'+Kisio.map_config.latitude;
    const input = JKisio('#proximity_search_uri_autocomplete').attr('data-coord', coords).val(coords);
    JKisio('#proximity_search_uri_autocomplete-hidden').val(inverted_coords);
    JKisio('.autocomplete-clear, .autocomplete-clear i').show();
    if (!JKisio('#proximity-results').is(':visible')) {
        JKisio('#proximity-results').show();
    }
    if (!JKisio('#div_show_poi_list').is(':visible')) {
        JKisio('#div_show_poi_list').show();
    }
    showResultsOnMap(result.data.result_map, true);
    drawCircle(
        JKisio('#proximity_search_coords').val() !== "" ?
            JKisio('#proximity_search_coords').val() : coords,
        JKisio('#proximity-search-time-input input:checked').val()
    );
};

function resetHeight (){
    JKisio('#proximity-results').css({'height': 'auto'});
    window.proximitySimpleBar.recalculate();
    reloadPositionBoard();
}